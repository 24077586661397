import React, { useState, useEffect } from 'react';

import GlobalContext from 'infra/GlobalContext';

import Form from './Form';

import './FormAction.scss';

export default function InlineFormAction(props) {
  const [pristine, setPristine] = useState();
  // const [formValuesByCard, setFormValuesByCard] = useState();

  useEffect(() => {
    if (props.card && props.card.formDefinition && props.card.legalStructure) {
      let card = props.card;

      setPristine(card);
    }
  }, [props]);

  const onChange = (value) => {
    let formValues = value[pristine.id];
    props.onChange(formValues);
  }


  return (
    <GlobalContext.Consumer>
      {(context) => pristine &&
        <Form
          className="field"
          cards={[pristine]}
          module={props.module}
          onChange={onChange}

          readOnly={props.readOnly}
          inline={true}
        />
      }
    </GlobalContext.Consumer >
  );
}