import React, { ReactNode } from "react";

import arxs from "infra/arxs";
import { RelationshipType, } from "infra/api/contracts";

import { CardClassProps } from "components/card/CardContracts";
import { CardSubtitle } from "components/card/CardSubtitle";
import CardSignature from "components/card/CardSignature"
import CardActions from "components/card/CardActions";
import CardFormBadge from "components/card/CardFormBadge";
import CardLocation from "components/card/CardLocation";
import CardInternalNumber from "components/card/CardInternalNumber";

export default function RecommendationCard(props: CardClassProps) {
  const { card } = props;

  const getValue = (map: any, cardValue: any) => {
    const id = (cardValue || {}).id;
    const ref = (map && map[id]) || {};
    return ref.name
      ? ref.name
      : Object.keys(ref).some((x) => x)
      ? ref
      : undefined;
  };

  const ownerRelationship = (card.relationships || []).filter(
    (x: { type: string }) => x.type === RelationshipType.Owner
  )[0] || {};
  const owner =
    ownerRelationship.employee &&
    (ownerRelationship.employee.name ||
      getValue(props.lookups.employeeMap, ownerRelationship.employee));

  return (
    <>
      <div className="list-card-right">
        <div className="list-card-header">
          {owner && <div>{owner}</div>}
          {card.activationDate && (
            <div>{arxs.dateTime.formatDate(card.activationDate)}</div>
          )}
          <CardSignature {...props} />
        </div>
        <h2>
          <div className="list-card-title" title={card.title}>
            {card.title}
          </div>
          <CardActions {...props.actionProps} />
        </h2>
        <CardSubtitle
          cardProps={props.cardProps}
          card={card}
          render={(children: ReactNode) => (
            <>
              {children}
              <div className="list-card-document-count">
                <i className="fas fa-copy"></i>
                {card.numberOfDocuments}
              </div>
              <CardFormBadge card={card} />
            </>
          )}
        />
        <div className="list-card-details">
          <CardInternalNumber {...props} />
          <div>
            {[card.sort, card.kind, card.type].filter((x) => x).join(" - ")}
          </div>
          <CardLocation card={card} />
        </div>
      </div>
    </>
  );
}
