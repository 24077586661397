import React, { useCallback, useEffect, useState } from 'react';
import Collapsible from "react-collapsible";

import arxs from 'infra/arxs';
import GlobalContext from 'infra/GlobalContext';

import Button from "components/controls/Button";
import Badge from "components/controls/Badge";

import buildFormValuesHelper from "./formValuesHelper";
import FormItem from "./FormItem";

import './FormForSubject.scss';

export default function FormForSubject(props) {
  const { metadata, card, module, form, objectId, requiredItemsWithoutValue, values } = props;

  const controls = metadata.controls;
  const items = metadata.items;

  const [sections, setSections] = useState([]);
  const [formValuesHelper, setFormValuesHelper] = useState();
  const [showAllOk, setShowAllOk] = useState(false);
  const [isAllOk, setIsAllOk] = useState(false);

  useEffect(() => {
    setSections((metadata.sections || []).orderBy(x => x.ordinal).map((x) => ({
      ...x,
      ref: React.createRef(),
    })));

    const formValuesHelper = buildFormValuesHelper(metadata);
    setFormValuesHelper(formValuesHelper);
    if (formValuesHelper) {
      setShowAllOk(formValuesHelper.canMarkAllOk() && !props.readOnly);
    } else {
      setShowAllOk(false);
    }

  }, [metadata]);


  useEffect(() => {
    if (formValuesHelper && values) {
      setIsAllOk(formValuesHelper.areItemsAllFilledIn(values));
    }
  }, [formValuesHelper, values]);

  let attachmentInfo = card.attachmentInfo;
  if (form) {
    attachmentInfo = {
      storedFiles: ((attachmentInfo || {}).storedFiles || [])
        .concat((form.attachmentInfo || {}).storedFiles || []),
    };
  }

  const markAllOk = useCallback(() => {
    if (props.onChange) {
      const newValues = formValuesHelper.markAllOk(values);
      props.onChange(newValues);
    }
  }, [props.onChange, values, formValuesHelper]);

  const onFormItemChange = useCallback((dataItem) => {
    if (!props.onChange) {
      return;
    }

    const newData = (values || []).filter(x => x.id !== dataItem.id).concat(dataItem);

    props.onChange(newData);
  }, [props.onChange, values]);

  const renderSection = (context, section, sectionIndex) => {
    return (
      <Collapsible
        key={`section-${sectionIndex}`}
        trigger={
          <div className="header">
            <div className="circle">{sectionIndex + 1}</div>
            <div className="title">{section.title} <Badge>{items.filter(x => x.section === section.id).length}</Badge></div>
          </div>
        }
        ref={section.ref}
        className="section"
        openedClassName="section"
        open={true}
        overflowWhenOpen="unset"
      >
        <div className="section-body">
          {(items
            .filter(x => x.section === section.id)
            .orderBy(x => x.ordinal) || [])
            .filter(x => controls.some(c => c.id === x.control))
            .map((item) => <FormItem
              key={`section-${sectionIndex}-${item.id}`}
              card={props.card}
              item={item}
              data={(values || []).filter(x => x.id === item.id)[0]}
              readOnly={props.readOnly}
              control={controls.filter(x => x.id === item.control)[0]}
              module={module}
              onChange={onFormItemChange}
              objectId={objectId}
              requiredWithoutValue={(requiredItemsWithoutValue || []).any(x => x === item.id)}
              attachmentInfo={attachmentInfo}
            />)}
        </div>
      </Collapsible>
    );
  };

  return (
    <GlobalContext.Consumer>
      {(context) => <>
        <div className="sections">
          {showAllOk && !props.inline && (
            <div className="form-actions">
              <Button
                className={`form-button form-mark-ok ${isAllOk ? "all-ok" : ""}`}
                onClick={() => markAllOk(context)}
                title={arxs.t("forms.mark_all_ok")}
              >
                <i className={"far fa-check"}></i>
              </Button>
            </div>
          )}
          {sections.map((section, sectionIndex) =>
            renderSection(context, section, sectionIndex)
          )}
        </div>
      </>}
    </GlobalContext.Consumer>
  );
}