import React, { Component } from "react";
import { Prompt } from "react-router-dom";
import Collapsible from "react-collapsible";
import { RRule } from 'rrule';
import _ from "lodash";

import GlobalContext from "infra/GlobalContext";
import arxs from 'infra/arxs';
import { LinkType, FormValueAttachmentType, OriginModuleEnum, TaskStatus, ObjectDocumentType, AttachmentType } from "infra/api/contracts";

import SignatoryList from "components/controls/SignatoryList";
import Toaster from "components/util/Toaster";
import Field from "components/controls/Field";
import Button from "components/controls/Button";
import TagOverview from "components/controls/tags/TagOverview";
import { ImageOverview } from "components/controls/images/ImageOverview";
import { IconOverview } from "components/controls/images/IconOverview";
import DocumentOverview from "components/controls/documents/DocumentOverview";
import { HorizontalSeparator } from "components/shell/HorizontalSeparator";
import ItemList from "components/controls/ItemList";
import RelationshipList from "components/controls/RelationshipList";
import CardList from "components/controls/cardlist/CardList";
import CodeElementList from "components/controls/codeElements/CodeElementList";
import { createInputPopup } from "components/shell/InputPopup/InputPopup";
import DateRange from "components/controls/dateRange/DateRange";
import FormContainer from "components/controls/form/FormContainer";

import { StepVisibility } from "modules/ModuleMetadata";
import WizardNavigator from "./WizardNavigator";
import ValidationSummary from "./ValidationSummary";

import "./Wizard.scss";

export default class Wizard extends Component {
  constructor(props) {
    super(props);

    this.getRequiredFields = this.getRequiredFields.bind(this);
    this.onLookupsChange = this.onLookupsChange.bind(this);

    this.filter = {};

    const module = this.props.module;
    const metadata = arxs.moduleMetadataRegistry.get(module);

    const hasGeoLocation = metadata.wizard.steps.any((step) =>
      step.fields
        .filter(
          (field) =>
            field.productType === undefined || field.productType === arxs.productType
        )
        .flatMap((row) => row)
        .any((field) => field && field.name === "geoLocation")
    );

    const injectedState = (this.props.location || {}).state;

    const autoClose = arxs.parseURL(window.location.href).searchObject["ac"];

    const validationRef = React.createRef();

    this.state = {
      stepIndex: 0,
      steps: [],
      pristine: this.props.pristine || {
        tags: [],
        attachmentInfo: {}
      },
      data: { ...this.props.data, ...injectedState },
      validation: {},
      metadata,
      isGeoLocationInherited: true,
      hasGeoLocation,
      isLoaded: false,
      isDirty: false,
      autoClose,
      processFlows: [],
      duplicateSourceData: {},
      validationRef,
      uploadCorrelationKey: new Date().format_yyyyMMddhhmmsshhh(),
      fieldProps: {},
    };
  }

  stateProxy = {
    getter: (propName) => this.state[propName],
    setter: (stateProp, callBack) => {
      this.setState(
        { ...stateProp },
        callBack ? () => callBack() : this.refresh
      )
    },
    getField: (fieldName) => this.state.data[fieldName] === undefined
      ? this.state.pristine[fieldName]
      : this.state.data[fieldName],
    setField: (fieldName, value, callBack) =>
      this.setState(
        { data: { ...this.state.data, [fieldName]: value } },
        callBack ? () => callBack() : this.refresh
      ),
    setFieldProps: (fieldName, props, callBack) => {
      const fieldProps = { ...this.state.fieldProps, [fieldName]: props };
      this.setState({ fieldProps }, callBack);
    }
  };

  onLookupsChange() {
    this.state.metadata.wizard.wizardClass.onLookupsChange(this.stateProxy);
  }

  deserialize(from) {
    return {
      ...from,
      reference: from && from.reference && JSON.parse(from.reference),
    };
  }



  serialize(from) {
    const formatted = { ...from };

    const serializeAttachments = (obj) => {
      if (Array.isArray(obj)) {
        return obj.map(item => serializeAttachments(item));
      } else if (typeof obj === 'object' && obj !== null) {
        const newObj = { ...obj };
        if (newObj.attachments && Array.isArray(newObj.attachments)) {
          newObj.attachments = newObj.attachments.map(attachment => {
            const newAttachment = { ...attachment };

            // Check if the type is 'Media' before processing
            if (newAttachment.type === "Media" && Array.isArray(newAttachment.value)) {
              // Keep only the selected fields in the value array
              const updatedValue = newAttachment.value.map(item => {
                const { id, name, hash, url, contentType, type } = item;
                return { refId: id, name, hash, url, contentType, type };
              });
              newAttachment.value = JSON.stringify(updatedValue); // Serialize `value` to string
            }

            return newAttachment;
          });
        }
        // Recursively traverse the rest of the object
        Object.keys(newObj).forEach(key => {
          newObj[key] = serializeAttachments(newObj[key]);
        });
        return newObj;
      }

      return obj;
    };

    //filter out empty lines from item lists
    for (const key of Object.keys(formatted)) {
      const value = formatted[key];
      if (Array.isArray(value)) {
        const nonEmptyLines = value.filter(
          (line) => line && Object.keys(line).length !== 0
        );
        formatted[key] = nonEmptyLines;
      }
      if (key === "reference") {
        formatted[key] = JSON.stringify(formatted[key]);
      }
      if (key === "formValues") {
        formatted[key] = serializeAttachments(formatted[key]);
      }
    }

    return {
      ...formatted,
    };
  }

  allowedLinkTypesToDuplicate = [
    LinkType.Subject,
    LinkType.SucceededBy,
    LinkType.Witness,
    LinkType.Participant,
    LinkType.Assignee,
    LinkType.Victim,
    LinkType.CrisisCommitteeMember,
    LinkType.PBM,
    LinkType.Supplier,
    LinkType.Recommendation,
    LinkType.PartOf,
    LinkType.Scope,
    LinkType.Authorization,
    LinkType.Contact,
  ]

  componentDidMount() {
    const { match } = this.props;
    const { metadata } = this.state;
    const stepVisibility = metadata.wizard.stepVisibility;

    const filterStep = (step) => {
      if (stepVisibility === StepVisibility.Hidden) {
        const stepRequiredAction = step.requiredAction;
        return stepRequiredAction ? arxs.isActionAllowed(stepRequiredAction) : true;
      }

      return true;
    }

    const isStepReadOnly = (step) => {
      if (stepVisibility === StepVisibility.ReadOnly) {
        const stepRequiredAction = step.requiredAction;
        return stepRequiredAction ? !arxs.isActionAllowed(stepRequiredAction) : false;
      }

      return false;
    }

    let steps = metadata.wizard.steps
      .map((x) => ({
        ...x,
        ref: React.createRef(),
        readOnly: isStepReadOnly(x)
      }))
      .filter(x => filterStep(x));

    this.setState({
      steps,
    });

    this.subscriptions = {
      lookups: arxs.Api.lookups.subscribe(
        metadata.wizard.lookups || {},
        (lookups) => this.setState({ ...lookups }, this.onLookupsChange)
      ),
    };

    try {
      const settingsResource = this.state.metadata.settings?.getResource();
      if (settingsResource) {
        settingsResource
          .get()
          .then((response) =>
            this.setState({ processFlows: response["processFlows"] })
          );
      }
    } catch (e) {
      console.log(`Settings resource not implemented for ${metadata.module}`);
    }

    const { params } = match || {};

    const resource = this.state.metadata.base.getResource();
    if (params && params.id) {
      resource.getById(params.id).then((pristine) => {
        const mapped = this.deserialize(pristine);
        if (this.props.duplicate) {
          const inboundLinks = [];
          const outboundLinks = (mapped.outboundLinks || [])
            .filter(x => this.allowedLinkTypesToDuplicate.contains(x.type));

          let data = { ...mapped, inboundLinks, outboundLinks };
          const duplicateSourceData = { id: mapped.id, uniqueNumber: mapped.uniqueNumber };

          delete data.id;
          delete data.uniqueNumber;

          if (data.formValues) {
            const items = Object.values(data.formValues.itemsBySubject || {}).flatMap(Object.values);
            for (const item of items) {
              for (const media of (item.attachments || []).filter(x => x.type === FormValueAttachmentType.Media)) {
                for (const value of (JSON.parse(media.value) || [])) {
                  switch (value.Type) {
                    case ObjectDocumentType.FormImage:
                    case ObjectDocumentType.FormDocument:
                      (((((data.attachmentInfo || {}).attatchments || []).filter(x => x.type === value.Type) || [])[0] || {}).value || []).filter(x => x.id !== value.RefId);
                      break;
                    default: break;
                  }
                }
              }
              delete data.formValues;
            }
          }

          switch (this.state.metadata.module) {
            case OriginModuleEnum.Task:
            case OriginModuleEnum.PeriodicControl:
            case OriginModuleEnum.PeriodicMaintenance:
            case OriginModuleEnum.Consultancy:
              data.status = TaskStatus.InProcess;
              delete data.origins;
              break;
            default: delete data.status; break;
          }

          for (const key of Object.keys(data)) {
            if (Array.isArray(data[key]) && (this.state.metadata.wizard.fieldsWithIdsForDuplication || []).includes(key)) {
              for (let item of data[key]) {
                if (item.id) {
                  delete item.id;
                }
              }
            }
            else {
              if (key === "attachmentInfo") {
                let newAttachmentInfo = { objectId: data.attachmentInfo.objectId, module: data.attachmentInfo.module, attachments: [], storedFiles: [], documents: [] };

                for (const attachment of (data[key].attachments || []).filter(x => ![ObjectDocumentType.FormImage, ObjectDocumentType.FormDocument].includes(x.type))) {
                  let newAttachment = { type: attachment.type, value: [] }

                  for (const v of attachment.value || []) {
                    const newId = arxs.uuid.generate();

                    newAttachment.value.push({ ...v, id: newId });

                    switch (v.type) {
                      case AttachmentType.StoredFile: newAttachmentInfo.storedFiles.push({ ...(data[key].storedFiles || []).filter(x => x.id === v.id)[0], id: newId }); break;
                      case AttachmentType.Document: newAttachmentInfo.documents.push({ ...(data[key].documents || []).filter(x => x.id === v.id)[0], id: newId }); break;
                      case AttachmentType.Weblink: break;
                      default: return;
                    }
                  }

                  newAttachmentInfo.attachments.push(newAttachment);
                }

                if (data.formDefinition) {
                  const imagesControlId = ((data.formDefinition.controls || []).filter(x => x.type === "images")[0] || {}).id;
                  if (imagesControlId) {
                    const imageItems = (data.formDefinition.items || []).filter(x => x.control === imagesControlId);

                    if (imageItems.some(x => x)) {
                      if (!newAttachmentInfo.attachments.some(x => x.type === ObjectDocumentType.FormImage)) {
                        newAttachmentInfo.attachments.push({ type: ObjectDocumentType.FormImage, value: [] });
                      }
                    }

                    for (let imageItem of imageItems) {
                      const imageData = JSON.parse(imageItem.data || "[]")
                      const idMap = (imageData).map(x => ({ oldId: x, newId: arxs.uuid.generate() }));
                      const newAttachmentValues = (((data.attachmentInfo.attachments
                        .filter(x => x.type === ObjectDocumentType.FormImage)[0] || {}).value || [])
                        .filter(x => (imageData).includes(x.id)) || [])
                        .map(x => ({ ...x, id: (idMap.filter(y => y.oldId === x.id)[0] || {}).newId }));
                      const newStoredFiles = (data.attachmentInfo.storedFiles || [])
                        .filter(x => (imageData).includes(x.id)).map(x => ({ ...x, id: (idMap.filter(y => y.oldId === x.id)[0] || {}).newId }));

                      ((newAttachmentInfo.attachments.filter(x => x.type === ObjectDocumentType.FormImage)[0] || {}).value || []).push(...newAttachmentValues);
                      (newAttachmentInfo.storedFiles || []).push(...newStoredFiles)

                      imageItem.data = (idMap || []).map(x => x.newId);

                      data.formDefinition.items.filter(x => x.id === imageItem.id)[0].data = JSON.stringify(imageItem.data);
                    }
                  }
                }

                data.attachmentInfo = newAttachmentInfo;

              } else if (typeof data[key] === "TagRef"
                || (this.state.metadata.wizard.fieldsWithIdsForDuplication || []).includes(key)) {
                delete data[key].id;
              }
            }
          }

          this.setState(
            { isGeoLocationInherited: false, isLoaded: true, data, duplicateSourceData },
            () => {
              this.triggerOnLoad(steps);
            }
          );
        } else {
          this.setState(
            { isGeoLocationInherited: false, isLoaded: true, pristine: mapped },
            () => {
              this.triggerOnLoad(steps);
            }
          );
        }
      });
    } else {
      const { data } = this.state;
      const mapped = this.deserialize(data);
      this.setState({ data: mapped }, () => {
        this.triggerOnLoad(steps);
        this.state.metadata && this.state.metadata.module !== undefined && this.state.metadata.wizard.wizardClass
          .setInitialValues(this.stateProxy)
          .then(() => this.setState({ isLoaded: true }));
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.validation && !_.isEqual(prevState.validation, this.state.validation) && Object.keys(this.state.validation).any(x => x)) {
      this.scrollTo(this.state.validationRef);
    }
  }

  triggerOnLoad = async (steps) => {
    for (const step of steps || {}) {
      for (const row of step.fields || []) {
        for (const field of row || []) {
          if (field.productType === undefined || field.productType === arxs.productType) {
            if (field.onLoad) {
              await field.onLoad(this.stateProxy);
            }
          }
        }
      }
    }
  };

  componentWillUnmount() {
    if (this.subscriptions) {
      this.subscriptions.lookups.dispose();
    }
  }

  scrollTo = (ref) => {
    const scroll = (ref) => {
      const pos = ref.style.position;
      const top = ref.style.top;
      ref.style.position = "relative";
      ref.style.top = "-70px";
      ref.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
      ref.style.top = top;
      ref.style.position = pos;
    }

    if (ref) {
      if (ref.current) {
        if (ref.current.innerRef) {
          this.scrollTo(ref.current.innerRef);
        }
        else {
          scroll(ref.current);
        }
      } else {
        scroll(ref);
      }
    }

  };

  navigateToStep = (stepIndex) => {
    this.setState({ stepIndex }, () =>
      this.scrollTo(this.state.steps[stepIndex].ref)
    );
  };

  getGeoLocationFromContext = () => {
    const inheritedLocations = [
      "subject",
      "location",
      "building",
      "branch",
      "legalStructure",
    ]
      .map(this.stateProxy.getField)
      .filter((x) => x && x.geoLocation)
      .map((x) => x.geoLocation);
    const inheritedLocation = inheritedLocations[0];
    return inheritedLocation ? { ...inheritedLocation } : null;
  };

  triggerFieldOnChange = (definition, fieldName, child, context) => {
    fieldName = fieldName || definition.name;

    setTimeout(() => {
      if (this.state.hasGeoLocation) {
        const isNew = !(
          this.props.match &&
          this.props.match.params &&
          this.props.match.params.id
        );
        if (isNew) {
          switch (fieldName) {
            case "legalStructure":
            case "branch":
            case "building":
            case "location":
            case "subject":
              if (this.state.isGeoLocationInherited) {
                const inheritedLocation = this.getGeoLocationFromContext();
                this.stateProxy.setField("geoLocation", inheritedLocation);
              }

              break;
            case "geoLocation":
              this.setState({ isGeoLocationInherited: false });
              break;
            default:
              break;
          }
        }
      }

      if (definition.onChange) {
        if (Array.isArray(definition.onChange)) {
          for (const onChangeHandler of definition.onChange) {
            onChangeHandler(this.stateProxy, definition.name, child, fieldName);
          }
        } else {
          definition.onChange(
            this.stateProxy,
            definition.name,
            child,
            fieldName,
            context
          );
        }
      }
    }, 50);
  };

  setField = (definition, fieldName, value) => {
    const data = { ...this.state.data, [fieldName]: value };
    this.setData(data, () => this.triggerFieldOnChange(definition, fieldName));
  };

  navigateToHome = (card) => {
    if (this.props.callback) {
      this.props.callback();
    } else {
      this.props.history.goBack();
    }
  }

  handleCancel = () => {
    this.navigateToHome();
  };

  handleSave = (context, onConfirm, onSave) => {
    const data = {
      ...this.state.pristine,
      ...this.state.data,
    };

    if (this.validateImpl()) {
      if (onSave) {
        return new Promise((resolve, reject) => {

          this.setData(null, () => {
            onSave(data);
            window.onbeforeunload = null;
            resolve();
          });
        });
      } else {
        const payload = this.serialize(data);
        const resource = this.state.metadata.base.getResource();
        const action = payload && payload.id ? resource.post(payload.id, payload) : resource.put(payload);
        return action.then((response) => {
          switch (response.status) {
            case 202:
              response.json().then((id) => {
                this.setData(null, () => {
                  Toaster.success(arxs.t("wizard.save_successful"));

                  window.onbeforeunload = null;

                  if (onConfirm) {
                    onConfirm();
                  } else if (this.state.autoClose) {
                    setTimeout(window.close, 2000);
                  } else {
                    this.navigateToHome({ id, module: this.props.module });
                  }
                });
              });
              break;
            case 400:
              response.json().then(x => {
                if (x && x.error) {
                  Toaster.error(x.error);
                } else {
                  Toaster.error(arxs.t("wizard.save_failed"));
                }
              })
              break;
            case 422:
              response.json().then(this.handleValidationErrors);
              break;
            default:
              Toaster.error(`Unhandled response, ${JSON.stringify(response)}`);
              arxs.logger.info(
                "Unhandled response from Wizard.handleSave() {response}",
                response
              );
              break;
          }
        }).catch((error) => {
          Toaster.error(arxs.t("wizard.save_failed"));
        });
      }
    } else {
      return new Promise((resolve) => resolve());
    }
  };

  getRequiredFields(getCurrentFieldValue) {
    return [];
  }

  validateImpl = () => {
    const schemaName = this.state.metadata.base.name;
    const schema = arxs.Api.getSchema(schemaName) || {};

    const { data, pristine } = this.state;
    const getCurrentFieldValue = (fieldName) =>
      data[fieldName] === undefined ? pristine[fieldName] : data[fieldName];
    const requiredFields = this.getRequiredFields(getCurrentFieldValue);

    let validation = {};

    const getFieldTitle = (field) => {
      if (field === "type") {
        return arxs.t("field.category");
      }
      return arxs.t(`field.${field}`);
    };

    for (const [key] of Object.entries(schema.properties)) {
      //exclude validation of sort & kind as it is automatically filled if applicable with the usage of the codeelementlist control
      if (!["sort", "kind"].contains(key)) {
        const value = getCurrentFieldValue(key);
        const required =
          (schema.required && schema.required.indexOf(key) > -1) ||
          requiredFields.indexOf(key) > -1;
        const fieldSchema = schema.properties[key];
        const isArray = fieldSchema.type === "array";
        if ((!value || (isArray && value.length === 0)) && required) {
          //if field 'type', use custom translation.
          if (schema.properties.legalStructure
            && schema.properties.branch
            && key.includes("legalStructure", "branch", "building", "location")) {
            validation[key] = {
              error: arxs.t("wizard.validation.field_is_required", {
                field: getFieldTitle("contextLocation"),
              }),
            };
          } else {
            validation[key] = {
              error: arxs.t("wizard.validation.field_is_required", {
                field: getFieldTitle(key),
              }),
            };
          }
        }

        if (value && isArray && value.length > 0) {
          if (fieldSchema.items && fieldSchema.items["$ref"]) {
            const link = fieldSchema.items["$ref"];
            if (link) {
              const match = link.match(/.*\/([^/]+)/);

              const ref = match[1];
              const refSchema = arxs.Api.getSchema(ref);

              if (refSchema.required) {
                const validationsForKey = [];
                let hasErrors;
                let rowIndex = 1;
                for (const valueRecord of value) {
                  const validationsForItem = {};
                  for (const requiredField of refSchema.required) {
                    const subValue = valueRecord[requiredField];
                    if (subValue === undefined || /^\s*$/.test(subValue)) {
                      validationsForItem[requiredField] = {
                        error: arxs.t(
                          "wizard.validation.field_is_required_on_parent_at_row",
                          {
                            parent: getFieldTitle(key),
                            field: getFieldTitle(requiredField),
                            row: rowIndex,
                          }
                        ),
                      };
                      hasErrors = true;
                    }
                  }
                  validationsForKey.push(validationsForItem);
                  rowIndex++;
                }
                if (hasErrors) {
                  validation[key] = validationsForKey;
                }
              }
            }
          }
        }
      }

      if (key === "schedule") {
        const schedule = getCurrentFieldValue("schedule");
        if (schedule) {
          if (schedule.start || schedule.end) {
            if (schedule.start && schedule.end && new Date(schedule.end) < new Date(schedule.start)) {
              validation[key] = {
                error: arxs.t("wizard.validation.end_date_before_start_date")
              }
            }
          }
          if (schedule.recurrenceRule) {
            const options = RRule.parseString(schedule.recurrenceRule);
            switch (options.freq) {
              case RRule.YEARLY:
                if (!options.bymonth && !options.bymonthday && (!options.byweekday || options.byweekday.length === 0) && !options.bysetpos) {
                  validation[key] = {
                    error: arxs.t("wizard.validation.schedule_incomplete")
                  }
                } else
                  if (!options.bymonth) {
                    validation[key] = {
                      error: arxs.t("wizard.validation.schedule_incomplete")
                    }
                  } else {
                    if (!options.bymonthday && (!options.byweekday || options.byweekday.length === 0) && !options.bysetpos) {
                      validation[key] = {
                        error: arxs.t("wizard.validation.schedule_incomplete")
                      }
                    } else {
                      if (((!options.byweekday || options.byweekday.length === 0) && options.bysetpos)) {
                        validation[key] = {
                          error: arxs.t("wizard.validation.schedule_incomplete")
                        }
                      } else {
                        const rule = new RRule({ ...options, dtstart: new Date(), count: 1 });
                        const dates = rule.all();
                        if ((dates || []).length === 0) {
                          validation[key] = {
                            error: arxs.t("wizard.validation.schedule_incorrect_date")
                          }
                        }
                      }
                    }
                  }
                break;
              case RRule.MONTHLY:
                if (!options.interval) {
                  validation[key] = {
                    error: arxs.t("wizard.validation.schedule_incomplete")
                  }
                } else {
                  if ((!options.byweekday || options.byweekday.length === 0) && !options.bysetpos && !options.bymonthday) {
                    validation[key] = {
                      error: arxs.t("wizard.validation.schedule_incomplete")
                    }
                  } else {
                    if (((!options.byweekday || options.byweekday.length === 0) && options.bysetpos)) {
                      validation[key] = {
                        error: arxs.t("wizard.validation.schedule_incomplete")
                      }
                    } else {
                      const rule = new RRule({ ...options, dtstart: new Date(), count: 1 });
                      const dates = rule.all();
                      if ((dates || []).length === 0) {
                        validation[key] = {
                          error: arxs.t("wizard.validation.schedule_incorrect_date")
                        }
                      }
                    }
                  }
                }
                break;
              case RRule.WEEKLY:
                if (!options.byweekday || options.byweekday.length === 0) {
                  validation[key] = {
                    error: arxs.t("wizard.validation.schedule_incomplete")
                  }
                } else {
                  const rule = new RRule({ ...options, dtstart: new Date(), count: 1 });
                  const dates = rule.all();
                  if ((dates || []).length === 0) {
                    validation[key] = {
                      error: arxs.t("wizard.validation.schedule_incorrect_date")
                    }
                  }
                }
                break;
              case "NONE":
                break;
              default: break;
            }
          }
        }
      }
    }

    validation = this.state.metadata.wizard.wizardClass.validate
      ? this.state.metadata.wizard.wizardClass.validate(
        this.stateProxy,
        getCurrentFieldValue,
        validation
      )
      : validation;

    this.setState({ validation });

    return Object.keys(validation).length === 0;
  };

  handleValidationErrors = (validationErrors) => {
    Toaster.error(`Er ging iets mis. ${JSON.stringify(validationErrors)}`);
    arxs.logger.error("Wizard validation failed: {validationErrors}", validationErrors);
  };

  handleAddTags = (added) => {
    const { pristine, data } = this.state;

    const pristineTags = pristine.tags || [];
    const oldTags = data.tags;
    const newTags = (
      oldTags ? [...oldTags, ...added] : [...pristineTags, ...added]
    ).distinct((x) => x.id);

    this.setData({ ...data, tags: newTags });
  };

  handleDeleteTag = (tag) => {
    const { pristine, data } = this.state;
    const oldTags = data.tags || pristine.tags;
    const newTags = oldTags.filter((x) => x.id !== tag.id);
    this.setData({ ...data, tags: newTags });
  };

  handleDeleteAttachment = (attachment, def) => {
    const { data, pristine, metadata } = this.state;
    const attachmentInfo = data.attachmentInfo || pristine.attachmentInfo || {};
    let newAttachmentInfo = _.cloneDeep(attachmentInfo)

    var typeToDelete = newAttachmentInfo.attachments.filter(x => x.value.map(x => x.id).includes(attachment.id))[0].type;
    let newAttachmentValues = newAttachmentInfo.attachments.filter(x => x.type === typeToDelete)[0].value

    if (typeToDelete === ObjectDocumentType.MainDocument && metadata.module === OriginModuleEnum.Document) {
      ;
      var origValue = newAttachmentValues.filter(x => x.id === attachment.id)[0];
      newAttachmentValues = newAttachmentValues.filter(x => x.id !== attachment.id);
      newAttachmentInfo.attachments.filter(x => x.type === ObjectDocumentType.MainDocument)[0].value = newAttachmentValues;

      if (!newAttachmentInfo.attachments.some(x => x.type === ObjectDocumentType.AdditionalDocument)) {
        newAttachmentInfo.attachments.push({ type: ObjectDocumentType.AdditionalDocument, value: [] });
      }

      const name = ((origValue.props || {}).name || newAttachmentInfo.storedFiles.filter(x => x.id === origValue.id)[0].name) + "_" + arxs.dateTime.formatDateTime(Date());

      newAttachmentInfo.attachments.filter(x => x.type === ObjectDocumentType.AdditionalDocument)[0].value.push({ ...origValue, props: { ...origValue.props, "name": name } });
    } else {
      let typeAttachments = newAttachmentInfo.attachments.filter(x => x.type === typeToDelete)[0];

      let newTypeAttachments = typeAttachments.value.filter(x => x.id !== attachment.id);
      let typeAttachmentToDelete = typeAttachments.value.filter(x => x.id === attachment.id)[0];
      typeAttachmentToDelete.isDeleted = true;
      newTypeAttachments.push(typeAttachmentToDelete);

      newAttachmentInfo.attachments.filter(x => x.type === typeToDelete)[0].value = newTypeAttachments;
    }

    this.setData({ ...data, attachmentInfo: newAttachmentInfo }, () => this.triggerFieldOnChange(def));
  };

  handleAddAttachments = (added, def) => {
    const { pristine, data } = this.state;
    const attachmentInfo = data.attachmentInfo || pristine.attachmentInfo || { attachments: [], documents: [], storedFiles: [] };
    let newAttachmentInfo = { ...attachmentInfo };

    if (!newAttachmentInfo.attachments) {
      newAttachmentInfo.attachments = [];
    }

    const getAttachmentFromAdded = (addedForType, attachmentType) => {

      const getProps = (item, type) => {
        let props = {};

        switch (type) {
          case AttachmentType.Weblink:
            props.url = item.url;
            props.name = item.name;
            break;
          case AttachmentType.Document:
            if (item.url) {
              props.url = item.url;
            }
            break;
          case AttachmentType.StoredFile:
            props.name = item.name;
            break;
          default:
            break;
        }

        return props;
      }

      switch (attachmentType) {
        case AttachmentType.Weblink: return addedForType.map(x => ({ id: x.id, type: AttachmentType.Weblink, props: getProps(x, AttachmentType.Weblink), isDeleted: false }));
        case AttachmentType.Document: return addedForType.map(x => ({ id: x.id, type: AttachmentType.Document, props: getProps(x, AttachmentType.Document), isDeleted: false }));
        case AttachmentType.StoredFile: return addedForType.map(x => ({ id: x.id, type: AttachmentType.StoredFile, props: getProps(x, AttachmentType.StoredFile), isDeleted: false }));
        default: return;
      }
    }

    const getStoredFileFromAdded = (addedForType) => {
      return addedForType.map(x => ({ id: x.id, contentType: x.contentType, url: x.previewUrl, name: x.name, hash: x.hash }));
    }

    const getDocumentsFromAdded = (addedForType) => {
      return addedForType.map(x => ({ id: x.id, documentId: x.documentId || x.objectId, name: x.name, contentType: x.contentType, props: { url: x.url } }));
    }

    if (added.documents) {
      for (const type of added.documents.map(x => x.type).distinct(x => x)) {
        const addedForType = added.documents.filter(x => x.type === type).map(x => ({ ...x, id: arxs.uuid.generate() }));

        const attachmentType = addedForType.some(x => x.documentId) ? AttachmentType.Document : AttachmentType.StoredFile;

        const isImageType = type === ObjectDocumentType.Image;

        let newAttachments = (isImageType && getAttachmentFromAdded(addedForType.filter(x => x.isPreferredImage), attachmentType)) || [];
        newAttachments.push(...(((newAttachmentInfo.attachments || []).filter(x => x.type === type)[0] || {}).value || []));
        newAttachments.push(...(getAttachmentFromAdded(addedForType.filter(x => isImageType ? !x.isPreferredImage : x), attachmentType)))

        if (newAttachmentInfo.attachments.some(x => x.type === type)) {
          newAttachmentInfo.attachments = newAttachmentInfo.attachments.filter(x => x.type !== type);
        }

        newAttachmentInfo.attachments.push({ type: type, value: newAttachments });

        if (attachmentType === AttachmentType.StoredFile) {
          newAttachmentInfo.storedFiles = (newAttachmentInfo.storedFiles || []).concat(getStoredFileFromAdded(addedForType));
        } else {
          newAttachmentInfo.documents = (newAttachmentInfo.documents || []).concat(getDocumentsFromAdded(addedForType));
        }
      }
    }

    if (added.weblinks) {
      for (const type of added.weblinks.map(x => x.type).distinct(x => x)) {
        const addedForType = added.weblinks.filter(x => x.type === type).map(x => ({ id: arxs.uuid.generate(), ...x }));

        if (newAttachmentInfo.attachments.some(x => x.type === type)) {
          newAttachmentInfo.attachments.filter(x => x.type === type)[0].value = newAttachmentInfo.attachments.filter(x => x.type === type)[0].value.concat(getAttachmentFromAdded(addedForType, AttachmentType.Weblink));
        }
        else {
          newAttachmentInfo.attachments.push({ type: type, value: getAttachmentFromAdded(addedForType, AttachmentType.Weblink) });
        }
      }
    }

    this.setData({ ...data, attachmentInfo: newAttachmentInfo }, () => this.triggerFieldOnChange(def));
  };

  handleSortImages = (sorted) => {
    const { data, pristine } = this.state;
    let attachmentInfo = data.attachmentInfo || pristine.attachmentInfo || {};
    const originalImageAttachments = attachmentInfo.attachments.filter(x => x.type === ObjectDocumentType.Image)[0].value;
    let newAttachments = [];

    for (const image of sorted) {
      newAttachments.push(originalImageAttachments.filter(x => x.id === image.id)[0])
    }

    attachmentInfo.attachments.filter(x => x.type === ObjectDocumentType.Image)[0].value = newAttachments;

    this.setData({ ...data, attachmentInfo });
  };

  getAttachmentStoreTypePropName = (storeType) => {
    switch (storeType) {
      case AttachmentType.StoredFile: return "storedFiles";
      case AttachmentType.Weblink: return "webLinks";
      case AttachmentType.Comment: return "comments";
      case AttachmentType.Document: return "documents";
      default: return;
    }
  }

  handleRenameAttachment = (attachment, def) => {
    const { pristine, data } = this.state;
    let attachmentInfo = data.attachmentInfo || pristine.attachmentInfo;
    let newAttachmentInfo = _.cloneDeep(attachmentInfo)

    for (const typeAttachments of newAttachmentInfo.attachments) {
      if ((typeAttachments.value || []).some(x => x.id === attachment.id)) {
        let attachmentToRename = newAttachmentInfo.attachments.filter(x => x.type === typeAttachments.type)[0].value.filter(x => x.id === attachment.id)[0];
        attachmentToRename.props = { ...attachmentToRename.props, name: attachment.value };
        this.setData({ ...data, attachmentInfo: newAttachmentInfo }, () => this.triggerFieldOnChange(def));
      }
    }
  };

  handleNavigatorAction = (context) => {
    const action = this.state.metadata.wizard.navigatorAction;
    if (!action) {
      return;
    }

    const route = action.route;

    const onConfirmNavigation = () => {
      this.props.history.push({ pathname: route });
    };

    if (this.state.isDirty) {
      const confirmation = createInputPopup(
        context,
        arxs.t("wizard.confirm_save"),
        () => {
          this.handleSave(context, onConfirmNavigation);
        }
      );
      context.inputPopup.show(confirmation);
    } else {
      onConfirmNavigation();
    }
  };

  objectEquals = (left, right) => {
    if (
      typeof left === "number" ||
      typeof left === "string" ||
      typeof left === "boolean" ||
      left instanceof Date
    ) {
      return left === right;
    }
    // left      | Right     |  Equal
    //---------------------------------
    // undefined | undefined | True
    // null      | null      | True
    // undefined | null      | False
    // null      | undefined | False
    // undefined | "abc"     | False
    // "abc"     | undefined | False
    // "abc"     | null      | False
    if (left === right && !left) {
      return true;
    }
    if (!left || !right) {
      return false;
    }
    if (Array.isArray(left) && Array.isArray(right)) {
      if (left.length === right.length) {
        const itemEquality = left.zip(right, this.objectEquals);
        return itemEquality.all((x) => x);
      }
      return false;
    }
    if (typeof left === "object") {
      // in case of a record of a listitem the comparison below is not true
      // if (left.id || right.id) {
      //   return left.id === right.id;
      // } else
      // {
      const keyValues = Object.keys(left)
        .concat(Object.keys(right))
        .distinct()
        .map((x) => [x, left[x]]);
      const keyEquality = keyValues.map(([key, value]) => {
        return this.objectEquals(value, right[key]);
      });
      return keyEquality.all((x) => x);
      // }
    }
    return false;
  };

  hasChanges = (pristine, data) => {
    const keyValues = Object.keys(pristine)
      .concat(Object.keys(data))
      .distinct()
      .map((x) => [x, pristine[x]]);
    const keyEquality = keyValues.map(([key, value]) => {
      return this.objectEquals(value, data[key]);
    });
    return keyEquality.any((x) => !x);
  };

  setData = (data, callback) => {
    if (!data) {
      this.setState({ isDirty: false, data: {} }, callback);
      window.onbeforeunload = null;
      return;
    }

    const pristineWithDiffs = { ...this.state.pristine, ...data };
    const hasPristineChanged = this.hasChanges(
      this.state.pristine,
      pristineWithDiffs
    );
    const isDirty = Object.keys(data).length > 0 && hasPristineChanged;
    const hasDataChanged = this.hasChanges(this.state.data, data);
    if (hasDataChanged) {
      this.setState({ isDirty, data }, callback);
    } else if (this.state.isDirty !== isDirty) {
      this.setState({ isDirty }, callback);
    }

    window.onbeforeunload = isDirty ? () => true : null;
  };

  render() {
    const module = this.state.metadata.module;
    const schemaName = this.state.metadata.base.name;
    const schema = arxs.Api.getSchema(schemaName) || {};
    const properties = schema.properties || {};

    const { data, pristine, uploadCorrelationKey } = this.state;

    const isFieldName = (definition) => {
      return typeof definition === "string";
    };

    const getFieldName = (definition) => {
      return isFieldName(definition) ? definition : definition.name;
    };

    const isRequired = (definition) => {
      const fieldName = getFieldName(definition);
      return (
        (schema.required && schema.required.indexOf(fieldName) > -1) ||
        (definition.props && definition.props.required)
      );
    };

    const isHiddenInWizard = (definition) => {
      return definition.props && definition.props.hideInTemplate;
    }

    const isDisabled = (definition) => {
      const disabled = (definition.props || {}).disabled;
      const disabledOnEditProp = (definition.props || {}).disabledOnEdit;
      let disabledOnEdit;
      if (typeof disabledOnEditProp === "function") {
        disabledOnEdit = disabledOnEditProp(this.stateProxy);
      } else {
        disabledOnEdit = disabledOnEditProp;
      }
      const isEdit = !!(
        this.props.match &&
        this.props.match.params &&
        this.props.match.params.id &&
        this.props.match.path &&
        this.props.match.path.indexOf("duplicate") === -1
      );
      return disabled || (isEdit && disabledOnEdit);
    };

    const getCurrentFieldValue = (fieldName) =>
      data[fieldName] === undefined ? pristine[fieldName] : data[fieldName];

    const metadata = this.state.metadata;
    const stepThatSetsSecurityContext = this.state.steps.filter(
      (x) => x.getSecurityContext
    )[0];
    const getSecurityContext = stepThatSetsSecurityContext
      ? stepThatSetsSecurityContext.getSecurityContext
      : null;
    const securityContext = getSecurityContext
      ? getSecurityContext(metadata.base.writeAction, getCurrentFieldValue)
      : arxs.securityContext.buildForUserContext();

    const getField = (definition) => {
      const fieldName = getFieldName(definition);
      const parentName = isFieldName(definition) ? null : definition.parent;

      const schema = properties[fieldName];

      const {
        serialize,
        deserialize,
        mapFromModel,
        mapToModel,
        values,
        title,
        unit,
      } = definition;

      const getter = () => {
        if (deserialize) {
          return deserialize(data, pristine, definition);
        }
        const value = getCurrentFieldValue(fieldName);
        return mapFromModel ? mapFromModel(value) : value;
      };

      const setter = (value) => {
        if (serialize) {
          serialize(definition, this.setField, value);
        } else {
          this.setField(
            definition,
            fieldName,
            mapToModel ? mapToModel(value) : value
          );
        }
      };

      const parentGetter = parentName
        ? () => getCurrentFieldValue(parentName)
        : null;

      const filter = definition.filter;

      const required = isRequired(definition);
      const disabled = isDisabled(definition);
      const validation = this.state.validation[fieldName];

      const code = typeof (definition.code) === "function" ? definition.code(this.stateProxy) : definition.code;

      const props = { ...definition.props, ...this.state.fieldProps[fieldName] };

      return {
        name: fieldName,
        title,
        schema,
        code,
        readOnly: this.props.readOnly || (definition.props || {}).readOnly,
        disabled,
        required,
        unit,
        getter,
        setter,
        parentGetter,
        parent: definition.parent,
        filter,
        validation,
        values,
        securityContext: securityContext,
        props,
        isLoaded: this.state.isLoaded,
        typeOverride: definition.type,
        stateProxy: this.stateProxy,
        module: this.state.metadata.module,
        preFilter: definition.preFilter
      };
    };

    const splitIntoFieldPerRowOnMobile = (context, row) => {
      let rows = [row];
      if (context.platform.isMobile) {
        rows = [];
        for (const field of row) {
          rows.push([field]);
        }
      }
      return rows;
    };

    const mapToDefinition = (fieldNameOrDefinition) => {
      const def =
        typeof fieldNameOrDefinition === "string"
          ? { name: fieldNameOrDefinition }
          : fieldNameOrDefinition;
      return { ...def };
    };

    const renderField = (context, def, stepIndex, i, j, isStepReadOnly) => {
      const { readOnly } = this.props;
      const fieldProps = { ...def.props, ...this.state.fieldProps[def.name] };
      let index = "";
      if (fieldProps && fieldProps.index) {
        index = `-${fieldProps.index}`
      }
      const key = `step-${stepIndex}-${def.name}${index}`;

      const isFieldName = typeof def === "string";
      const fieldName = isFieldName ? def : def.name;
      const field = properties[fieldName];
      const isArray = field && field.type === "array";
      const required = isRequired(def);

      const actualReadOnly = isStepReadOnly === true ? true : (readOnly || false);

      const coalesce = function () { // "arguments" isn't supported in arrow functions
        for (let i = 0; i < arguments.length; i++) {
          const value = arguments[i];
          if (value) {
            return Array.isArray(value) ? value : [value];
          }
        }
        return [];
      };

      const subjectRefs = coalesce(
        this.state.data.subject,
        this.state.pristine.subject,
        this.state.data.subjects,
        this.state.pristine.subjects);

      const subjects = subjectRefs
        .map(x => arxs.Api.lookups.resolveSubject(x));

      const legalStructures = coalesce(
        this.state.data.legalStructure,
        this.state.pristine.legalStructure,
        this.state.data.legalStructures,
        this.state.pristine.legalStructures)
        .concat(subjects.map(x => x.legalStructure).filter(x => x))
        .concat(subjects.filter(x => x.module === OriginModuleEnum.SchoolGroup));

      const branches = coalesce(
        this.state.data.branch,
        this.state.pristine.branch,
        this.state.data.branches,
        this.state.pristine.branches)
        .concat(subjects.map(x => x.branch).filter(x => x))
        .concat(subjects.filter(x => x.module === OriginModuleEnum.School));

      const buildings = coalesce(
        this.state.data.building,
        this.state.pristine.building,
        this.state.data.buildings,
        this.state.pristine.buildings);

      const locations = coalesce(
        this.state.data.location,
        this.state.pristine.location,
        this.state.data.locations,
        this.state.pristine.locations);

      const filter = {};

      if (fieldProps) {
        if (!fieldProps.overridePrefilter) {
          filter["location"] = locations.toDictionary(
            (x) => x.id,
            (x) => true
          );
          filter["building"] = buildings.toDictionary(
            (x) => x.id,
            (x) => true
          );
          filter["branch"] = branches.toDictionary(
            (x) => x.id,
            (x) => true
          );
          filter["legalStructure"] = legalStructures.toDictionary(
            (x) => x.id,
            (x) => true
          );
        }

        if (fieldProps.setFilterOnField) {
          let fieldValues =
            this.state.data[fieldProps.setFilterOnField] ||
            this.state.pristine[fieldProps.setFilterOnField];
          if (fieldValues) {
            if (!Array.isArray(fieldValues)) {
              fieldValues = [fieldValues];
            }
            filter[fieldProps.setFilterOnField] = fieldValues.toDictionary(
              (x) => x.id,
              (x) => true
            );
          }
        }
      }

      if (def.render) {
        return def.render(this.stateProxy);
      } else if (def.label) {
        return <label key={key}>{def.label}</label>;
      } else if (def.type === "formContainer") {
        let card = { ...pristine, ...data };

        const onChange = (value) => {
          const data = { ...this.state.data, formDefinition: value.formDefinition, formValues: value.formValues };
          this.setData(data);
        }

        return (
          <FormContainer
            className="field full-width"
            key={key}
            module={this.state.metadata.module}
            readOnly={actualReadOnly}
            card={card}
            onChange={(value) => onChange(value)}
            {...fieldProps}
            stateProxy={this.stateProxy} />

        )
      }
      else if (def.type === "tag") {
        const tags = this.state.data.tags || this.state.pristine.tags;
        return (
          <TagOverview
            className="field full-width"
            key={key}
            module={this.state.metadata.module}
            readOnly={actualReadOnly}
            tags={tags}
            onAdd={this.handleAddTags}
            onDelete={this.handleDeleteTag}
            title={arxs.t("wizard.tags")}
            securityContext={securityContext}
            {...fieldProps}
          />
        );
      } else if (def.type === "image") {
        const attachmentInfo = this.state.data.attachmentInfo || this.state.pristine.attachmentInfo || {};

        let imageAttachments = ((attachmentInfo || {}).attachments || []).filter(
          (x) =>
            x.type &&
            ObjectDocumentType.Image === x.type
        );

        const images = ((attachmentInfo || {}).storedFiles || [])
          .filter((x) =>
            imageAttachments
              .flatMap((y) => y.value?.filter((x) => !x.isDeleted))
              .map((z) => z && z.id)
              .includes(x.id)
          )
          .map((x) => ({
            ...x,
            name:
              (
                (
                  (imageAttachments
                    .flatMap((y) => y.value)
                    .filter((z) => z && z.id === x.id) || [])[0] || {}
                ).props || {}
              ).name || x.name,
          }));

        return (
          <ImageOverview
            className="field full-width"
            key={key}
            module={this.state.metadata.module}
            readOnly={actualReadOnly}
            data={images}
            onAdd={x => this.handleAddAttachments(x, def)}
            onDelete={x => this.handleDeleteAttachment(x, def)}
            onSort={this.handleSortImages}
            correlationKey={uploadCorrelationKey}
            {...fieldProps}
          />
        );
      } else if (def.type === "icon") {
        const addIcon = (icons) => {
          const originalItems = [...(getCurrentFieldValue(fieldName) || [])];
          const newItems = Object.values(icons).filter(x => !originalItems.map(y => y.iconId).includes(x.iconId));

          const items = originalItems.concat(newItems);
          const data = { ...this.state.data, [fieldName]: items };
          this.setData(data);
        };

        const deleteIcon = (icon) => {
          const items = [...(getCurrentFieldValue(fieldName) || [])];
          const data = { ...this.state.data, [fieldName]: items.filter(x => x !== icon) };
          this.setData(data);
        }

        return (
          <IconOverview
            className="field full-width"
            key={key}
            readOnly={actualReadOnly}
            data={this.state.data[fieldName] || this.state.pristine[fieldName]}
            onAdd={addIcon}
            onDelete={deleteIcon}
            title={def.title || arxs.t(`field.${def.name}`)}
            module={this.state.metadata.module}
            {...fieldProps}
          />
        );
      } else if (def.type === "document") {
        const attachmentInfo = this.state.data.attachmentInfo || this.state.pristine.attachmentInfo || {};
        return (
          <DocumentOverview
            className="field full-width"
            key={key}
            module={this.state.metadata.module}
            readOnly={actualReadOnly}
            attachmentInfo={attachmentInfo}
            onAdd={x => this.handleAddAttachments(x, def)}
            onRename={x => this.handleRenameAttachment(x, def)}
            onDelete={x => this.handleDeleteAttachment(x, def)}
            objectId={this.state.pristine.id}
            securityContext={securityContext}
            correlationKey={uploadCorrelationKey}
            disableInlineEdit={true}
            {...fieldProps}
          />
        );
      } else if (def.type === "itemlist") {
        const title = def.title || arxs.t(`field.${def.name}`);
        const setChildField = (child, fieldName, value) => {
          const items = [...(getCurrentFieldValue(def.name) || [])];
          const index = items.indexOf(child);
          if (index > -1) {
            items[index] = { ...child, [fieldName]: value };
          }
          const data = { ...this.state.data, [def.name]: items };
          this.setData(data, () =>
            this.triggerFieldOnChange(def, fieldName, items[index])
          );
        };

        return (
          <ItemList
            className="field full-width"
            key={key}
            title={title}
            field={getField(def)}
            setField={setChildField}
            names={def.children}
            readOnly={actualReadOnly}
            onDelete={def.onDelete}
            noHeaders={def.noHeaders}
            preferredOnly={def.preferredOnly}
            securityContext={securityContext}
            stateProxy={this.stateProxy}
            validation={this.state.validation[fieldName]}
            {...fieldProps}
          />
        );
      } else if (def.type === "relationshiplist") {
        const onChange = (value) => {
          const data = { ...this.state.data, [def.name]: value };
          this.setData(data, () => this.triggerFieldOnChange(def));
        };

        return (
          <RelationshipList
            className="field full-width"
            key={key}
            field={getField(def)}
            readOnly={actualReadOnly}
            value={this.state.data[def.name] || this.state.pristine[def.name]}
            onChange={onChange}
            prefilter={filter}
            securityContext={securityContext}
            {...fieldProps}
          />
        );
      } else if (def.type === "signatorylist") {
        const onChange = (value) => {
          const data = { ...this.state.data, [def.name]: value };
          this.setData(data, () => this.triggerFieldOnChange(def));
        };

        return (
          <SignatoryList
            className="field full-width"
            key={key}
            field={getField(def)}
            readOnly={actualReadOnly}
            value={this.state.data[def.name] || this.state.pristine[def.name]}
            onChange={onChange}
            prefilter={filter}
            securityContext={securityContext}
            {...fieldProps}
          />
        );
      } else if (def.type === "cardlist") {
        const title = def.title || arxs.t(`field.${def.name}`);

        const onChange = (rawValue) => {
          const value = isArray ? rawValue : (rawValue && rawValue[0]) || null;

          const data = { ...this.state.data, [def.name]: value };
          this.setData(data, () => this.triggerFieldOnChange(def, undefined, undefined, context));
        };

        const pristineValue = this.state.pristine[def.name];
        const modifiedValue = this.state.data[def.name];
        const value =
          modifiedValue === undefined
            ? pristineValue
            : modifiedValue || (isArray ? [] : null);
        const cardListProps = fieldProps || {};

        return (
          <CardList
            className="field full-width"
            key={key}
            title={title}
            readOnly={actualReadOnly}
            value={isArray || !value ? value : [value]}
            onChange={onChange}
            singleSelection={cardListProps.singleSelection || !isArray}
            {...cardListProps}
            required={required}
            prefilter={filter}
            securityContext={securityContext}
          />
        );
      } else if (def.type === "codeelementlist") {
        const title = def.title || arxs.t(`field.category`);

        const onChange = (value) => {
          let data = { ...this.state.data };
          if (!isArray) {
            value = value ? value[0] : null;

            data[def.name] = value ? { id: value.id } : null;

            if (def.parent) {
              data[def.parent] = value ? { id: value.parentId } : null;
            }

            if (def.grandParent) {
              data[def.grandParent] = value ? { id: value.grandParentId } : null;
            }

            this.setData(data, () => this.triggerFieldOnChange(def));
          } else {
            data[def.name] = value;
          }

          this.setData(data, () => this.triggerFieldOnChange(def));
        };

        let codeElementListProps = fieldProps || {};

        // The classic version of MYP used to support defining only sort or kind and leaving the rest blank
        // As such we want to display the first codeElement-field that is non-null in order "type -> kind -> sort"
        const value = ["name", "parent", "grandParent"]
          .map(name => def[name])
          .map(fieldName => this.stateProxy.getField(fieldName))
          .filter(x => x)[0];

        let targetModule = module;

        if (module === OriginModuleEnum.Form) {
          const subjectModule = data.targetModule || pristine.targetModule;
          if (subjectModule) {
            const subjectModuleMetadata = arxs.moduleMetadataRegistry.get(subjectModule);
            if (subjectModuleMetadata) {
              codeElementListProps.additionalCodes = subjectModuleMetadata.formCodesOverride;
            }
          }

          if ((codeElementListProps.additionalCodes || []).length === 0) {
            targetModule = subjectModule;
          }

          const formMetadata = arxs.moduleMetadataRegistry.get(OriginModuleEnum.Form);
          codeElementListProps.code = (formMetadata.formCodesOverride.filter(x => x.module === targetModule)[0] || {}).code;
        }

        return (
          <CodeElementList
            className="field"
            key={key}
            title={title}
            readOnly={actualReadOnly}
            value={isArray || !value ? value : [value]}
            onChange={onChange}
            singleSelection={!isArray}
            module={targetModule}
            allowDelete={!this.props.readOnly}
            {...codeElementListProps}
            required={required}
            securityContext={securityContext}
          />
        );
      } else if (def.type === "dateRange") {
        const value =
        {
          start: this.state.data[def.name] || this.state.pristine[def.name],
          end: this.state.data[fieldProps.end] || this.state.pristine[fieldProps.end]
        };

        const title = def.title || arxs.t(`field.daterange`);

        const onChange = (state) => {
          let data = { ...this.state.data };

          if (state.start) {
            data[def.name] = new Date(state.start);
          }

          if (state.end) {
            data[fieldProps.end] = new Date(state.end)
          }

          this.setData(data, () => this.triggerFieldOnChange(def));
        }

        return (<DateRange
          className="field"
          key={key}
          title={title}
          readOnly={actualReadOnly}
          allowDelete={!this.props.readOnly}
          {...fieldProps}
          required={required}
          securityContext={securityContext}
          value={value}
          onChange={onChange}
        />);
      }

      const fieldState = getField(def);
      fieldState.readOnly = actualReadOnly;

      return <Field key={key} field={fieldState} />;
    };

    const renderStep = (context, step, stepIndex) => {
      const stepSetsSecurityContext = !!step.getSecurityContext;
      const isVisible =
        stepSetsSecurityContext || securityContext.isUnambiguous;

      const stepHasFieldsToHide = !!step.toggleFieldVisibility;

      let fieldsToHide = [];

      if (stepHasFieldsToHide) {
        const fieldsToHideDefinition = step.toggleFieldVisibility();
        for (const fieldToHideDef of fieldsToHideDefinition) {
          for (const fieldName of Object.keys(fieldToHideDef)) {
            const fieldValue = getCurrentFieldValue(fieldName);
            const hideDef = fieldToHideDef[fieldName];
            if (fieldValue) {
              if (typeof fieldValue === "boolean") {
                fieldsToHide = fieldsToHide.concat(
                  hideDef[fieldValue.toString().toLowerCase()]
                );
              } else {
                fieldsToHide = fieldsToHide.concat(
                  hideDef[fieldValue.toLowerCase()]
                );
              }
            } else {
              const defaultValue = hideDef["default"];
              if (defaultValue) {
                fieldsToHide = fieldsToHide.concat(hideDef[defaultValue]);
              }
            }
          }
        }
      }

      const getFieldToShow = (definition) => {
        if (
          !fieldsToHide.some(
            (x) => x.toLowerCase() === definition.name.toLowerCase()
          ) &&
          (!definition.isVisible || definition.isVisible(this.stateProxy)) &&
          (definition.productType === undefined ||
            definition.productType === arxs.productType)
        ) {
          const hiddenInWizard = isHiddenInWizard(definition);
          if (this.props.isPopup && hiddenInWizard) {
            return undefined;
          }
          return definition;
        }
      };

      const fieldsToRenderForStep = (step.fields || [])
        .flatMap((row) => splitIntoFieldPerRowOnMobile(context, row))
        .map((row) =>
          row.length !== 0
            ? row.map((fieldNameOrDefinition) =>
              getFieldToShow(mapToDefinition(fieldNameOrDefinition))
            )
            : row
        );

      const readOnly = step.readOnly || false;

      const open = fieldsToRenderForStep.some(x => (x || []).some(y => y !== undefined));

      return (
        <Collapsible
          key={`step-${stepIndex}`}
          trigger={
            <div className="header">
              <div className="circle">{stepIndex + 1}</div>
              <div className="title">{step.title}</div>
            </div>
          }
          ref={step.ref}
          className="step"
          openedClassName="step open"
          open={open}
          readOnly={readOnly}
        >
          <div className="body">
            {isVisible ? (
              fieldsToRenderForStep.map(
                (row, i) =>
                  (row.some((x) => x !== undefined) || row.length === 0) && (
                    <div className="row" key={`step-${stepIndex}-row-${i}`}>
                      {row.length === 0 ? (
                        <HorizontalSeparator />
                      ) : (
                        row.map(
                          (def, j) =>
                            def && renderField(context, def, stepIndex, i, j, readOnly)
                        )
                      )}
                    </div>
                  )
              )
            ) : (
              <div>Gelieve eerst de locatie te kiezen</div>
            )}
          </div>
        </Collapsible>
      );
    };

    const getNavigatorAction = (context) =>
      this.state.metadata.wizard.navigatorAction && this.pristine && this.pristine.id
        ? {
          title: this.state.metadata.wizard.navigatorAction.title,
          onClick: () => this.handleNavigatorAction(context),
        }
        : null;

    let headerTitle = "";
    if (this.props.duplicate) {
      headerTitle = arxs.t("wizard.duplicate_header", {
        uniqueNumber: this.state.duplicateSourceData.uniqueNumber
      });
    } else {
      if (this.state.pristine && this.state.pristine.id) {
        headerTitle = arxs.t("wizard.edit_header", {
          uniqueNumber: this.state.pristine.uniqueNumber
        });
      } else {
        headerTitle = arxs.t("wizard.new_header", {
          module: arxs.t(`wizard.modules.${this.state.metadata.module.toLowerCase()}`)
        });
      }
    }

    return (
      <GlobalContext.Consumer>
        {(context) => (
          <div className="wizard">
            <WizardNavigator
              steps={this.state.steps}
              stepIndex={this.state.stepIndex}
              onNavigate={this.navigateToStep}
              action={getNavigatorAction(context)}
            />
            <div className="wizard-body">
              {!this.props.readOnly && (
                <div className="wizard-header" ref={this.state.validationRef}>
                  <h1>
                    {headerTitle}
                  </h1>
                  <h3>{arxs.t("wizard.asterisk_fields_are_required")}</h3>
                  <HorizontalSeparator />
                </div>
              )}

              {context.platform.isMobile && (
                <ValidationSummary data={this.state.validation} />
              )}

              <div className="steps">
                {this.state.steps.map((step, stepIndex) =>
                  renderStep(context, step, stepIndex)
                )}
              </div>

              {!this.props.readOnly && (
                <div className="wizard-buttons">
                  <Button
                    className="wizard-button wizard-cancel icon"
                    onClick={this.handleCancel}
                    title={arxs.t("wizard.cancel")}
                  >
                    <i className="fas fa-times"></i>
                  </Button>
                  <Button
                    className="wizard-button wizard-save icon"
                    onClick={() => this.handleSave(context, undefined, this.props.onSave)}
                    title={arxs.t("wizard.save")}
                    disable-on-click
                  >
                    <i className="fad fa-save"></i>
                  </Button>
                </div>
              )}
              <Prompt
                when={this.state.isDirty}
                message={arxs.t("common.confirm_navigation")}
              />
            </div>
            {!context.platform.isMobile && (
              <ValidationSummary data={this.state.validation} />
            )}
          </div>
        )}
      </GlobalContext.Consumer>
    );
  }
}
