import React from "react";
import i18nIsoCountries from "i18n-iso-countries";
import en from "i18n-iso-countries/langs/en.json";
import fr from "i18n-iso-countries/langs/fr.json";
import nl from "i18n-iso-countries/langs/nl.json";

import GlobalContext from "infra/GlobalContext";

import arxs from 'infra/arxs';
import { CardClassProps } from "components/card/CardContracts";
import CardActions from "components/card/CardActions";
import { createGeoLookup } from "components/controls/geolocation/GeoLookup";

// Register languages
i18nIsoCountries.registerLocale(en);
i18nIsoCountries.registerLocale(fr);
i18nIsoCountries.registerLocale(nl);

export default function GeoLocationCard(props: CardClassProps) {
    const { card, cardProps, actionProps } = props;

    const toggleGeoPopup = (context: any) => {
        const addressLookup = createGeoLookup(context, card);
        context.popup.show(addressLookup);
    };

    const getCountryIsoCode = (countryName: string | null) => {
        if (!countryName) return null;
        const isoCode = i18nIsoCountries.getAlpha2Code(countryName, arxs.language);
        return isoCode || countryName || null;
    };

    if (actionProps.condensed) {
        return <GlobalContext.Consumer>
            {(context) => (
            <div className={`user-card ${cardProps.className || ""}`}>
                <div className="user-card-icon">
                    <i className="far fa-map-marked-alt"></i>
                </div>
                <div className="user-card-names">
                    <div className="user-card-name">
                        <div>
                            {card.street} {card.number} {card.bus && `bus ${card.street}`}
                        </div>
                        <div>
                            {card.zipCode} {card.city} {getCountryIsoCode(card.country)}
                        </div>
                        {card.latitude && card.longitude && (
                            <div>
                                {card.latitude.toFixed(6)}&deg; {card.longitude.toFixed(6)}
                                &deg;
                            </div>
                        )}
                    </div>
                </div>
                <CardActions
                    {...actionProps}
                    showNavigateTo={true}
                    onNavigateTo={() => toggleGeoPopup(context)}
                    showCheckbox={false}
                />
            </div>
            )}
        </GlobalContext.Consumer>;
    }
  
    return <GlobalContext.Consumer>
        {(context) => (
            <>
                <div className="list-card-left">
                    <i className="far fa-map-marked-alt"></i>
                </div>
                <div className="list-card-right">
                    <h2>
                        <CardActions
                            {...actionProps}
                            onNavigateTo={() => toggleGeoPopup(context)}
                        />
                    </h2>
                    <div className="list-card-details">
                        <div>
                            {card.street} {card.number} {card.bus && `bus ${card.street}`}
                        </div>
                        <div>
                            {card.zipCode} {card.city} {getCountryIsoCode(card.country)}
                        </div>
                        {card.latitude && card.longitude && (
                            <div>
                                {card.latitude.toFixed(6)}&deg; {card.longitude.toFixed(6)}&deg;
                            </div>
                        )}
                    </div>
                </div>
            </>
        )}
    </GlobalContext.Consumer>;
}