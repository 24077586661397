import arxs from "infra/arxs";
import BoardController from "modules/BoardController";

import { RelationshipType } from "infra/api/contracts";

class EquipmentBoard extends BoardController {
  enrichCard(card, stateProxy) {
    return {
      ...card,

      sortId: card.sort && card.sort.id,
      kindId: card.kind && card.kind.id,
      typeId: card.type && card.type.id,
      title: this.getCodeElementValue(
        card.type,
        stateProxy.getter("codeElementsById")
      ),
      sort: this.getCodeElementValue(
        card.sort,
        stateProxy.getter("codeElementsById")
      ),
      kind: this.getCodeElementValue(
        card.kind,
        stateProxy.getter("codeElementsById")
      ),
      type: this.getCodeElementValue(
        card.type,
        stateProxy.getter("codeElementsById")
      ),
      legalStructureId: card.legalStructure && card.legalStructure.id,
      branchId: card.branch && card.branch.id,
      legalStructure: this.getLookupValue(
        stateProxy.getter("legalStructureMap"),
        card.legalStructure
      ),
      branch: this.getLookupValue(stateProxy.getter("branchMap"), card.branch),
      building: this.getLookupValue(
        stateProxy.getter("buildingMap"),
        card.building
      ),
      location: this.getLookupValue(
        stateProxy.getter("locationMap"),
        card.location
      ),
      supplier: this.getLookupValue(
        stateProxy.getter("supplierMap"),
        card.supplier
      ),
      contacts: (card.contacts || []).map((x) =>
        this.getLookupValue(stateProxy.getter("contactMap"), x)
      ),
      responsible: this.getEmployeeValue(
        card.responsible,
        stateProxy.getter("employeeMap")
      ),
      coResponsibles: this.getEmployeesAndRoleValues(
        card.relationships.filter(
          (x) => x.type === RelationshipType.CoResponsible
        ),
        stateProxy.getter("employeeMap"),
        stateProxy.getter("userRoleMap")
      ),
      owner: this.getLookupValue(stateProxy.getter("employeeMap"), card.owner),
      energySupplies: (card.energySupplies || []).map((x) =>
        this.getCodeElementValue(x, stateProxy.getter("codeElementsById"))
      ),
      energySupplyNames: ((card.energySupplies || []).map((x) =>
        this.getCodeElementValue(x, stateProxy.getter("codeElementsById"))
      )).join(", "),
      extraSpecifications: (card.extraSpecifications || []).map(x => x.description),
      recommendation: this.getLookupValueWithFieldNames(
        stateProxy.getter("recommendationMap"),
        card.recommendation, ["uniqueNumber", "title"]
      ),
      purchaseDate: card.purchaseDate && new Date(card.purchaseDate),
      expirationDate: card.expirationDate && new Date(card.expirationDate),
      constructionYear:
        card.constructionYear && new Date(card.constructionYear),
      depreciationPeriodEnd:
        card.depreciationPeriodEnd && new Date(card.depreciationPeriodEnd),
      createdAt: card.createdAt && new Date(card.createdAt),
      combinedInstallationUniqueNumber: this.getUniqueNumbersOfCombinedInstallations(stateProxy, card.inboundLinks),
      periodical_uniqueNumber: this.getUniqueNumbersOfPeriodicals(stateProxy, card.inboundLinks)
    };
  }

  getCardSearchTerms(card) {
    return [
      card.description,
      card.uniqueNumber,
      card.internalNumber,
      card.sort,
      card.kind,
      card.type,
      card.legalStructure,
      card.branch,
      card.building,
      card.location,
      (card.geoLocation || {}).street,
      (card.geoLocation || {}).city,
      card.brand,
      card.model,
      card.serialNumber,
      ...card.tags.map((x) => x.name),
      ...(card.energySupplies || []),
      ...(card.extraSpecifications || []),
      card.combinedInstallationUniqueNumber,
      card.periodical_uniqueNumber,
      (card.co2 || 0).toString().replace(".", ",")
    ];
  }

  async loadData(requestedLookups, stateProxy) {
    return arxs.Api.lookups.subscribe(requestedLookups, (lookups) => {
      if (lookups.equipments) {
        lookups.pristine = lookups.equipments
          .orderByDescending((card) => card.createdAt)
          .map((card) => {
            return {
              ...card,
              activities: card.numberOfMessages,
              selected: () => !!stateProxy.getter("selected")[card.id],
            };
          });
        delete lookups.equipments;
      }
      stateProxy.setter(lookups, () => Promise.resolve({ lookups }));
    });
  }

  onDrop = (droppedStatus, item) => {
    arxs.logger("No drag & drop supported for EquipmentAndInstallation yet");
  };
}
export default new EquipmentBoard();
