import React, { useState, useEffect } from "react";

import arxs from "infra/arxs";
import { FormDefinition, FormValues, LinkRef, LinkType, OriginModuleEnum, SubjectRef } from "infra/api/contracts";
import formDefinitionSelector from "components/controls/form/FormDefinitionSelector";

export interface CardWithForm {
    module: OriginModuleEnum;
    status: string;
    formDefinition?: FormDefinition;
    formValues?: FormValues;
    subjects?: Array<SubjectRef>;
    subject?: LinkRef;
    outboundLinks?: Array<LinkRef>;
}

export interface FormBadgePresenterProps {
    card: CardWithForm,
    overrideItems?: Array<any>,
    module: OriginModuleEnum,
    render: (props: FormBadgePresenterRenderProps) => JSX.Element | false;
}

export interface FormBadgePresenterRenderProps {
    filledIn: number;
    total: number;
}

const _executableFormControlMap = [
    "select",
    "checkbox",
    "remark",
    "riskAnalysis",
    "riskMeasurement",
    "periodicals",
    "recommendation",
    "link",
    "additionalSafetyRequirements",
].toDictionary(x => x);

export default function FormBadgePresenter(props: FormBadgePresenterProps) : JSX.Element {
    const [renderProps, setRenderProps] = useState<FormBadgePresenterRenderProps>({
        filledIn: 0,
        total: 0,
    });

    useEffect(() => {
        let formDefinition = props.card.formDefinition;

        if (formDefinition && formDefinition.link && formDefinition.link.id) {
            const formMap = arxs.Api.lookups.resolveModuleMap(OriginModuleEnum.Form);
            const [_, definition] = formDefinitionSelector(formMap, props.module, props.card.status, formDefinition);
            formDefinition = definition || { controls: [], items: [] };
        }

        if (!formDefinition || !formDefinition.controls || !formDefinition.items) {
            // Due to virtualization, sometimes Card component instances get reused with a different card.
            // If we don't reset the counts in those cases, we'll see the counts from the previous card.
            setRenderProps({
                filledIn: 0,
                total: 0,
            });
            return;
        }

        const formValues = props.card.formValues || {};
        const itemsBySubject = formValues.itemsBySubject || {};

        let subjects: SubjectRef[] = [];

        if (props.card) {
            if (props.card.subjects) {
                subjects = props.card.subjects;
            } else if (props.card.subject) {
                subjects = [props.card.subject];
            } else if (props.card.outboundLinks){
                subjects = props.card.outboundLinks.filter(x => x.type === LinkType.Subject);
            }
        }

        const items = props.overrideItems
            ? props.overrideItems
            : subjects.flatMap(subject =>
                (subject.module && itemsBySubject[subject.module]?.[subject.id || ""]) || []
            );
        const subjectCount = props.overrideItems
            ? 1
            : subjects.length;

        const executableControlIds = formDefinition.controls
            .filter(x => x.id && x.type && _executableFormControlMap[x.type])
            .map(x => x.id);

        const executableFormDefinitionItemIds = formDefinition.items
            .filter(x => executableControlIds.includes(x.control))
            .map(x => x.id);

        const filledInFormItems = items
            .filter(x => executableFormDefinitionItemIds.includes(x.id))
            .filter(x => x.value && x.value !== "[]")
            ;

        setRenderProps({
            filledIn: filledInFormItems.length,
            total: executableFormDefinitionItemIds.length * subjectCount,
        });
    }, [props.card, props.overrideItems]);
    
    return props.render(renderProps) || <></>;
}