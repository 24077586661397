import React from "react";
import Toaster from "components/util/Toaster";
import BoardController from "modules/BoardController";
import arxs from "infra/arxs";

import { OriginModuleEnum, RelationshipType } from "infra/api/contracts";

function hasGhsIconMatch(iconLookup, refs, name) {
  if (!refs || !iconLookup || !name) {
    return false;
  }

  return refs
    .map(x => iconLookup[x.iconId])
    .filter(x => x)
    .some(icon => (icon.name || "").split(".")[0].replace(/\s/g, "") === name);
}

class HazardousSubstanceBoard extends BoardController {

  enrichCard(card, stateProxy) {
    const ghsPictogramMap = stateProxy.getter("ghsPictogramMap");

    return {
      ...card,

      sortId: card.sort && card.sort.id,
      sort: this.getCodeElementValue(
        card.sort,
        stateProxy.getter("codeElementsById")
      ),
      kindId: card.kind && card.kind.id,
      kind: this.getCodeElementValue(
        card.kind,
        stateProxy.getter("codeElementsById")
      ),
      typeId: card.type && card.type.id,
      type: this.getCodeElementValue(
        card.type,
        stateProxy.getter("codeElementsById")
      ),
      title: card.name,
      legalStructureId: card.legalStructure && card.legalStructure.id,
      legalStructure: this.getLookupValue(
        stateProxy.getter("legalStructureMap"),
        card.legalStructure
      ),
      branchId: card.branch && card.branch.id,
      branch: this.getLookupValue(stateProxy.getter("branchMap"), card.branch),
      building: this.getLookupValue(
        stateProxy.getter("buildingMap"),
        card.building
      ),
      location: this.getLookupValue(
        stateProxy.getter("locationMap"),
        card.location
      ),
      emergencyNumber: this.getEmergencyNumber(stateProxy.getter("supplierMap"), card.supplier, stateProxy.getter("codeElementsById")),
      supplier: this.getLookupValue(
        stateProxy.getter("supplierMap"),
        card.supplier
      ),
      responsible: this.getEmployeeValue(
        card.responsible,
        stateProxy.getter("employeeMap")
      ),
      coResponsibles: this.getEmployeesAndRoleValues(
        card.relationships.filter(
          (x) => x.type === RelationshipType.CoResponsible
        ),
        stateProxy.getter("employeeMap"),
        stateProxy.getter("userRoleMap")
      ),
      owner: this.getLookupValue(stateProxy.getter("employeeMap"), card.owner),
      createdAt: card.createdAt && new Date(card.createdAt),
      expirationDate: card.expirationDate && new Date(card.expirationDate),
      productionDateTime:
        card.productionDateTime && new Date(card.productionDateTime),
      hazardSentenceNames: (card.hazardSentences || [])
        .map(x => this.getLookupValue(stateProxy.getter("hazardSentenceMap"), x))
        .join(", "),
      preventionSentenceNames: (card.preventionSentences || [])
        .map(x => this.getLookupValue(stateProxy.getter("preventionSentenceMap"), x))
        .join(", "),
      hazardSentencesFull: (card.hazardSentences || [])
        .map(x => ({ name: this.getLookupValue(stateProxy.getter("hazardSentenceMap"), x), description: this.getLookupValueWithFieldNames(stateProxy.getter("hazardSentenceMap"), x, ["description"]) })),
      preventionSentencesFull: (card.preventionSentences || [])
        .map(x => ({ name: this.getLookupValue(stateProxy.getter("preventionSentenceMap"), x), description: this.getLookupValueWithFieldNames(stateProxy.getter("preventionSentenceMap"), x, ["description"]) }))
      ,
      ghs_oxiderend: hasGhsIconMatch(ghsPictogramMap, card.ghsPictograms, "oxiderend"),
      ghs_houderonderdruk: hasGhsIconMatch(ghsPictogramMap, card.ghsPictograms, "houderonderdruk"),
      ghs_chronischgiftig: hasGhsIconMatch(ghsPictogramMap, card.ghsPictograms, "chronischgiftig"),
      ghs_ontvlambaar: hasGhsIconMatch(ghsPictogramMap, card.ghsPictograms, "ontvlambaar"),
      ghs_explosief: hasGhsIconMatch(ghsPictogramMap, card.ghsPictograms, "explosief"),
      ghs_schadelijk: hasGhsIconMatch(ghsPictogramMap, card.ghsPictograms, "schadelijk"),
      ghs_acuutgiftig: hasGhsIconMatch(ghsPictogramMap, card.ghsPictograms, "acuutgiftig"),
      ghs_milieugevaarlijk: hasGhsIconMatch(ghsPictogramMap, card.ghsPictograms, "milieugevaarlijk"),
      ghs_bijtend: hasGhsIconMatch(ghsPictogramMap, card.ghsPictograms, "bijtend"),
      recommendation: this.getLookupValueWithFieldNames(
        stateProxy.getter("recommendationMap"),
        card.recommendation, ["uniqueNumber", "title"]
      ),
      combinedInstallationUniqueNumber: this.getUniqueNumbersOfCombinedInstallations(stateProxy, card.inboundLinks),
      periodical_uniqueNumber: this.getUniqueNumbersOfPeriodicals(stateProxy, card.inboundLinks)
    };
  }

  getEmergencyNumber = (lookup, ref, codeElementsById) => {
    const match = lookup && ref && lookup[ref.id];
    var number = ((match || {}).phones || []).filter(x => this.getCodeElement(x.type, codeElementsById).code === "Supplier.Phone.Type.Emergency"
    ).map(x => x.number)[0];
    return number;
  }

  getCardSearchTerms(card) {
    return [
      card.description,
      card.uniqueNumber,
      card.internalNumber,
      card.name,
      card.sort,
      card.kind,
      card.type,
      card.legalStructure,
      card.branch,
      card.building,
      card.location,
      (card.geoLocation || {}).street,
      (card.geoLocation || {}).city,
      ...(card.ghsPictograms || []).map((x) => x.name),
      ...(card.tags || []).map((x) => x.name),
      card.combinedInstallationUniqueNumber,
      card.periodical_uniqueNumber
    ];
  }

  async loadData(requestedLookups, stateProxy) {
    return arxs.Api.lookups.subscribe(requestedLookups, (lookups) => {
      if (lookups.hazardousSubstances) {
        lookups.pristine = lookups.hazardousSubstances
          .orderByDescending((card) => card.createdAt)
          .map((card) => {
            return {
              ...card,
              activities: card.numberOfMessages,
              selected: () => !!stateProxy.getter("selected")[card.id],
            };
          });
        delete lookups.hazardousSubstances;
      }
      stateProxy.setter(lookups, () => Promise.resolve({ lookups }));
    });
  }

  onDrop = (droppedStatus, item) => {
    arxs.logger("No drag & drop supported for Labourmeans yet");
  };

  getFullExportActions() {
    const metadata = arxs.moduleMetadataRegistry.get(OriginModuleEnum.HazardousSubstance);

    return (metadata.board.excelExports || [])
      .filter((x) => !!x.alwaysOn === true)
      .map((report) => ({
        content: (
          <div className="board-export-selector">
            <i className="far fa-file-excel"></i>
            {arxs.t(`report.${report.name}`)}
          </div>
        ),
        onClick: (e) => {
          arxs.ReportClient.reporting().generateBinary(
            report.name,
            metadata.module
          );
          Toaster.notify(arxs.t("report.requested"));
        },
      }));
  }
}
export default new HazardousSubstanceBoard();
