import React, { Fragment } from "react";
import arxs from 'infra/arxs';
import FormItemEditorRiskController from "./FormItemEditorRiskController";
import GlobalContext from "infra/GlobalContext";
import DropDown from "components/controls/DropDown";
import RiskAnalysisValue from "components/controls/riskAnalysis/RiskAnalysisValue";
import { createRiskValueSelectionPopup } from "components/controls/riskAnalysis/RiskValueSelectionPopup";
import { TextArea } from "components/controls/TextArea";

export default class RiskMeasurement extends FormItemEditorRiskController {
    lookups = {
        codeElements: {},
    }

    constructor(props) {
        super(props);

        this.state = {
            ...this.lookups
        }
    }

    componentDidMount() {
        this.subscriptions = {
            lookups: arxs.Api.lookups
                .subscribe(this.lookups, lookups => this.setState({ ...lookups }, this.refresh))
        };
    }

    componentWillUnmount() {
        if (this.subscriptions) {
            this.subscriptions.lookups.dispose();
        }
    }

    refresh = () => {
    }

    renderRiskMeasurement = (context, settings, domains, phases, onItemChange, onDeleteItem, selectedMethod, data) => {
        const { readOnly } = this.props;

        const onRiskValueChange = (state, field) => {
            onItemChange({ ...data, [field]: state });
            context.popup.close();
        }

        const onTextChange = (state, field) => {
            onItemChange({ ...data, [field]: state });
        }

        const onDropDownChange = (state, field) => {
            onItemChange({ ...data, [field]: state ? state.id : null });
        }

        const getTextValue = (field) => {
            return (data || {})[field] || "";
        }

        const openRiskMethodEditor = (field, onChange) => {
            if (selectedMethod && selectedMethod.id) {
                const riskValueSelectionPopup = createRiskValueSelectionPopup(selectedMethod.id, (data || {})[field], onChange, context.popup.close);

                context.popup.show(riskValueSelectionPopup);
            }
        }

        const handleDelete = () => {
            onDeleteItem(data.id);
        }

        return <div className="risk-measurement" key={(data && data.id) || 0}>
            <div className="risk-measurement-line">
                <div className="risk-measurement-header">
                    <div className="risk-measurement-header-line">
                        <div className="risk-measurement-header-line-values">
                            <label>{arxs.t("forms.items.riskmeasurement.domain")}: </label>
                            <DropDown
                                id={`domain-${data.id}`}
                                className="risk-measurement-domain"
                                items={domains}
                                disableClearButton={true}
                                readOnly={readOnly}
                                onChange={(state) => onDropDownChange(state, "domain")}
                                selected={(domains || []).filter(x => x.id === (data || {}).domain)[0]}
                                placeholder={arxs.t("forms.items.riskmeasurement.domain")}
                            />
                            <label>{arxs.t("forms.items.riskmeasurement.phase")}: </label>
                            <DropDown
                                id={`phase-${data.id}`}
                                className="risk-measurement-phase"
                                items={phases}
                                disableClearButton={true}
                                readOnly={readOnly}
                                onChange={(state) => onDropDownChange(state, "phase")}
                                selected={(phases || []).filter(x => x.id === (data || {}).phase)[0]}
                                placeholder={arxs.t("forms.items.riskmeasurement.phase")}
                            />
                        </div>
                        <div className="risk-measurement-actions">
                            {!readOnly && data && <div className="risk-measurement-action" onClick={handleDelete}>
                                <i className="far fa-trash-alt"></i>
                            </div>}
                        </div>
                    </div>
                    <div className="risk-measurement-header-line">
                        <label>{arxs.t("forms.items.riskmeasurement.danger")}: </label>
                        <TextArea
                            readOnly={readOnly}
                            field={{ getter: () => getTextValue("danger"), setter: (state) => onTextChange(state, "danger"), multiLine: false }}
                            placeholder={arxs.t("forms.items.riskmeasurement.danger")}
                        />
                    </div>

                </div>
                <div className="risk-measurement-value risk">
                    <label>{arxs.t("forms.items.riskmeasurement.risk_description")}: </label>
                    <TextArea
                        readOnly={readOnly}
                        field={{ getter: () => getTextValue("riskDescription"), setter: (state) => onTextChange(state, "riskDescription"), multiLine: false }}
                        placeholder={arxs.t("forms.items.riskmeasurement.risk_description")}
                    />
                    <RiskAnalysisValue value={(data || {}).risk} readOnly={true} type={(settings || {}).method} includeRiskValue={true} />
                    {!readOnly && <div className="riskAnalysis-action" onClick={() => openRiskMethodEditor("risk", (state) => onRiskValueChange(state, "risk"))}><i className="fas fa-balance-scale-right"></i></div>}
                </div>
                <div className="risk-measurement-value prevention">
                    <label>{arxs.t("forms.items.riskmeasurement.prevention_description")}: </label>
                    <TextArea
                        readOnly={readOnly}
                        field={{ getter: () => getTextValue("preventionDescription"), setter: (state) => onTextChange(state, "preventionDescription"), multiLine: false }}
                        placeholder={arxs.t("forms.items.riskmeasurement.prevention_description")}
                    />
                    <RiskAnalysisValue value={(data || {}).prevention} readOnly={true} type={(settings || {}).method} includeRiskValue={true} />
                    {!readOnly && <div className="riskAnalysis-action" onClick={() => openRiskMethodEditor("prevention", (state) => onRiskValueChange(state, "prevention"))}><i className="fas fa-balance-scale-right"></i></div>}
                </div>
            </div>

        </div>
    }

    renderRiskMeasurements = (context) => {
        const { codeElements } = this.state;
        const { field, readOnly } = this.props;
        const phaseRoot = ((codeElements || {})["RiskAnalysis.Phase"]) || [];
        const phases = (phaseRoot[0] || {}).children || [];
        const domainRoot = ((codeElements || {})["RiskAnalysis.Domain"]) || [];
        const domains = (domainRoot[0] || {}).children || [];

        const value = field.getter();
        const data = value.data || [];
        const settings = value.settings || {};
        const selectedMethod = this.methods.filter(x => x.id === settings.method)[0];

        const onMethodChange = (state) => {
            let newData = [];
            for (let v of data) {
                delete v.risk;
                delete v.prevention;
                newData.push(v);
            }
            field.setter({ ...value, data: newData, settings: { ...settings, method: state ? state.id : null } });
        }

        const onItemChange = (state, newSettings) => {
            let newData = (data || []);
            const index = newData.findIndex(x => x.id === state.id);
            if (index === -1) {
                newData.push(state);
            } else {
                newData[index] = state;
            }

            field.setter({ ...value, data: newData, settings: newSettings || settings });
        };

        const renderLines = () => {
            return (data || []).map(line => this.renderRiskMeasurement(context, settings, domains, phases, onItemChange, onDeleteItem, selectedMethod, line));
        }

        const addLine = () => {
            onItemChange({ id: arxs.uuid.generate() });
        }

        const onDeleteItem = (id) => {
            let newData = (data || []).filter(x => x.id !== id);
            field.setter({ ...value, data: newData, settings: settings });
        }

        return <div className="risk-measurement-container">
            <div className="risk-measurement-method">
                <label>{arxs.t("forms.items.riskmeasurement.method")}: </label>
                <DropDown
                    id={`method-${data.id}`}
                    key={`method-${data.id}`}
                    items={this.methods}
                    selected={selectedMethod}
                    onChange={onMethodChange}
                    readOnly={readOnly}
                    disableClearButton={true}
                    placeholder={arxs.t("forms.items.riskmeasurement.method")}
                /></div>
            {renderLines()}
            {!readOnly && <div className="form-item-control-add-line" onClick={addLine}>
                {arxs.t("form_editor.section.add_item.title")} <i className="fas fa-plus"></i>
            </div>}
        </div>
    }

    render() {
        return (<GlobalContext.Consumer>
            {
                (context) => <Fragment>
                    {this.renderBegin()}
                    <div className="form-item-control-container">
                        {this.renderTitle(context)}
                        {this.renderRiskMeasurements(context)}
                    </div>
                    {this.renderEnd(true)}
                </Fragment>
            }
        </GlobalContext.Consumer>)
    }
}