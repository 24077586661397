import { ObjectDocumentType, OriginModuleEnum, ImportRequest } from "infra/api/contracts";
import { ProductType } from "infra/Types";
import { CardClassProps } from "components/card/CardContracts";

export enum StepVisibility {
  Hidden,
  ReadOnly
}

export interface Resource {
  getById?: (id: string) => Promise<any>;
  get?: () => Promise<any>;
  patch?: (id: string, data: any) => Promise<any>;
  post?: (id: string, data: any) => Promise<any>;
  getRefs?: () => Promise<any>;
  put?: (data: any) => Promise<any>;
}

export interface SettingResource {
  get: () => Promise<any>;
  post: (data: any) => Promise<any>;
}

export interface ImportResource {
  getImportTemplate: () => Promise<any>;
  importData: (data: ImportRequest) => Promise<any>;
}

export interface StateProxy {
  getter(propName: string): any;
  setter(stateProp: {}, callback: void): void;
  getField(fieldName: string): any;
  setField(fieldName: string, value: any): void;
}

export type GetStringFromState  = (stateProxy: StateProxy) => string;

export interface FieldDefinition {
  local?: boolean;
  name?: string;
  code?: string | GetStringFromState;
  parent?: string | GetStringFromState;
  grandParent?: string | GetStringFromState;
  title?: string; 
  props?: any;
  onChange?(
    stateProxy: StateProxy,
    fieldName: string,
    row: any,
    childName: string,
    context: any
  ): void;
  onLoad?(stateProxy: StateProxy): void;
  render?(stateProxy: StateProxy): void;
  filter?(stateProxy: StateProxy, row: any, id: any): void;
  onDelete?(stateProxy: StateProxy, items: any, index: number): void;
  isVisible?(stateProxy: StateProxy): boolean;
  preFilter?(stateProxy: StateProxy, value: any): void; //This is added for location compartment (comming from building), filter in field was not returning stateproxy etc (itemlist is, as this is what filter was made for), so we needed something new?
  productType?: ProductType;

  mapFromModel?(value: any): any;
  mapToModel?(value: any): any;

  deserialize?(data: any, pristine: any, definition: FieldDefinition): any;
  serialize?(
    definition: FieldDefinition,
    setField: (
      definition: FieldDefinition,
      fieldName: string,
      value: any
    ) => void,
    value: any
  ): any;

  //following should become obsolete
  type?: string;
  //following should probably move to the props prop
  multiLine?: boolean; //for a text input only
  children?: Array<FieldDefinition>; // for itemlist
  fullWidth?: boolean; // general
  unit?: string; //for a text input & display
  width?: string; // general
  preferredOnly?: boolean; // for itemlist
  noHeaders?: boolean; // for itemlist
  values?: Array<any>; // used for relationship & status at this moment
}

export interface FieldGroupDefinition {
  title?: string;
  initiallyOpen?: boolean;
  fields?: Array<FieldDefinition>;
}

export interface SchemaDefinition {
  name: string;
  route: string;
  getResource: (module?: string) => Resource | SettingResource;
  subscribe: (id: string, module: string, handler: (x: any) => any) => any;
  readAction: string;
  writeAction: string;
  createAction?: string;
}

export interface ModuleCode {
  module: string,
  code: string
}

export interface SettingsSchemaDefinition {
  name: string;
  route: string;
  getResource?: (module?: string) => Resource | SettingResource;
  getImportResource?: (module?: string) => ImportResource;
  readAction: string;
  writeAction: string;
  stepsTitle?: string;
  steps?: Array<SettingStep>;
  additionalCodes?: Array<ModuleCode>;
}

export interface Action {
  name: string;
  module: string;
  applicableViews?: Array<BoardViewType>;
  singleSelectionOnly?: boolean;
  icon: string;
  getTitle: () => string;
  onClick: (state: any) => void;
}

export interface BucketConfig {
  module: string;
  status: string;
  onDrop?: (state: any) => void;
  requiredActions: Array<string>;
}

export interface WizardNavigatorAction {
  title: string;
  route?: string;
  onClick?: () => void;
}

export interface Wizard {
  steps: Array<WizardStep>;
  lookups?: {};
  addNewState?: string;
  wizardClass: {};
  navigatorAction?: WizardNavigatorAction;
  fieldsWithIdsForDuplication?: Array<string>;
  stepVisibility?: StepVisibility;
}

export interface WizardStep {
  title: string;
  fields: Array<Array<FieldDefinition>>;
  requiredAction?: string;
  getSecurityContext?(writeAction: string, getValue: any): any;
  toggleFieldVisibility?(): [{}];
  toggleFieldVisibilityButton?() : [{}];
}

export interface GridColumn {
  name: string;
  title?: string;
  width?: string;
  exportOnly?: boolean;
}

export enum BoardViewType {
  KanBan = 1,
  Grid = 2,
  Map = 3,
  Tree = 4,
  Gantt = 5,
  GridDebug = 6,
}

export interface Board {
  gridColums: Array<GridColumn>;
  lookups?: {};
  cardProps?: {};
  boardClass: {};
  bucketConfig?: Array<BucketConfig>;
  additionalStatuses?: Array<string>;
  views?: Array<BoardViewType>;
  allowCreate?: boolean;
  allowDuplicate?: boolean;
  reports?: Array<Report>;
  excelExports?: Array<Report>;
  documents?: Array<Document>;
}

export interface Report {
  name: string,
  alwaysOn?: boolean,
  filter?(cards: Array<any>): boolean,
}

export interface Document {
  type: ObjectDocumentType,
  filter?(cards: Array<any>): boolean,
}

export interface SettingStep {
  title: string;
  fields: Array<Array<FieldDefinition>>;
}

export interface CodeOverride {
  module: OriginModuleEnum;
  code: string;
}

export interface StatusColorOverride {
  status: string,
  color: string
}

export default interface ModuleMetadata {
  module: string;
  title: string;
  icon: string;
  path: string;
  base: SchemaDefinition;
  settings?: SettingsSchemaDefinition;
  detailspane?: Array<FieldGroupDefinition>;
  defaultDetailsTabIndex?: number;
  allowedDocumentTypes: Array<ObjectDocumentType>;
  singleFileDocumentTypes?: Array<string>;
  formCodesOverride?: Array<CodeOverride>;
  actions: Array<Action>;
  actionClass?: any;
  statuses: Array<string>;
  statusColorOverride?: Array<StatusColorOverride>
  wizard?: Wizard;
  board?: Board;
  cardClass?: React.FC<CardClassProps>;
  canSubscribe?: boolean;
  canNavigateTo?: boolean;
}
