import React, { useState, useEffect } from "react";

import arxs from "infra/arxs";
import { AttachmentType, ObjectDocumentType, } from "infra/api/contracts";

import { CardClassProps } from "components/card/CardContracts";
import { CardSubtitle } from "components/card/CardSubtitle";
import { Image } from "components/controls/images/Image";
import CardActions from "components/card/CardActions";
import Avatar from "components/controls/images/Avatar";

function getValue(map: any, cardValue: any) {
  const id = (cardValue || {}).id;
  const ref = (map && map[id]) || {};
  return ref.name
    ? ref.name
    : Object.keys(ref).some((x) => x)
    ? ref
    : undefined;
}

function getLookupRef(map: any, cardValue: any) {
  const id = (cardValue || {}).id;
  const ref = (map && map[id]) || {};
  return ref;
}

export default function EmployeeCard(props: CardClassProps) {
  const initialLookups = {
    codeElementsById: {},
    employeeMap: {},
    legalStructureMap: {},
    branchMap: {}
  };
  
  const [lookups, setLookups] = useState(initialLookups);

  useEffect(() => {
    const subscriptions = {
      lookups: arxs.Api.lookups.subscribe(initialLookups, (values: any) => {
        setLookups((lookups) => ({ ...lookups, ...values }));
      }),
    };

    return () => {
        subscriptions.lookups.dispose();
    };
  }, []);

  const { card, cardProps, actionProps, context } = props;

  const attachmentInfo = card.attachmentInfo;

  const email =
    card.emails &&
    (card.emails.filter((x: any) => x.isPreferred)[0] || card.emails[0] || {})
      .email;
  const phone =
    card.phones &&
    (card.phones.filter((x: any) => x.isPreferred)[0] || card.phones[0] || {})
      .number;

  if (!card.images) {
    const ref = card.id && getLookupRef(lookups.employeeMap, card);
    const imageStoredFileIds = (
      ((
        (
          (((ref || {}).attachmentInfo || {}).attachments || []).filter(
            (x: any) => x.type === ObjectDocumentType.Image
          )[0] || {}
        ).value || []
      ).filter(
        (x: any) => !x.isDeleted && x.type === AttachmentType.StoredFile
      ) || [])[0] || {}
    ).id;
    card.images = (
      ((ref || {}).attachmentInfo || {}).storedFiles || []
    ).filter((x: any) => (imageStoredFileIds || []).includes(x.id));
  }

  if (actionProps.condensed) {
    const signatures = (context || {}).signatures || [];
    const signingDate = signatures.filter((s: any) => s.userId === card.id).map((s: any) => s.signingDate)[0];

    const signatureClassName = (signingDate && " signed") || "";
    const avatar =
      card.images && card.images.length > 0 && card.images[0].url;
    return (
      <div className={`user-card ${cardProps.className || ""}`}>
        <Avatar src={avatar} fullName={card.name} />
        <div className="user-card-names">
          <div className="user-card-name">{card.name}</div>
          <div className="user-card-username">{email}</div>
          <div className="user-card-phone">{phone}</div>
        </div>
        <div className="user-card-i-container">
          <CardActions {...props.actionProps} />
          {signingDate && (
            <div className="user-card-signature">
              <i className={`far fa-signature${signatureClassName}`} />
              { arxs.dateTime.formatDate(new Date(signingDate)) }
            </div>
          )}
        </div>
      </div>
    );
  }

  const imageAttachment = (
    (
      (((attachmentInfo || {}).attachments || []).filter(
        (x: any) => x.type === ObjectDocumentType.Image
      ) || [])[0] || {}
    ).value || []
  ).filter((x: any) => !x.isDeleted)[0];

  const firstImageFile =
    ((attachmentInfo || {}).storedFiles || []).filter(
      (x: any) => x.id === (imageAttachment || {}).id
    )[0] || {};

  let image = card.imagePath || (firstImageFile || {}).url;
  if (image && image.length > 0) {
    image = arxs.ImageProxy.resizeImage(image, 100, 150);
  }
  let showImage = cardProps.showImage || false;

  const assignment = card.assignments &&
    (card.assignments.filter((x: any) => x.isPreferred)[0] || card.assignments[0] || {});

  const legalStructure = assignment &&  getValue(lookups.legalStructureMap, assignment.legalStructure);
  const branch = assignment && assignment.branch && getValue(lookups.branchMap, assignment.branch);
  const _function = assignment && getValue(lookups.codeElementsById, assignment.function);

  return (
    <>
        <div className="list-card-left">
            {showImage && <Image className="list-card-image" src={image} />}
        </div>
        <div className="list-card-right">
            <h2>
              <div className="list-card-title">{card.name}</div>
              <CardActions {...props.actionProps} />
            </h2>
            <CardSubtitle cardProps={props.cardProps} card={card} />
            <div className="list-card-details">
              <div>{_function}</div>
              <div>{email}</div>
              <div>{phone}</div>
              <div>{legalStructure}{branch ? '-' + branch : ''}</div>
            </div>
        </div>
    </>
  );
}
