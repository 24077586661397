import React, { useEffect, useState } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import {
  process,
  isCompositeFilterDescriptor
} from '@progress/kendo-data-query';
import {
  Grid as KendoGrid,
  GridColumn,
  GridColumnMenuWrapper,
} from "@progress/kendo-react-grid";
import GridColumnMenu from "./GridColumnMenu";
import arxs from "infra/arxs";
import { ObjectDocumentType } from "infra/api/contracts";

import FolderButtonStrip from "components/controls/folderbuttons/FolderButtonStrip";
import { createImagePreview } from "components/controls/images/ImagePreview";
import { createGeoLookup } from "components/controls/geolocation/GeoLookup";
import { Icon as IconImage } from "components/controls/images/Icon";
import UniqueNumber from "components/card/UniqueNumber";
import { DetailsPaneTabs } from "components/shell/DetailsPane/DetailsPane";

import "./Grid.scss";

const lookups = {
  ghsPictograms: [],
};

const rootFilterOrDefault = (rootFilter) => {
  return rootFilter || { filters: [], logic: 'and' };
};

const filterGroupByField = (field, filter) => {
  const rootFilter = rootFilterOrDefault(filter);
  const compositeFilters = rootFilter.filters.filter((f) => {
    if (isCompositeFilterDescriptor(f)) {
      return (
        f.filters &&
        f.filters.length &&
        !f.filters.find(
          (nf) => isCompositeFilterDescriptor(nf) || nf.field !== field
        )
      );
    }
    return false;
  });

  return compositeFilters[0] || null;
};

const CustomHeaderColumn = (props) =>
  <span className="k-cell-inner">
    <span className="k-link">
      <span className="k-column-title">
        {props.title}
      </span>
    </span>
    {props.children}
    <GridColumnMenuWrapper
      {...props.columnMenuWrapperProps}
    ></GridColumnMenuWrapper>
  </span>;

export default function GridDebug(props) {
  const [ghsPictograms, setGhsPictograms] = useState([]);
  const [gridColumns, setGridColumns] = useState([]);

  useEffect(() => {
    const subscription = arxs.Api.lookups.subscribe(lookups, (lookups) => {
      setGhsPictograms(lookups.ghsPictograms);
    });

    return () => subscription.dispose();
  }, []);

  useEffect(() => {
    const columnList = props.gridColumns
      .map(mapToColumnDefinition)
      .map((x) => ({ name: x.name, disabled: x.disabled || false, exportOnly: x.exportOnly || false }))
      .filter(x => !x.exportOnly)
      .filter(x => (props.columnDefinitions[x.name] && Object.keys(props.columnDefinitions[x.name]).any(y => y)) || !x.disabled)
      .map((x) => x.name);

    const columns = ["checked"].concat(
      columnList.filter((field) => props.columnDefinitions[field])
    );

    setGridColumns(columns);
  }, [props.columnDefinitions])

  const handleGridStateChange = (event) => {
    const gridState = props.views.createGridState(
      props.visibleCards,
      event.dataState
    );
    props.views.setState({ gridState: gridState });
  };

  const handleGridColumnDefinitionChange = (arg) => {
    const columnDefinitions = arg.columns
      .filter((x) => x.field !== "checked")
      .toDictionary(
        (x) => x.field,
        (x) => ({ orderIndex: x.orderIndex, width: x.width })
      );
    props.views.setState({ columnDefinitions: columnDefinitions });
  };

  const handleHeaderCardToggle = (event) => {
    const checked = event.syntheticEvent.target.checked;
    const dataState = {
      ...props.gridState.dataState,
      skip: 0,
      take: 100000,
    };
    const gridState = props.views.createGridState(
      props.visibleCards,
      dataState
    );

    const allChecked = checked
      ? gridState.result.data.toDictionary(
        (card) => card.id,
        (_) => true
      )
      : {};

    props.setCheckedCards(allChecked);
  }

  const changeTab = (tab) => {
    props.views.setState({ activeTab: tab.label });
    tab.onClick();
  }

  const isColumnActive = (field, dataState) => {
    // This fails when selecting more than two checkbox filters: see https://github.com/telerik/kendo-react/issues/1167
    //return GridColumnMenuFilter.active(field, dataState.filter);
    return filterGroupByField(field, dataState.filter);
  }

  const createImagePreviewPopup = (objectId) => {
    arxs
      .moduleMetadataRegistry
      .getResourceByModule(props.module)
      .getById(objectId)
      .then((fullCard) => {
        const imageAttachments = ((
          (((fullCard.attachmentInfo || {}).attachments || []).filter(
            x => x.type === ObjectDocumentType.Image
          ) || [])[0] || {}
        ).value || []).filter(x => !x.isDeleted);
        const images = imageAttachments.map(x => ((fullCard.attachmentInfo || {}).storedFiles || []).filter(y => y.id === x.id)[0]);

        props.context.popup.show(createImagePreview(images));
      });
  }

  const createGeoPreview = (geoLocation) => {
    const geolookup = createGeoLookup(props.context, geoLocation);
    props.context.popup.show(geolookup);
  }

  const mapToColumnDefinition = (nameOrDefinition) => {
    if (typeof nameOrDefinition === "string") {
      return { name: nameOrDefinition };
    }
    return nameOrDefinition;
  }

  const getIconImage = (field) => {
    const name = field.split("_")[1];
    const icon = (ghsPictograms || []).filter(x => x.name.split(".")[0].replace(/\s/g, "") === name)[0];
    return icon ? < IconImage
      key={`icon-${name}`
      }
      src={icon.image.url}
      name={icon.name}
      width={28}
      height={28}
    /> : null
  }

  const configureGridColumn = (field, i) => {
    const componentProps = props;
    const module = props.module;
    const tdProps = {
      colSpan: 1,
      class: "k-table-td",
      role: "gridcell",
      "aria-selected": false,
      style: { left: "0px", right: "0px" },
    };
    switch (field) {
      case "checked":
        return {
          render: (
            <GridColumn
              key={`grid-col-${i}`}
              columnMenu={null}
              field={field}
              filter={"boolean"}
              width="36px"
              headerSelectionValue={
                props.gridState.result.data.any((card) => card.checked)
              }
            />
          ),
          title: "ignore",
        };

      case "status":
        return {
          getLabel: (value) => arxs.statuses.titles[value],
          cell: (props) => {
            const value = props.dataItem["status"];
            return (
              <td
                {...tdProps}
              >
                <div className={`badge ${arxs.getStatusClassName(module, value)}`}>
                  {arxs.statuses.titles[value]}
                </div>
              </td>
            );
          },
          checkboxFilter: true,
          title: arxs.t(`kanban.common.status`),
          defaultWidth: "130px",
        };

      case "priority":
        return {
          getLabel: (value) => arxs.enums.getTitle("TaskPriorityEnum", value),
          cell: (props) => {
            const value = props.dataItem["priority"];
            const priorityClassName =
              value && `priority-${value.toLowerCase()}`;
            const priorityValue =
              value && arxs.enums.getTitle("TaskPriorityEnum", value);
            return (
              <td
                {...tdProps}
              >
                {value && (
                  <div className={`badge ${priorityClassName}`}>
                    {priorityValue}
                  </div>
                )}
              </td>
            );
          },
          checkboxFilter: true,
          defaultWidth: "130px",
        };
      case "taskType":
        return {
          getLabel: (value) => arxs.enums.getTitle("TaskType", value),
          cell: (props) => {
            const value = props.dataItem["taskType"];
            const taskTypeClassName = [
              "badge",
              value && `task-type-${value.toLowerCase()}`,
            ]
              .filter(_ => _)
              .join(" ");
            const taskTypeValue = value && arxs.enums.getTitle("TaskType", value);
            return (
              <td
                {...tdProps}
              >
                {value && (
                  <div className={taskTypeClassName}>
                    {taskTypeValue}
                  </div>
                )}
              </td>
            );
          },
          checkboxFilter: true,
          defaultWidth: "130px",
        };
      case "instructionCardType":
        return {
          getLabel: (value) => arxs.enums.getTitle("instructionCardType", value),
          cell: (props) => {
            const value = props.dataItem["instructionCardType"];
            const instructionCardTypeClassName = [
              "badge",
              value && `instruction-card-type-${value.toLowerCase()}`,
            ]
              .filter(_ => _)
              .join(" ");
            const instructionCardTypeValue = value && arxs.enums.getTitle("InstructionCardType", value);
            return (
              <td
                {...tdProps}
              >
                {value && (
                  <div className={instructionCardTypeClassName}>
                    {instructionCardTypeValue}
                  </div>
                )}
              </td>
            );
          },
          checkboxFilter: true,
          defaultWidth: "170px",
        };
      case "activities":
        return {
          headerCell: () => <i className="fas fa-comment-alt"></i>,
          cell: (props) => (
            <td
              {...tdProps}

            >
              <div
                onClick={(event) =>
                  componentProps.navigateDetailsPane(event, props.dataItem, DetailsPaneTabs.activities)
                }
                className="nav-details-pane"
              >
                {props.dataItem.activities}
              </div>
            </td>
          ),
          defaultWidth: "50px",
          noFilter: true,
        };
      case "tags":
        return {
          headerCell: () => <i className="fas fa-tag"></i>,
          cell: (props) => (
            <td
              {...tdProps}

            >
              <div
                onClick={(event) =>
                  componentProps.navigateDetailsPane(event, props.dataItem, DetailsPaneTabs.info)
                }
                className="nav-details-pane"
              >
                {(props.dataItem.tags || []).length}
              </div>
            </td>
          ),
          defaultWidth: "50px",
          noFilter: true,
        };
      case "legalStructure":
      case "legalStructures":
      case "branch":
      case "branches":
        return {
          defaultWidth: "300px",
          checkboxFilter: true,
        };
      case "building":
      case "location":
        return {
          defaultWidth: "150px",
          checkboxFilter: true,
        };
      case "sort":
      case "kind":
      case "type":
        return {
          checkboxFilter: true,
          defaultWidth: "180px",
        };
      case "power":
        return {
          format: "{0:n} Watt",
          defaultWidth: "100px",
        };
      case "title":
      case "description":
        return {
          defaultWidth: "400px",
        };
      case "purchasePrice":
        return {
          defaultWidth: "100px",
          filter: "numeric",
          format: "€ {0:n2}",
        };
      case "period":
        return {
          defaultWidth: "100px",
          checkboxFilter: true,
        };
      case "depreciationPeriod":
      case "remainingDepreciationPeriod":
        return {
          defaultWidth: "100px",
          filter: "numeric",
        };
      case "purchaseDate":
      case "constructionYear":
      case "targetDate":
      case "createdAt":
      case "depreciationPeriodEnd":
      case "birthDate":
      case "dateInService":
      case "creationDate":
      case "revisionDate":
      case "mostRecentVersion":
      case "expirationDate":
      case "productionDateTime":
      case "executedAt":
      case "incidentGridDate":
      case "start":
      case "end":
      case "activationDate":
      case "plannedAt":
        return {
          defaultWidth: "125px",
          filter: "date",
          format: "{0:d}",
        };
      case "inactivityDuration":
        return {
          defaultWidth: "100px",
          filter: "numeric",
          format: `{0} ${arxs.t("unit.day")}`,
        };
      case "seniority":
        return {
          defaultWidth: "120px",
          filter: "numeric",
          format: `{0} ${arxs.t("unit.y")}`,
        };
      case "estimatedDuration":
      case "actualDuration":
        return {
          defaultWidth: "100px",
          filter: "numeric",
          format: `{0} ${arxs.t("unit.h")}`,
        };
      case "estimatedCost":
      case "actualCost":
        return {
          defaultWidth: "100px",
          filter: "numeric",
          format: `{0} ${arxs.t("unit.euro")}`,
        };
      case "startAt":
      case "endAt":
        return {
          defaultWidth: "125px",
          filter: "date",
          format: "{0:g}",
        };
      case "dimensionHeight":
      case "dimensionWidth":
      case "dimensionLength":
        return {
          defaultWidth: "125px",
          filter: "numeric",
          format: `{0} ${arxs.t("unit.meter")}`,
        };
      case "dimensionSurface":
        return {
          defaultWidth: "125px",
          filter: "numeric",
          format: `{0} ${arxs.t("unit.square_meter")}`,
        };
      case "dimensionVolume":
        return {
          defaultWidth: "125px",
          filter: "numeric",
          format: `{0} ${arxs.t("unit.cubic_meter")}`,
        };
      case "numberOfDocuments":
        return {
          headerCell: () => <i className="far fa-file"></i>,
          cell: (props) => (
            <td
              {...tdProps}

            >
              <div
                onClick={(event) =>
                  componentProps.navigateDetailsPane(event, props.dataItem, DetailsPaneTabs.documents)
                }
                className="nav-details-pane"
              >
                {props.dataItem.numberOfDocuments}
              </div>
            </td>
          ),
          defaultWidth: "50px",
          noFilter: true,
        };
      case "numberOfImages":
        return {
          headerCell: () => <i className="fas fa-images"></i>,
          cell: (props) => (
            <td
              {...tdProps}

            >
              <div
                onClick={() =>
                  props.dataItem.numberOfImages &&
                  props.dataItem.numberOfImages > 0 &&
                  createImagePreviewPopup(props.dataItem.id)
                }
                className="nav-details-pane"
              >
                {props.dataItem.numberOfImages}
              </div>
            </td>
          ),
          defaultWidth: "50px",
          noFilter: true,
        };
      case "authorizationCount":
        return {
          cell: (props) => (
            <td
              {...tdProps}

            >
              <div
                onClick={(event) =>
                  componentProps.navigateDetailsPane(event, props.dataItem, DetailsPaneTabs.info)
                }
                className="nav-details-pane"
              >
                {props.dataItem.authorizationCount}
              </div>
            </td>
          ),
          defaultWidth: "90px",
          noFilter: true,
        };
      case "locationType":
        return {
          getLabel: (value) =>
            arxs.enums.getTitle("IncidentManagementLocationType", value),
          cell: (props) => {
            const value = props.dataItem["locationType"];
            const locationTypeValue =
              value &&
              arxs.enums.getTitle("IncidentManagementLocationType", value);
            return <td
              {...tdProps}

            >{locationTypeValue}</td>;
          },
          defaultWidth: "130px",
          checkboxFilter: true,
        };
      case "victimType":
        return {
          getLabel: (value) =>
            arxs.enums.getTitle("IncidentManagementVictimType", value),
          cell: (props) => {
            const value = props.dataItem["victimType"];
            const victimType =
              value &&
              arxs.enums.getTitle("IncidentManagementVictimType", value);
            return <td
              {...tdProps}

            >{victimType}</td>;
          },
          defaultWidth: "130px",
          checkboxFilter: true,
        };
      case "victimDepartment":
        return {
          defaultWidth: "130px",
          checkboxFilter: true,
        };
      case "victimFunction":
        return {
          defaultWidth: "130px",
          checkboxFilter: true,
        };
      case "department":
      case "buildingCompartment":
        return {
          defaultWidth: "130px",
          checkboxFilter: true,
        };
      case "function":
        return {
          defaultWidth: "130px",
          checkboxFilter: true,
        };
      case "functions":
        return {
          getLabel: (value) => arxs.t("kanban.common.functions"),
          cell: (props) => {
            return (
              <td
                {...tdProps}
              >
                {props.dataItem["functions"]}
              </td>
            );
          },
          defaultWidth: "130px",
        };

      case "isDeadly":
      case "isPermanentInjury":
      case "isSerious":
      case "insuranceAck":
        return {
          getLabel: (value) => arxs.t(`kanban.common.${field}`),
          cell: (props) => {
            return (
              <td
                {...tdProps}
              >
                {props.dataItem[field] ? (
                  <i className="fas fa-check-square"></i>
                ) : (
                  <i className="far fa-square"></i>
                )}
              </td>
            );
          },
          defaultWidth: "130px",
          filter: "boolean",
        };
      case "email":
        return {
          headerCell: () => arxs.t("kanban.common.email"),
          cell: (props) => {
            return (
              <td
                {...tdProps}
              >
                {
                  props.dataItem["email"]
                }
              </td>
            );
          },
          defaultWidth: "130px",
        };
      case "phone":
        return {
          headercell: (props) => arxs.t("kanban.common.phone"),
          cell: (props) => {
            return (
              <td
                {...tdProps}
              >
                {
                  props.dataItem["phone"]
                }
              </td>
            );
          },
          defaultWidth: "130px",
        };
      case "address": {
        return {
          headercell: (props) => arxs.t("kanban.common.address"),
          cell: (props) => {
            return (
              <td
                {...tdProps}
              >
                {props.dataItem["address"]}
              </td>
            );
          },
          defaultWidth: "250px",
        }
      }
      case "isPreferred":
        return {
          defaultWidth: "50px",
        };
      case "geoLocation":
        return {
          headerCell: () => <i className="far fa-map-marked-alt"></i>,
          cell: (props) => (
            <td
              {...tdProps}

            >
              {props.dataItem.geoLocation &&
                Object.keys(props.dataItem.geoLocation).some((x) => x) && (
                  <div
                    onClick={() =>
                      createGeoPreview(props.dataItem.geoLocation)
                    }
                    className="nav-details-pane"
                  >
                    <i className="fas fa-map-marker"></i>
                  </div>
                )}
            </td>
          ),
          defaultWidth: "50px",
          noFilter: true,
        };
      case "owner":
      case "responsible":
      case "coResponsibles":
      case "assignees":
      case "assignee":
      case "participants":
        return {
          defaultWidth: "200px",
        };
      case "uniqueNumber":
        return {
          cell: (props) => {
            return <td
              {...tdProps}

            ><UniqueNumber uniqueNumber={props.dataItem[field]} /></td>;
          },
          defaultWidth: "120px",
          checkboxFilter: true,
        };
      case "subject_uniqueNumber":
      case "origin_uniqueNumber":
        return {
          headercell: (props) => <>{arxs.t("kanban.common." + field.split("_")[1])} {arxs.t("kanban.common.subject")}</>,
          cell: (props) => {
            return (
              <td
                {...tdProps}
              >
                {props.dataItem[field]
                  ? props.dataItem[field].split(", ").map((f, index, arr) => (
                    <span key={index}>
                      <UniqueNumber uniqueNumber={f.trim()} />
                      {index < arr.length - 1 && ", "}
                    </span>
                  ))
                  : null}
              </td>
            );
          },
          checkboxFilter: true,
          defaultWidth: "120px",
        };
      case "subject_sort":
      case "subject_kind":
      case "subject_type":
        return {
          headercell: (props) => <>{arxs.t("kanban.common." + field.split("_")[1])} {arxs.t("kanban.common.subject")}</>,
          cell: (props) => {
            return <td
              {...tdProps}

            >{props.dataItem[field]}</td>;
          },
          checkboxFilter: true,
          defaultWidth: "180px",
        };
      case "subject_internalReference":
        return {
          headercell: (props) => <>{arxs.t("kanban.common." + field.split("_")[1])} {arxs.t("kanban.common.subject")}</>,
          cell: (props) => {
            return <td
              {...tdProps}

            >{props.dataItem[field]}</td>;
          },
          checkboxFilter: true,
          defaultWidth: "200px",
        };
      case "signalWord":
        return {
          getLabel: (value) =>
            arxs.enums.getTitle("HazardousSubstanceSignalWord", value),
          cell: (props) => {
            const value = props.dataItem["signalWord"];
            const signalWordValue =
              value &&
              arxs.enums.getTitle("HazardousSubstanceSignalWord", value);
            return <td
              {...tdProps}
            >{signalWordValue}</td>;
          },
          defaultWidth: "130px",
          checkboxFilter: true,
        };
      case "ghs_oxiderend":
      case "ghs_houderonderdruk":
      case "ghs_chronischgiftig":
      case "ghs_ontvlambaar":
      case "ghs_explosief":
      case "ghs_schadelijk":
      case "ghs_acuutgiftig":
      case "ghs_milieugevaarlijk":
      case "ghs_bijtend":
        return {
          headerCell: () => getIconImage(field),
          cell: (props) => {
            return (
              <td
                {...tdProps}

              >
                {props.dataItem[field] ? (
                  <i className="fas fa-check-square"></i>
                ) : (
                  <i className="far fa-square"></i>
                )}
              </td>
            );
          },
          defaultWidth: "50px",
          filter: "boolean"
        };
      case "hazardSentences":
        return {
          getLabel: (value) => arxs.t("kanban.common.hazardCodes"),
          cell: (props) => {
            let value = props.dataItem["hazardSentences"];
            return (
              <td
                {...tdProps}

              >
                {value.map(v => v.name).join(", ")}
              </td>
            );
          },
          defaultWidth: "150px",
          filter: "text",
        };
      case "preventionSentences":
        return {
          getLabel: (value) => arxs.t("kanban.common.preventionCodes"),
          cell: (props) => {
            let value = props.dataItem["preventionSentences"];
            return (
              <td
                {...tdProps}
              >
                {value.map(v => v.name).join(", ")}
              </td>
            );
          },
          defaultWidth: "150px",
          filter: "text",
        };
      case "recommendation":
        return {
          getLabel: (value) => arxs.t("kanban.common.recommendation"),
          cell: (props) => {
            let value = props.dataItem["recommendation"];
            return <td
              {...tdProps}

            >
              {value && `${value}`}
            </td>
          },
          defaultWidth: "150px",
          filter: "text",
        };
      case "riskAnalysisValue":
        return {
          getLabel: (value) => arxs.t("field.riskAnalysisValue"),
          cell: (props) => {
            let value = props.dataItem["riskAnalysisValue"];
            value = value === "" ? "{}" : value;
            value = JSON.parse(value).value;
            return <td
              {...tdProps}

            >
              {value && `${value}`}
            </td>
          },
          defaultWidth: "150px",
          filter: "text",
        };
      case "link":
        return {
          headerCell: () => <i className="fas fa-link"></i>,
          cell: (props) => (
            <td
              {...tdProps}

            >
              {props.dataItem.uniqueNumber && (
                <div
                  onClick={() =>
                    window.open(`${window.location.origin.toLowerCase()}/search/${props.dataItem.uniqueNumber}`)
                  }
                  className="nav-details-pane"
                >
                  <i className="fas fa-link"></i>
                </div>
              )}
            </td>
          ),
          defaultWidth: "50px",
          noFilter: true,
        };
      default:
        return {
          defaultWidth: "125px",
        };
    }
  }

  const mapToGridColumn = (field, i) => {
    let conf = configureGridColumn(field, i);

    const definition = props.columnDefinitions[field] || {};

    const { width, orderIndex } = definition;
    const headerClassName = isColumnActive(field, props.gridState.dataState)
      ? "active"
      : "";

    const cards = props.visibleCards;
    const customOperators = props.views.KENDO_GRID_CUSTOM_OPERATORS;

    const columnMenu = conf.noFilter
      ? null
      : (props) => (
        <GridColumnMenu
          {...props}
          headerCell={props.headerCell ? (x) => <CustomHeaderColumn {...x} title={props.headerCell(x)} /> : undefined}
          items={cards}
          checkboxFilter={conf.checkboxFilter}
          getLabel={conf.getLabel}
          customOperators={customOperators}
        />
      );
    let title = definition.title || conf.title;
    if (!title) {
      const columnKey = `kanban.common.${field}`;
      const columnTitle = arxs.t(columnKey);
      title = columnTitle === columnKey ? arxs.t(`field.${field}`) : columnTitle;
    }

    conf = (window.arxs && window.arxs.gridDebug && window.arxs.gridDebug.interceptConf)
      ? window.arxs.gridDebug.interceptConf(field, conf)
      : conf;

    return conf.render ? (
      conf.render
    ) : (
      <GridColumn
        key={`grid-col-${i}`}
        field={field}
        title={title}
        columnMenu={columnMenu}
        headerClassName={headerClassName}
        width={width || conf.defaultWidth}
        orderIndex={orderIndex}
        {...conf}
      />
    );
  }

  const pageChange = (event) => {
    const { skip, take } = event.page;

    const prevDataState = (props.gridState || {}).dataState;

    if (prevDataState.skip !== skip || prevDataState.take !== take) {
      const dataState = { ...props.gridState.dataState, skip, take };
      const result = props.gridState.result;
      const gridState = props.views.createGridState(
        result.data,
        dataState,
        true
      );
      props.views.setState({ gridState: { ...gridState, dataState } }, true);
    }
  }

  const { gridState, tabs, activeTab } = props;

  const data = process(props.visibleCards, gridState.dataState);
  const skip = gridState.dataState.skip;

  const gridProps = (width, height) => {
    let obj = {
      style: { width, height },
      sortable: true,
      reorderable: true,
      resizable: true,
      filterOperators: {
        text: [
          { text: "grid.filterContainsOperator", operator: "contains" },
          { text: "grid.filterStartsWithOperator", operator: "startswith" },
          { text: "grid.filterEndsWithOperator", operator: "endswith" },
          { text: "grid.filterIsEmptyOperator", operator: "isempty" },
          { text: "grid.filterIsNotEmptyOperator", operator: "isnotempty" },
        ],
        numeric: [
          { text: "grid.filterLtOperator", operator: "lt" },
          { text: "grid.filterEqOperator", operator: "eq" },
          { text: "grid.filterGtOperator", operator: "gt" },
        ],
        date: [
          { text: "grid.filterBeforeOperator", operator: "lt" },
          { text: "grid.filterEqOperator", operator: props.views.KENDO_GRID_CUSTOM_OPERATORS.deq },
          { text: "grid.filterAfterOperator", operator: "gt" },
        ],
        boolean: [{ text: "grid.filterEqOperator", operator: "eq" }],
      },
      selectedField: "checked",
      scrollable: "virtual",
      rowHeight: 48,
      pageSize: 30,

      data: data,
      total: data.length,
      skip: skip,

      ...gridState.dataState,

      onPageChange: pageChange,
      onColumnReorder: handleGridColumnDefinitionChange,
      onColumnResize: handleGridColumnDefinitionChange,
      onDataStateChange: handleGridStateChange,
      onRowClick: (event) => props.handleCardToggle(event, event.dataItem),
      onSelectionChange: (event) => props.onCheckChange(event, event.dataItem),
      onHeaderSelectionChange: handleHeaderCardToggle
    };
    if (window.arxs
      && window.arxs.gridDebug
      && window.arxs.gridDebug.intercept) {
      obj = window.arxs.gridDebug.intercept(obj);
    }
    return obj;
  };

  return (
    <div className="grid-wrapper" >
      <div className="grid-toolbar">
        <FolderButtonStrip
          items={tabs}
          active={activeTab}
          onChange={changeTab}
        />
      </div>
      <div className="grid-body">
        <AutoSizer>
          {({ width, height }) => (<KendoGrid
            {...gridProps(width, height)}
          >
            {gridColumns.map(mapToGridColumn)}
          </KendoGrid>)}
        </AutoSizer>
      </div>
    </div>
  );
}
