import arxs from "infra/arxs";
import { LinkType, OriginModuleEnum, RecommendationStatus, SignatoryType } from 'infra/api/contracts';
import Toaster from "components/util/Toaster";
import { createInputPopup } from "components/shell/InputPopup/InputPopup";
import { createSignaturePopup } from "components/controls/signature/SignaturePopup";
import GlobalActions from "modules/GlobalActions";

class RecommendationActions {
  getActions = (module) => {
    return GlobalActions.getActions(module).concat([
      {
        name: "set_inprocess",
        module: module,
        icon: arxs.actions.icons["set_inprocess"],
        getTitle: () => arxs.actions.titles["set_inprocess"],
        onClick: (state) => this.setInProcess(state),
      },
      {
        name: "request",
        module: module,
        icon: arxs.actions.icons["request"],
        getTitle: () => arxs.actions.titles["request"],
        onClick: (state) => this.request(state),
      },
      {
        name: "to_treatment",
        module: module,
        icon: arxs.actions.icons["to_treatment"],
        getTitle: () => arxs.actions.titles["to_treatment"],
        onClick: (state) => this.setInTreatment(state),
      },
      {
        name: "refuse",
        module: module,
        icon: arxs.actions.icons["refuse"],
        getTitle: () => arxs.actions.titles["refuse"],
        onClick: (state) => this.refuse(state),
      },
      {
        name: "accept",
        module: module,
        icon: arxs.actions.icons["accept"],
        getTitle: () => arxs.actions.titles["accept"],
        onClick: (state) => this.accept(state),
      },
      {
        name: "activate",
        module: module,
        icon: arxs.actions.icons["accept"],
        getTitle: () => arxs.actions.titles["activate"],
        onClick: (state) => this.activate(state),
      },
      {
        name: "finalize",
        module: module,
        icon: arxs.actions.icons["finalize"],
        getTitle: () => arxs.actions.titles["finalize"],
        onClick: (state) => this.finalize(state),
      },
      {
        name: "sign",
        module: module,
        icon: arxs.actions.icons["sign"],
        getTitle: () => arxs.actions.titles["sign"],
        onClick: (state) => this.sign(state),
      },
    ]);
  };

  setInProcess = (state) => {
    const { context } = state;
    const ids = state.ids || [state.item.objectId];

    const executeAction = (motivation) => {
      const data = { motivation: motivation || "", ids: ids };
      arxs.ApiClient.safety.recommendation
        .setInProcess(data)
        .then(() =>
          Toaster.success(arxs.t("actions.recommendation.set_inprocess_confirmation"))
        );
    };

    const confirmation = createInputPopup(
      context,
      arxs.t("actions.recommendation.set_inprocess_confirmation_question"),
      (motivation) => executeAction(motivation),
      true,
      true,
      arxs.t("common.motivation"),
      arxs.t("common.save"),
      arxs.t("common.cancel")
    );

    context.inputPopup.show(confirmation);
  };

    areFormRequirementMet = (record) => {
        const subjectIds = record.outboundLinks.filter(x => !x.isDeleted && x.type === LinkType.Subject).map(x => x.id);
        const formCompletedResult = arxs.getFormCompletionResult(
            record.formDefinition,
            record.formValues,
            subjectIds
        );

        if (formCompletedResult.isCompleted) {
            return true;
        }

        Toaster.error(arxs.t("actions.form.error.not_all_required_fields_filled_in"));
        return false;
    };

    finalize = (state) => {
        const { cards } = state;

        return new Promise((resolve, reject) => {
            if (cards) {
                const areChecked = cards.map(card => this.areFormRequirementMet(card));

                if (areChecked.all(x => x === true)) {
                    arxs.ApiClient.safety.recommendation
                        .finalize(cards.map(x => x.id))
                        .then(() => {
                            Toaster.success(arxs.t("actions.recommendation.finalize_confirmation"));
                            resolve();
                        });
                }
            }
        });
    }

  
  sign = (state) => {
    return new Promise((resolve, reject) => {
      const { cards, context } = state;
      if (cards) {
        const attachmentToStoredFileIdMap = cards
          .flatMap(x => x.attachmentInfo.storedFiles)
          .toDictionary(x => x.id, x => x.storedFileId);

        const cardToAttachmentMap = cards
          .toDictionary(x => x.id, x => x.attachmentInfo.attachments
            .filter(a => a.type === "MainDocument")
            .flatMap(a => a.value || [])
            .filter(v => !v.isDeleted)
            .map(v => v.id)[0]
          );

        const popup = createSignaturePopup({
          module: OriginModuleEnum.Consultancy,
          documents: cards
            .map(x => ({
              id: x.id,
              module: x.module,
              name: x.name,
              uniqueNumber: x.uniqueNumber,
              storedFileId: attachmentToStoredFileIdMap[cardToAttachmentMap[x.id]]
            })),
          onConfirm: () => {
            context.popup.close();
          }
        });
        context.popup.show(popup);
      }
    });
  }

  request = (state) => {
    const ids = state.ids || [state.item.objectId];
    return arxs.ApiClient.safety.recommendation
      .request(ids)
      .then(() => Toaster.success(arxs.t("actions.recommendation.request_confirmation")));
  };

  setInTreatment = (state) => {
    const { context } = state;
    const ids = state.ids || [state.item.objectId];

    const executeAction = (motivation) => {
      const data = { motivation: motivation || "", ids: ids };
      arxs.ApiClient.safety.recommendation
        .setInTreatment(data)
        .then(() =>
          Toaster.success(arxs.t("actions.recommendation.in_treatment_confirmation"))
        );
    };

    const card = (state.cards && state.cards[0]) || state.item;
    if ([RecommendationStatus.ToSign, RecommendationStatus.Active].includes(card.status)) {
      const confirmation = createInputPopup(
        context,
        arxs.t("actions.recommendation.in_treatment_confirmation_question"),
        (motivation) => executeAction(motivation),
        true,
        false,
        arxs.t("common.motivation"),
        arxs.t("common.save"),
        arxs.t("common.cancel")
      );

      context.inputPopup.show(confirmation);
    } else {
      const data = { motivation: "", ids: ids };
      arxs.ApiClient.safety.recommendation
        .setInTreatment(data)
        .then(() =>
          Toaster.success(arxs.t("actions.recommendation.in_treatment_confirmation"))
        );
    }
  };

  refuse = (state) => {
    const context = state.context;
    const ids = state.ids || [state.item.objectId];

    const executeAction = (motivation) => {
      const data = { motivation: motivation || "", ids: ids };
      arxs.ApiClient.safety.recommendation
        .refuse(data)
        .then(() =>
          Toaster.success(arxs.t("actions.recommendation.refuse_confirmation"))
        );
    };

    const confirmation = createInputPopup(
      context,
      arxs.t("actions.recommendation.refuse_confirmation_question"),
      (motivation) => executeAction(motivation),
      true,
      true,
      arxs.t("common.motivation"),
      arxs.t("actions.refuse"),
      arxs.t("common.cancel")
    );

    context.inputPopup.show(confirmation);
  };

  accept = (state) => {
    const ids = state.ids || [state.item.objectId];
    return arxs.ApiClient.safety.recommendation
      .accept(ids)
      .then(() => Toaster.success(arxs.t("actions.recommendation.in_treatment_confirmation")));
  };

  activate = (state) => {
    const ids = state.ids || [state.item.objectId];
    return arxs.ApiClient.safety.recommendation
      .activate(ids)
      .then(() => Toaster.success(arxs.t("actions.recommendation.activate_confirmation")));
  };


  dropActions = () => {
    return {
      onDropInProcess: (state) => {
        switch (state.item.status) {
          case RecommendationStatus.Requested:
          case RecommendationStatus.Refused:
            this.setInProcess(state);
            break;
          default: return;
        }
      },
      onDropInRequested: (state) => {
        switch (state.item.status) {
          case RecommendationStatus.InProcess:
            this.request(state);
            break;
          default: return;
        }
      },
      onDropInTreatment: (state) => {
        switch (state.item.status) {
          case RecommendationStatus.InProcess:
          case RecommendationStatus.ToSign:
          case RecommendationStatus.Active:
            this.setInTreatment(state);
            break;
          case RecommendationStatus.Requested:
            this.accept(state);
            break;
          default: return;
        }
      },
      onDropInRefused: (state) => {
        switch (state.item.status) {
          case RecommendationStatus.Requested:
            this.refuse(state);
            break;
          default: return;
        }
      },
    };
  };
}
export default new RecommendationActions();
