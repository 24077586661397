import { ArXsState } from "infra/arxs";
import ModuleMetadata, { BoardViewType } from "modules/ModuleMetadata";
import AssetActions from "modules/assetmanagement/AssetActions";
import HazardousSubstanceBoard from "./HazardousSubstanceBoard";
import HazardousSubstanceWizard from "./HazardousSubstanceWizard";
import {
  HazardousSubstanceSignalWord,
  HazardousSubstanceWGK,
  ObjectDocumentType,
  OriginModuleEnum,
  RelationshipType,
  StatusEnum,
} from "infra/api/contracts";
import { ProductType } from "infra/Types";
import AssetCard from "../AssetCard";

const getHazardousSubstanceMetadata = (arxs: ArXsState): ModuleMetadata => {
  const module = OriginModuleEnum.HazardousSubstance;

  return {
    module,
    title: arxs.modules.titles[module],
    icon: arxs.modules.icons[module],
    path: "/hazardoussubstance",
    base: {
      name: "HazardousSubstance",
      route: "/hazardoussubstance",
      getResource: () => arxs.Api.getResource("hazardoussubstance"),
      subscribe: (id, module, handler) =>
        arxs.Api.lookups.subscribeToId(module, id, handler),
      writeAction: "HazardousSubstance.Write",
      readAction: "HazardousSubstance.Read",
    },
    settings: {
      name: "HazardousSubstanceSettings",
      route: "/hazardoussubstance/settings",
      getImportResource: () => arxs.ApiClient.assets.hazardousSubstanceSettings,
      readAction: "HazardousSubstance.Settings.Write",
      writeAction: "HazardousSubstance.Settings.Write",
    },
    detailspane: [
      {
        title: arxs.t("details_pane.information"),
        initiallyOpen: true,
        fields: [
          { name: "description" },
          { name: "internalNumber" },
          { name: "sort" },
          { name: "kind", parent: "sort" },
          { name: "type", parent: "kind" },
        ],
      },
      {
        title: arxs.t("details_pane.location"),
        initiallyOpen: true,
        fields: [
          { name: "legalStructure" },
          { name: "branch", parent: "legalStructure" },
          { name: "geoLocation" },
        ],
      },
      {
        title: arxs.t("details_pane.tags"),
        initiallyOpen: true,
        fields: [{ name: "tags" }],
      },
      {
        title: arxs.t("details_pane.details"),
        fields: [],
      },
      {
        title: arxs.t("details_pane.supplier_customer"),
        fields: [{ name: "supplier" }, { name: "contacts" }],
      },
    ],
    allowedDocumentTypes: [
      ObjectDocumentType.Image,
      ObjectDocumentType.Sds,
      ObjectDocumentType.ChemistryCard,
      ObjectDocumentType.AdditionalDocument,
    ],
    statuses: [
      StatusEnum.Active,
      StatusEnum.ToCommission1,
      StatusEnum.ToCommission2,
      StatusEnum.InService,
      StatusEnum.Expired,
      StatusEnum.OutOfService,
    ],
    statusColorOverride: [
      { status: StatusEnum.Active, color: "status-completed" },
    ],
    actions: AssetActions.getActions(module),
    wizard: {
      wizardClass: HazardousSubstanceWizard,
      steps: [
        {
          title: arxs.t("details_pane.location"),
          fields: [
            [
              {
                name: "context",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.School],
                  overridePrefilter: true,
                  required: true,
                },
                onChange: (stateProxy) => {
                  HazardousSubstanceWizard.setScopeFields(
                    stateProxy,
                    "context"
                  ).then(() =>
                    HazardousSubstanceWizard.applyProcessFlow(
                      stateProxy,
                      "context"
                    )
                  );
                },
                onLoad: (stateProxy) => {
                  HazardousSubstanceWizard.getContext(stateProxy);
                },
              },
              { name: "geoLocation" },
            ],
          ],
          getSecurityContext: (writeAction, getValue) => {
            const args = ["legalStructure", "branch"].map(getValue);
            return arxs.securityContext.buildForContext(
              writeAction,
              ...(args as any)
            );
          },
        },
        {
          title: arxs.t("details_pane.identification"),
          fields: [
            [{ name: "name" }, { name: "internalNumber" }],
            [
              {
                name: "type",
                parent: "kind",
                grandParent: "sort",
                props: { code: "HazardousSubstance.Sort" },
                type: "codeelementlist",
                onChange: (stateProxy) =>
                  HazardousSubstanceWizard.applyProcessFlow(
                    stateProxy,
                    "context"
                  ),
              },
            ],
            [{ name: "description" }],
            [{ name: "tags", type: "tag" }],
          ],
        },
        {
          title: arxs.t("details_pane.details"),
          fields: [
            [{ name: "casNumber" }, { name: "unNumber" }],
            [{ name: "ufiCode" }, { name: "formula" }],
            [
              {
                name: "molecularWeight",
                unit: arxs.t("unit.molecular_weight"),
              },
              {
                name: "wgk",
                values: [
                  HazardousSubstanceWGK.Wgk0,
                  HazardousSubstanceWGK.Wgk1,
                  HazardousSubstanceWGK.Wgk2,
                  HazardousSubstanceWGK.Wgk3,
                ],
              },
            ],
            [{ name: "productionDateTime" }],
            [
              {
                name: "recommendation",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.Consultancy],
                  singleSelection: true,
                },
                title: arxs.t("field.recommendation"),
                onChange: (stateProxy) => {
                  HazardousSubstanceWizard.copyRecommendationData(stateProxy);
                },
              },
            ],
            [
              {
                name: "supplier",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.Supplier],
                  singleSelection: true,
                  condensed: true,
                  // Prefilter would filter these since they are not contextual
                  overridePrefilter: true,
                },
                title: arxs.t("field.supplier"),
              },
              {
                name: "contacts",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.Contact],
                  setFilterOnField: "supplier",
                  singleSelection: true,
                  condensed: true,
                  // Prefilter would filter these since they are not contextual
                  overridePrefilter: true,
                },
                title: arxs.t("field.contact"),
              },
            ],
            [
              {
                name: "compositions",
                type: "itemlist",
                children: [
                  { name: "chemicalName" },
                  { name: "synonym" },
                  { name: "casNumber" },
                  { name: "percentage" },
                ],
              },
            ],
            [
              {
                name: "usages",
                type: "itemlist",
                children: [
                  { name: "user" },
                  {
                    name: "usageFrequency",
                    code: "HazardousSubstance.UsageFrequency",
                  },
                  {
                    name: "usageExposure",
                    code: "HazardousSubstance.UsageExposure",
                  },
                ],
                title: arxs.t("field.usages_frequence_exposure"),
              },
            ],
            [
              {
                name: "educationAdvices",
                type: "itemlist",
                children: [
                  {
                    name: "educationAdvice",
                    code: "HazardousSubstance.EducationAdvice",
                  },
                ],
                noHeaders: true,
                productType: ProductType.Helios,
              },
            ],
            [
              {
                name: "extraAdvices",
                type: "itemlist",
                children: [
                  {
                    name: "description",
                    multiLine: false,
                  },
                ],
                noHeaders: true,
              },
            ],
          ],
        },
        {
          title: arxs.t("details_pane.layout_dangers"),
          fields: [
            [
              {
                name: "ghsPictograms",
                type: "icon",
                props: { iconType: arxs.iconTypes.ghs },
              },
            ],
            [
              {
                name: "signalWord",
                values: [
                  HazardousSubstanceSignalWord.NotApplicable,
                  HazardousSubstanceSignalWord.Warning,
                  HazardousSubstanceSignalWord.Danger,
                ],
              },
            ],
            [
              {
                name: "hazardSentences",
                type: "itemlist",
                children: [
                  {
                    name: "name",
                    title: arxs.t("field.hCode"),
                    props: { followSchema: true },
                  },
                  { name: "description", props: { readOnly: true } },
                  { name: "dangerClass", props: { readOnly: true } },
                  { name: "dangerCategory", props: { readOnly: true } },
                ],
                onChange: (stateProxy) =>
                  HazardousSubstanceWizard.setSentenceInfo(
                    stateProxy,
                    "hazardSentences"
                  ),
                onLoad: (stateProxy) =>
                  HazardousSubstanceWizard.setSentenceInfo(
                    stateProxy,
                    "hazardSentences"
                    ),
              },
            ],
            [
              {
                name: "preventionSentences",
                type: "itemlist",
                children: [
                  {
                    name: "name",
                    title: arxs.t("field.pCode"),
                    props: { followSchema: true },
                  },
                  { name: "description", props: { readOnly: true } },
                ],
                onChange: (stateProxy) =>
                  HazardousSubstanceWizard.setSentenceInfo(
                    stateProxy,
                    "preventionSentences"
                  ),
                onLoad: (stateProxy) =>
                  HazardousSubstanceWizard.setSentenceInfo(
                    stateProxy,
                    "preventionSentences"
                  ),
              },
            ],
          ],
        },
        {
          title: arxs.t("details_pane.protectionequipments_ehbo"),
          fields: [
            [
              {
                name: "mandatoryIcons",
                type: "icon",
                props: { iconType: arxs.iconTypes.icon },
              },
            ],
            [
              {
                name: "involvedProtectionEquipment",
                type: "cardlist",
                props: { modules: [OriginModuleEnum.Pbm] },
                title: arxs.t("field.relatedPBMs"),
              },
            ],
            [
              {
                name: "firstAidMeasures",
                type: "itemlist",
                children: [
                  {
                    name: "firstAidMeasure",
                    code: "HazardousSubstance.FirstAidMeasure",
                  },
                ],
                noHeaders: true,
              },
            ],
          ],
        },
        {
          title: arxs.t("details_pane.storage"),
          fields: [
            [
              {
                name: "storageDetails",
                type: "itemlist",
                children: [
                  {
                    name: "location",
                    type: "cardlist",
                    props: {
                      modules: [
                        OriginModuleEnum.Building,
                        OriginModuleEnum.Room,
                        OriginModuleEnum.Pbm,
                        OriginModuleEnum.Labourmeans,
                        OriginModuleEnum.EquipmentInstallation,
                      ],
                      singleSelection: true,
                    },
                    width: "310px",
                  },
                  { name: "type", code: "HazardousSubstance.Storage" },
                  { name: "storageLocation" },
                  { name: "amount" },
                  {
                    name: "packaging",
                    code: "HazardousSubstance.Packaging",
                  },
                  { name: "contents" },
                  { name: "unit", code: "HazardousSubstance.Units" },
                ],
              },
            ],
          ],
        },
        {
          title: arxs.t("details_pane.expiration"),
          fields: [
            [
              { name: "expirationDate" },
              { name: "expirationNotificationDate" },
            ],
            [
              {
                name: "responsible",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.Employee],
                  condensed: true,
                },
              },
              {
                name: "relationships",
                type: "relationshiplist",
                props: {
                  types: [RelationshipType.CoResponsible],
                },
              },
            ],
          ],
        },
        {
          title: arxs.t("details_pane.attachments"),
          fields: [
            [
              { name: "images", type: "image" },
              { name: "documents", type: "document" },
            ],
          ],
        },
      ],
      lookups: {
        hazardSentenceMap: {},
        preventionSentenceMap: {},
        legalStructureMap: {},
        branchMap: {},
        labourmeanMap: {},
        protectionEquipmentMap: {},
        equipmentMap: {},
        iconMap: {},
        ghsPictogramMap: {},
      },
      fieldsWithIdsForDuplication: [
        "compositions",
        "educationAdvices",
        "extraAdvices",
        "usages",
        "ghsPictograms",
        // "hazardSentences",
        // "preventionSentences",
        "mandatoryIcons",
        "firstAidMeasures",
        "storageDetails",
      ],
    },
    board: {
      lookups: {
        legalStructureMap: {},
        branchMap: {},
        buildingMap: {},
        locationMap: {},
        codeElementsById: {},
        hazardousSubstances: {},
        tagMap: {},
        supplierMap: {},
        employeeMap: {},
        contactMap: {},
        ghsPictograms: [],
        combinedInstallationMap: {},
        periodicalMap: {},
        hazardSentenceMap: {},
        preventionSentenceMap: {},
        iconMap: {},
        ghsPictogramMap: {}

      },
      gridColums: [
        { name: "status" },
        { name: "activities" },
        { name: "uniqueNumber" },
        { name: "numberOfImages" },
        { name: "numberOfDocuments" },
        { name: "tags" },
        { name: "legalStructure" },
        { name: "branch" },
        { name: "name" },
        { name: "sort" },
        { name: "kind" },
        { name: "type" },
        { name: "description" },
        { name: "internalNumber" },
        { name: "signalWord" },

        // see https://en.wikipedia.org/wiki/GHS_hazard_pictograms in volgorde van GHS Nummering
        { name: "ghs_explosief" },
        { name: "ghs_ontvlambaar" },
        { name: "ghs_oxiderend" },
        { name: "ghs_houderonderdruk" },
        { name: "ghs_bijtend" },
        { name: "ghs_acuutgiftig" },
        { name: "ghs_schadelijk" },
        { name: "ghs_chronischgiftig" },
        { name: "ghs_milieugevaarlijk" },

        { name: "recommendation" },
        { name: "supplier" },
        { name: "owner" },
        { name: "productionDateTime" },
        { name: "expirationDate" },
        { name: "responsible"},
        { name: "coResponsibles"},
        { name: "createdAt" },
        { name: "casNumber" },
        { name: "unNumber" },
        { name: "formula" },
        { name: "wgk" },
        { name: "molecularWeight" },
        { name: "ufiCode" },
        { name: "hazardSentenceNames" },
        { name: "preventionSentenceNames" },
        { name: "emergencyNumber" },
        { name: "combinedInstallationUniqueNumber" },
        { name: "periodical_uniqueNumber" },
        { name: "link" },
        { name: "ghs_pict_1", exportOnly: true },
        { name: "ghs_pict_2", exportOnly: true },
        { name: "ghs_pict_3", exportOnly: true },
        { name: "ghs_pict_4", exportOnly: true },
        { name: "ghs_pict_5", exportOnly: true },
        { name: "ghs_pict_6", exportOnly: true },
        { name: "ghs_pict_7", exportOnly: true },
        { name: "ghs_pict_8", exportOnly: true },
        { name: "ghs_pict_9", exportOnly: true },
        { name: "hazardSentencesFull", exportOnly: true },
        { name: "preventionSentencesFull", exportOnly: true },
      ],
      cardProps: {
        showImage: true,
      },
      boardClass: HazardousSubstanceBoard,
      views: [
        BoardViewType.KanBan,
        BoardViewType.Grid,
        BoardViewType.Tree,
        BoardViewType.Map,
      ],
      reports: [{ name: "HazardousSubstanceShortReport" }],
      excelExports: [{ name: "HazardousSubstanceInventory", alwaysOn: true }],
    },
    cardClass: AssetCard
  };
};
export default getHazardousSubstanceMetadata;
