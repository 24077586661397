import arxs from "infra/arxs";
import { LinkType, OriginModuleEnum } from "infra/api/contracts";
import BoardController from "modules/BoardController";

class PeriodicalBoard extends BoardController {
  enrichCard(card, stateProxy) {
   

    const ref = (card.reference && JSON.parse(card.reference)) || {};
    
    const codeElementsById = stateProxy.getter("codeElementsById");
    const subjectRefs = ref.subjects ?? (ref.outboundLinks || []).filter(x => x.type === LinkType.Subject);
    const subjects = subjectRefs.map(arxs.Api.lookups.resolveSubject);
    const getLookupValueFor = (key) => (x) => this.getLookupValue(stateProxy.getter(key), x);
    let legalStructures = subjects.filter(x => x.module === OriginModuleEnum.SchoolGroup)
      .coalesceIfEmpty(subjects.map(x => x.legalStructure).filter(x => x))
      .map(getLookupValueFor("legalStructureMap"));
    let branches = subjects.filter(x => x.module === OriginModuleEnum.School)
      .coalesceIfEmpty(subjects.map(x => x.branch).filter(x => x))
      .map(getLookupValueFor("branchMap"));
    let buildings = subjects.filter(x => x.module === OriginModuleEnum.Building)
      .coalesceIfEmpty(subjects.map(x => x.building).filter(x => x))
      .map(getLookupValueFor("buildingMap"));
    let locations = subjects.filter(x => x.module === OriginModuleEnum.Room)
      .coalesceIfEmpty(subjects.map(x => x.location).filter(x => x))
      .map(getLookupValueFor("locationMap"));
    const subjectUniqueNumber = subjects
      .filter(x => x.uniqueNumber)
      .map(x => x.uniqueNumber);

    let legalStructure, branch, building, location;

    if (ref && ref.subjects) {
      const refSubjects = ref.subjects.map(arxs.Api.lookups.resolveSubject);
      const overLappingLegalStructureId = refSubjects.some(x => x.legalStructure) && this.getOverlappingLegalStructureId(refSubjects);
      const overLappingBranchId = refSubjects.some(x => x.branch) && this.getOverlappingBranchId(refSubjects);
      const overLappingBuildingId = refSubjects.some(x => x.building) && this.getOverlappingBuildingId(refSubjects);
      const overLappingLocationId = refSubjects.some(x => x.location) && this.getOverlappingLocationId(refSubjects);

      legalStructure = this.getLookupValue(stateProxy.getter("legalStructureMap"), { id: overLappingLegalStructureId });
      branch = this.getLookupValue(stateProxy.getter("branchMap"), { id: overLappingBranchId });
      building = this.getLookupValue(stateProxy.getter("buildingMap"), { id: overLappingBuildingId });
      location = this.getLookupValue(stateProxy.getter("locationMap"), { id: overLappingLocationId });
    }

    const addDistinctItem = (array, item) => {
      return item ? [...new Set([...array, item])] : array;
    }
    
    legalStructures = addDistinctItem(legalStructures, legalStructure);
    branches = addDistinctItem(branches, branch);
    buildings = addDistinctItem(buildings, building);
    locations = addDistinctItem(locations, location);

    const result = {
      ...card,
      responsible: this.getEmployeeValue(
        card.responsible,
        stateProxy.getter("employeeMap")
      ),
      title: ref.title,
      description: ref.description,
      sortId: ref.sort && ref.sort.id,
      kindId: ref.kind && ref.kind.id,
      typeId: ref.type && ref.type.id,
      kind: this.getCodeElementValue(ref.kind, codeElementsById),
      type: this.getCodeElementValue(ref.type, codeElementsById),
      subjectUniqueNumber,
      legalStructures,
      branches,
      buildings,
      locations,
      geoLocation: ref.geoLocation,
      referenceModule: arxs.modules.titles[card.referenceModule],
      createdAt: card.createdAt && new Date(card.createdAt),
      legalStructure,
      branch,
      building,
      location
    };

    return result;
  }

  getOverlappingLegalStructureId(subjects) {
    return this.getOverlappingId(subject => (subject.legalStructure || {}).id, subjects);
  }

  getOverlappingBranchId(subjects) {
    return this.getOverlappingId(subject => (subject.branch || {}).id, subjects);
  }

  getOverlappingBuildingId(subjects) {
    return this.getOverlappingId(subject => (subject.building || {}).id, subjects);
  }

  getOverlappingLocationId(subjects) {
    return this.getOverlappingId(subject => (subject.location || {}).id, subjects);
  }

  getOverlappingId(selector, subjects) {
    let id = null;

    for (const subject of subjects) {
      const targetId = selector(subject);

      if (targetId == null) {
        return null;
      }

      if (id != null && id !== targetId) {
        return null;
      }

      id = targetId;
    }

    return id;
  }

  getCardSearchTerms(card) {
    return [
      ...card.legalStructures,
      ...card.branches,
      ...card.buildings,
      ...card.locations,
      card.title,
      card.uniqueNumber,
      card.responsible,
      card.sort,
      card.kind,
      card.type,
      card.description,
      card.subjectUniqueNumber,
      card.legalStructure,
      card.branch,
      card.building,
      card.location,
      (card.geoLocation || {}).street,
      (card.geoLocation || {}).city,
      ...(card.tags || []).map((x) => x.name),
    ];
  }

  async loadData(requestedLookups, stateProxy) {
    return arxs.Api.lookups.subscribe(requestedLookups, (lookups) => {
      if (lookups.periodicals) {
        lookups.pristine = lookups.periodicals
          .orderByDescending((card) => card.createdAt)
          .map((card) => {
            return {
              ...card,
              activities: card.numberOfMessages,
              selected: () => !!stateProxy.getter("selected")[card.id],
            };
          });
        delete lookups.periodicals;
      }
      stateProxy.setter(lookups, () => Promise.resolve({ lookups }));
    });
  }

  onDrop = (droppedStatus, item) => {
    arxs.logger("No drag & drop supported for Periodicals yet");
  };
}
export default new PeriodicalBoard();
