import React, {useState, useEffect} from "react";

import arxs from "infra/arxs";

import { CardClassProps } from "components/card/CardContracts";
import CardLocation from "components/card/CardLocation";
import { CardSubtitle } from "components/card/CardSubtitle";
import CardActions from "components/card/CardActions";
import CardImage from "components/card/CardImage";

export default function PeriodicalCard(props: CardClassProps) {
  const initialLookups = {
    legalStructureMap: {},
    branchMap: {},
    locationMap: {},
    buildingMap: {}
  };

  const [lookups, setLookups] = useState(initialLookups);
  const [cardLocation, setCardLocation] = useState({});

  function getValue(map: any, cardValue: any) {
    const id = (cardValue || {}).id;
    const ref = (map && map[id]) || {};
    return ref.name
      ? ref.name
      : (Object.keys(ref).some((x) => x)
        ? ref
        : undefined);
  }

  useEffect(() => {
    const subscriptions = {
      lookups: arxs.Api.lookups.subscribe(initialLookups, (values:any) => {
        setLookups((lookups) => ({ ...lookups, ...values }));
      }),
    };

    return () => {
      subscriptions.lookups.dispose();
    };
  }, []);

  const { card, cardProps, actionProps } = props;

  const ref = JSON.parse(card.reference || "{}");

  useEffect(() => {
    if (props.card) {
      let legalStructure, branch, building, location;
      if (props.card.legalStructure !== undefined){
        setCardLocation({ legalStructure: props.card.legalStructure, branch: props.card.branch, building: props.card.building, location: props.card.location });
      } else if (ref && ref.subjects){
        const overLappingLegalStructureId = ref.subjects.some((x : any) => x.legalStructure) && getOverlappingLegalStructureId(ref.subjects);
        const overLappingBranchId = ref.subjects.some((x : any) => x.branch) && getOverlappingBranchId(ref.subjects);
        const overLappingBuildingId = ref.subjects.some((x : any) => x.building) && getOverlappingBuildingId(ref.subjects);
        const overLappingLocationId = ref.subjects.some((x : any) => x.location) && getOverlappingLocationId(ref.subjects);

        legalStructure = getValue(lookups.legalStructureMap, {id: overLappingLegalStructureId});
        branch = getValue(lookups.branchMap, {id: overLappingBranchId});
        building = getValue(lookups.buildingMap, {id: overLappingBuildingId});
        location = getValue(lookups.locationMap, {id: overLappingLocationId});
        setCardLocation({ 
          legalStructure : legalStructure,
          branch : branch,
          building : building,
          location : location,
        });
      } else if (ref && ref.legalStructure){
        legalStructure = getValue(lookups.legalStructureMap, ref.legalStructure);
        branch = getValue(lookups.branchMap, ref.branch);
        building = getValue(lookups.buildingMap, ref.buidling);
        location = getValue(lookups.locationMap, ref.location);
        setCardLocation({ 
          legalStructure : legalStructure,
          branch : branch,
          building : building,
          location : location,
        });
      }
    }
  }, [props.card, lookups]);

  const getOverlappingLegalStructureId = (subjects: Array<any>)  => {
    return getOverlappingId((subject: { legalStructure: any; })  => (subject.legalStructure || {}).id, subjects);
  }

  const getOverlappingBranchId = (subjects: Array<any>) => {
    return getOverlappingId((subject: { branch: any; }) => (subject.branch || {}).id, subjects);
  }

  const getOverlappingBuildingId = (subjects: Array<any>) => {
    return getOverlappingId((subject: { building: any; }) => (subject.building || {}).id, subjects);
  }

  const getOverlappingLocationId = (subjects: Array<any>) => {
    return getOverlappingId((subject: { location: any; }) => (subject.location || {}).id, subjects);
  }

  const getOverlappingId = (selector : any, subjects: Array<any>) => {
    let id = null;

    for (const subject of subjects) {
      const targetId = selector(subject);

      if (targetId == null) {
        return null;
      }

      if (id != null && id !== targetId) {
        return null;
      }

      id = targetId;
    }

    return id;
  }

  return (
    <>
      <div className="list-card-left">
          <CardImage {...props} />
      </div>
      <div className="list-card-right">
        <h2>
          <div className="list-card-title" title={ref.title}>
            {ref.title}
          </div>
          <CardActions {...actionProps} />
        </h2>
        <CardSubtitle cardProps={cardProps} card={card} />
        <div className="list-card-details">
          <div>
            {[
              arxs.modules.titles[card.referenceModule] || card.referenceModule,
              card.kind,
              card.type,
            ]
              .filter((x) => x)
              .join(" - ")}
          </div>
          <CardLocation card={cardLocation} />
          <div>
            {arxs.t("kanban.common.changed")}:{" "}
            {arxs.dateTime.formatDate(card.modifiedAt)}
          </div>
        </div>
      </div>
    </>
  );
}