import React from "react";
import arxs from 'infra/arxs';
import FormItemController from "./FormItemController";
import GlobalContext from "infra/GlobalContext";
import ToggleButton from "components/controls/ToggleButton";
import DropDown from "components/controls/DropDown";
import { OriginModuleEnum } from "infra/api/contracts";

import "./Recommendation.scss";

export default class Recommendation extends FormItemController {
    deserialize(serializedValue) {
        return JSON.parse(serializedValue || "[]");
    }

    serialize(deserializedValue) {
        return JSON.stringify(deserializedValue);
    }

    getConclusions = () => {
        const { codeElements } = this.state;
        let code;

        switch (this.props.targetModule || this.props.module) {
            case OriginModuleEnum.Commissioning:
                code = "Commissioning.Conclusion";
                break;
            case OriginModuleEnum.OutOfCommissioning:
                code = "OutOfCommissioning.Conclusion";
                break;
            default: break;
        }

        if (!code) {
            return [];
        }

        const conclusionKind = ((codeElements || {})[code] || [])[0] || {};
        const conclusions = (conclusionKind.children || [])
            .map(x => ({ id: x.id, name: x.name }));
        return conclusions;
    }

    renderRecommendation = () => {
        const { codeElementsById } = this.state;
        const value = this.getter() || {};
        const status = value.status;
        const conclusions = this.getConclusions();
        const selectedConclusion = value.conclusion && codeElementsById
            ? {
                id: value.conclusion,
                name: (codeElementsById[value.conclusion] || {}).name
            }
            : undefined;
        const statusValues = ["FAVORABLE", "UNFAVORABLE"];

        const getToggleTranslation = (value) => {
            const translation = arxs.t(`enums.ConclusionStatusEnum.${value}`);
            return translation;
        }

        const onFieldChange = (field, state) => {
            const value = this.getter();
            let newValue = { ...value };
            newValue[field] = state;
            this.setter(newValue);
        }

        return <div className="recommendation">
            <ToggleButton
                value={status}
                values={statusValues}
                textSelector={getToggleTranslation}
                onChange={(_, value) => onFieldChange("status", value)}
                readOnly={this.props.readOnly}
            />
            <DropDown
                id={`conclusion`}
                key={`conclusion`}
                className="conclusion"
                items={conclusions}
                selected={selectedConclusion}
                onChange={value => onFieldChange("conclusion", value ? value.id : null)}
                readOnly={this.props.readOnly}
                placeholder={arxs.t("forms.items.recommendation.conclusion")}
            />
        </div >
    }

    render() {
        return (<GlobalContext.Consumer>
            {
                (context) => <div className="form-control recommendation">
                    {this.renderHeader(this.props.title)}
                    <div className="form-control-field">
                        {this.renderRecommendation()}
                    </div>
                    {this.renderFooter(context)}
                </div>}
        </GlobalContext.Consumer>)
    }
}