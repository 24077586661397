import { ArXsState } from "infra/arxs";
import { OriginModuleEnum, RelationshipType, ObjectDocumentType, DecommissioningStatus, SignatoryType, Attachment, AttachmentValue } from "infra/api/contracts";

import ModuleMetadata, { BoardViewType } from "modules/ModuleMetadata";
import DecommissioningWizard from "./DecommissioningWizard";
import DecommissioningActions from "./DecommissioningActions";
import DecommissioningBoard from "./DecommissioningBoard";
import DecommissioningCard from "./DecommissioningCard";
import createAssetProcessFlowSettings from "../AssetProcessFlowSettings";

const getDecommissioningMetadata = (arxs: ArXsState): ModuleMetadata => {
  const module = OriginModuleEnum.OutOfCommissioning;

  return {
    module,
    title: arxs.t("modules.decommissioning"),
    icon: "fas fa-repeat",
    path: "/decommissioning",
    base: {
      name: "Decommissioning",
      route: "/decommissioning",
      getResource: () => arxs.Api.getResource("decommissioning"),
      subscribe: (id, module, handler) =>
        arxs.Api.lookups.subscribeToId(module, id, handler),
      writeAction: "OutOfCommissioning.Write",
      readAction: "OutOfCommissioning.Read",
    },
    settings: {
      name: "DecommissioningSettings",
      route: "/decommissioning/settings",
      getResource: () => arxs.ApiClient.safety.decommissioningSettings,
      readAction: "OutOfCommissioning.Settings.Write",
      writeAction: "OutOfCommissioning.Settings.Write",
      steps: createAssetProcessFlowSettings(),
    },
    detailspane: [
      {
        initiallyOpen: true,
        fields: [
          { name: "description", fullWidth: true },
          { name: "subject" },
          { name: "createdAt" },
          { name: "activationDate" },
          { name: "relationships" },
          { name: "signatories" },
        ],
      },
      {
        title: arxs.t("details_pane.tags"),
        initiallyOpen: true,
        fields: [{ name: "tags" }],
      },
    ],
    allowedDocumentTypes: [
      ObjectDocumentType.MainDocument,
      ObjectDocumentType.AdditionalDocument,
    ],
    statuses: [
      DecommissioningStatus.InProcess,
      DecommissioningStatus.ToSign,
      DecommissioningStatus.Active,
      DecommissioningStatus.Inactive,
    ],
    statusColorOverride: [
      { status: DecommissioningStatus.Active, color: "status-completed" },
    ],
    actions: DecommissioningActions.getActions(OriginModuleEnum.OutOfCommissioning),
    formCodesOverride: [
      { module: OriginModuleEnum.Labourmeans, code: "LabourMeans.Kind" },
      { module: OriginModuleEnum.Pbm, code: "ProtectionEquipment.Sort" },
      {
        module: OriginModuleEnum.EquipmentInstallation,
        code: "EquipmentInstallation.Sort",
      },
      {
        module: OriginModuleEnum.HazardousSubstance,
        code: "HazardousSubstance.Sort",
      },
      {
        module: OriginModuleEnum.IntangibleAsset,
        code: "IntangibleAsset.Kind",
      },
    ],
    actionClass: DecommissioningActions,
    wizard: {
      wizardClass: DecommissioningWizard,
      steps: [
        {
          title: arxs.t("details_pane.identification"),
          fields: [
            [
              {
                name: "title",
                onLoad: (stateProxy) =>
                  DecommissioningWizard.setTitleInitialValue(stateProxy, false),
              },
              { name: "activationDate" },
            ],
            [{ name: "description" }],
            [
              {
                name: "subject",
                type: "cardlist",
                props: {
                  modules: [
                    OriginModuleEnum.Building,
                    OriginModuleEnum.Room,
                    OriginModuleEnum.Labourmeans,
                    OriginModuleEnum.Pbm,
                    OriginModuleEnum.EquipmentInstallation,
                    OriginModuleEnum.HazardousSubstance,
                    OriginModuleEnum.CombinedInstallation,
                    OriginModuleEnum.IntangibleAsset,
                  ],
                  overridePrefilter: true,
                  disableInitialValue: true,
                  readOnly: true,
                },
                onChange: (
                  stateProxy,
                  fieldName: string,
                  row: any,
                  childName: string
                ) => {
                  DecommissioningWizard.setScopeFields(stateProxy, "subject");
                },
                title: arxs.t("field.subject"),
              },
            ],
            [{ name: "tags", type: "tag" }],
          ],
        },
        {
          title: arxs.t("details_pane.involved_people"),
          fields: [
            [
              {
                name: "responsible",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.Employee],
                  condensed: true,
                },
              },
            ],
            [
              {
                name: "relationships",
                type: "relationshiplist",
                props: {
                  types: [RelationshipType.CoResponsible, RelationshipType.Cc],
                },
              },
            ],
          ],
        },
        {
          title: arxs.t("wizard.common.form"),
          fields: [
            [
              {
                name: "formDefinition",
                props: { module: OriginModuleEnum.OutOfCommissioning },
                onChange: (stateProxy) => DecommissioningWizard.onFormChange(stateProxy),
              },
            ],
          ],
        },
        {
          title: arxs.t("wizard.common.form"),
          fields: [
            [
              {
                type: "formContainer",
                props: { 
                  completeAction: "OutOfCommissioning.Write",
                  manageAction: "OutOfCommissioning.Write"
                  },
                onChange: (stateProxy) => DecommissioningWizard.onFormChange(stateProxy),
              },
            ],
          ],
        },
        {
          title: arxs.t("kanban.common.signatories"),
          fields: [
            [{ name: "needsSignature" }],
            [
              {
                name: "signatories",
                type: "signatorylist",
                props: {
                  types: [SignatoryType.PreventionAdvisor],
                  required: true,
                  singleSelection: true,
                },
              },
              {
                name: "signatories",
                type: "signatorylist",
                props: {
                  types: [SignatoryType.HierarchicalLine],
                  singleSelection: true,
                },
              },
            ],
            [
              {
                name: "signatories",
                type: "signatorylist",
                props: {
                  types: [SignatoryType.Other],
                },
              },
            ],
          ],
          toggleFieldVisibility: () => {
            return [
              {
                needsSignature: {
                  default: "false",
                  false: [
                    "signingPreventionAdvisor",
                    "signingHierarchicalLine",
                    "signatories",
                  ],
                  true: [],
                },
              },
            ];
          },
        },
        {
          title: arxs.t("details_pane.attachments"),
          fields: [
            [
              {
                name: "images",
                type: "image",
              },
              {
                name: "documents",
                type: "document",
                onChange: (stateProxy) => DecommissioningWizard.onDocumentsChange(stateProxy),
              },
            ],
          ],
        },
      ],
      lookups: {
        decommissioningMap: {},
        legalStructureMap: {},
        branchMap: {},
      },
      addNewState: DecommissioningStatus.InProcess,
    },
    board: {
      allowCreate: false,
      allowDuplicate: false,
      lookups: {
        tagMap: {},
        decommissionings: [],
        employeeMap: {},
        legalStructureMap: {},
        branchMap: {},
        buildingMap: {},
        locationMap: {},
        codeElementsById: {},
        userRoleMap: {},
        labourmeanMap: {},
        equipmentMap: {},
        protectionEquipmentMap: {},
        intangibleAssetMap: {},
        hazardousSubstanceMap: {},
        formMap: {},
      },
      gridColums: [
        { name: "status" },
        { name: "activities" },
        { name: "uniqueNumber" },
        { name: "numberOfImages" },
        { name: "numberOfDocuments" },
        { name: "tags" },
        { name: "legalStructure" },
        { name: "branch" },
        { name: "building" },
        { name: "location" },
        { name: "title" },
        { name: "description" },
        { name: "createdAt" },
        { name: "activationDate" },
        { name: "responsible" },
        { name: "coResponsibles" },
        { name: "subject_uniqueNumber" },
        { name: "subject_sort" },
        { name: "subject_kind" },
        { name: "subject_type" },
      ],
      cardProps: {
        showImage: true,
        showSubjectImage: true,
      },
      boardClass: DecommissioningBoard,
      bucketConfig: [
        {
          module: module,
          status: DecommissioningStatus.InProcess,
          onDrop: (state) =>
            DecommissioningActions.dropActions().onDropInProcess(state),
          requiredActions: ["set_inprocess"],
        },
      ],
      views: [BoardViewType.KanBan, BoardViewType.Grid, BoardViewType.Tree],
      reports: [
        {
          name: "Decommissioning",
          filter: cards => !cards.any(x => x.status !== DecommissioningStatus.InProcess),
        }
      ],
      documents: [
        {
          type: ObjectDocumentType.MainDocument,
          filter: (cards) => cards.some(card => card.attachmentInfo?.attachments.some((x : Attachment) => x.type === ObjectDocumentType.MainDocument && (x.value || []).some(y => !y.isDeleted)))
        }
      ]
    },
    cardClass: DecommissioningCard,
  };
};
export default getDecommissioningMetadata;
