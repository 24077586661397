import AssetWizard from "modules/assetmanagement/AssetWizard";
import arxs from "infra/arxs";
import { OriginModuleEnum } from "infra/api/contracts";

class CombinedInstallationWizard extends AssetWizard {
  setScopeAndContextFromAssets = (stateProxy) => {
    return new Promise((resolve, reject) => {
      const data = stateProxy.getter("data");
      const pristine = stateProxy.getter("pristine");

      const getDataValue = (fieldName) => data[fieldName];
      const getPristineValue = (fieldName) => pristine[fieldName];
      const assets = getDataValue("assets");
      if (assets && assets.length > 0) {
        const module = assets[0].module;
        const id = assets[0].id;
        const subscription = arxs.Api.lookups.subscribeToId(module, id, () => {
          // Gets the shared context of all the assets
          const context = assets
            .map((asset) => arxs.Api.lookups.resolveSubject(asset))
            .filter((asset) => Object.keys(asset).length > 0)
            .map((asset) => [asset.legalStructure, asset.branch, asset.building, asset.location])
            .reduce((acc, val) => {
              if (acc) {
                return [
                  acc[0],
                  (acc[1]?.id === val[1]?.id) ? acc[1] : null,
                  (acc[2]?.id === val[2]?.id) ? acc[2] : null,
                  (acc[3]?.id === val[3]?.id) ? acc[3] : null,
                ];
              }
              return [
                val[0] ? { id: val[0].id, module: OriginModuleEnum.SchoolGroup } : null,
                val[1] ? { id: val[1].id, module: OriginModuleEnum.School } : null,
                val[2] ? { id: val[2].id, module: OriginModuleEnum.Building } : null,
                val[3] ? { id: val[3].id, module: OriginModuleEnum.Room } : null,
              ];
            }, null);

          if (!context) { 
            return;
          }

          const [ legalStructure, branch, building, location ] = context;
        
          stateProxy.setter(
            {
              data: {
                ...data,
                legalStructure,
                branch,
                building,
                location,
                context: location || building || branch || legalStructure,
              },
            },
            resolve
          );

          subscription.dispose();
        });
      } else {
        resolve();
      }
    });
  };
}
export default new CombinedInstallationWizard();
