import { ArXsState } from "infra/arxs";
import ModuleMetadata, {
  BoardViewType,
  StepVisibility,
} from "modules/ModuleMetadata";
import {
  OriginModuleEnum,
  RelationshipType,
  ObjectDocumentType,
  RecommendationStatus,
  SignatoryType,
  Attachment,
  AttachmentValue
} from "infra/api/contracts";
import RecommendationActions from "./RecommendationActions";
import RecommendationWizard from "./RecommendationWizard";
import RecommendationBoard from "./RecommendationBoard";
import RecommendationCard from "./RecommendationCard";

const getRecommendationMetadata = (arxs: ArXsState): ModuleMetadata => {
  const module = OriginModuleEnum.Consultancy;

  return {
    module,
    title: arxs.t("modules.recommendation"),
    icon: "far fa-comments",
    path: "/recommendation",
    base: {
      name: "Recommendation",
      route: "/recommendation",
      getResource: () => arxs.Api.getResource("recommendation"),
      subscribe: (id, module, handler) =>
        arxs.Api.lookups.subscribeToId(module, id, handler),
      writeAction: "Consultancy.Write",
      createAction: "Consultancy.Request",
      readAction: "Consultancy.Read",
    },
    settings: {
      name: "RecommendationSettings",
      route: "/recommendation/settings",
      getResource: () => arxs.ApiClient.safety.recommendationSettings,
      readAction: "Consultancy.Settings.Read",
      writeAction: "Consultancy.Settings.Write",
      steps: [
        {
          title: arxs.t("field.processFlows"),
          fields: [
            [
              {
                name: "processFlows",
                type: "itemlist",
                children: [
                  { name: "legalStructure" },
                  { name: "branch", parent: "legalStructure" },
                  { name: "employee" },
                  { name: "userRole" },
                  {
                    name: "relationshipType",
                    values: [
                      RelationshipType.Responsible,
                      RelationshipType.CoResponsible,
                      RelationshipType.Cc,
                      RelationshipType.PreventionAdvisorSignatory,
                      RelationshipType.HierarchicalLineSignatory,
                      RelationshipType.OtherSignatory,
                    ],
                  },
                ],
              },
            ],
          ],
        },
      ],
    },
    detailspane: [
      {
        initiallyOpen: true,
        fields: [
          { name: "description", fullWidth: true },
          { name: "internalReference", fullWidth: true },
          { name: "kind" },
          { name: "type", parent: "kind" },
        ],
      },
      {
        title: arxs.t("details_pane.location"),
        initiallyOpen: true,
        fields: [
          { name: "legalStructure" },
          { name: "branch", parent: "legalStructure" },
          { name: "building", parent: "branch" },
          { name: "location", parent: "building" },
        ],
      },
      {
        title: arxs.t("details_pane.details"),
        initiallyOpen: true,
        fields: [{ name: "createdAt" }, { name: "activationDate" }],
      },
      {
        title: arxs.t("details_pane.involved_people"),
        initiallyOpen: true,
        fields: [
          { name: "relationships" },
          { name: "signatories" },
        ],
      },
      {
        title: arxs.t("details_pane.tags"),
        initiallyOpen: true,
        fields: [{ name: "tags" }],
      },
    ],
    allowedDocumentTypes: [
      ObjectDocumentType.Image,
      ObjectDocumentType.MainDocument,
      ObjectDocumentType.Manual,
      ObjectDocumentType.Certificate,
      ObjectDocumentType.AdditionalDocument,
    ],
    statuses: [
      RecommendationStatus.InProcess,
      RecommendationStatus.Requested,
      RecommendationStatus.Refused,
      RecommendationStatus.InTreatment,
      RecommendationStatus.ToSign,
      RecommendationStatus.Active,
    ],
    statusColorOverride: [
      { status: RecommendationStatus.Active, color: "status-completed" },
      { status: RecommendationStatus.Requested, color: "status-active" },
    ],
    actions: RecommendationActions.getActions(OriginModuleEnum.Consultancy),
    wizard: {
      stepVisibility: StepVisibility.Hidden,
      wizardClass: RecommendationWizard,
      steps: [
        {
          title: arxs.t("details_pane.request"),
          fields: [
            [{ name: "title" }],
            [{ name: "description" }],
            [
              {
                name: "type",
                type: "codeelementlist",
                props: { code: "Recommendation.Kind" },
                parent: "kind",
                title: arxs.t("field.category"),
                onChange: (stateProxy) => 
                  RecommendationWizard.selectForm(stateProxy)
                  .then(() => RecommendationWizard.onFormChange(stateProxy))
                  .then(() => RecommendationWizard.applyProcessFlow(stateProxy, "context"))
              },
              {
                name: "internalReference",
              },
            ],
            [
              {
                name: "context",
                type: "cardlist",
                title: arxs.t("field.scope"),
                props: {
                  modules: [
                    OriginModuleEnum.SchoolGroup,
                    OriginModuleEnum.School,
                    OriginModuleEnum.Building,
                    OriginModuleEnum.Room,
                  ],
                  overridePrefilter: true,
                  required: true,
                },
                onChange: (stateProxy) => {
                  RecommendationWizard.setScopeFields(stateProxy,"context")
                  .then(() => RecommendationWizard.setSubject(stateProxy))
                  .then(() => RecommendationWizard.selectForm(stateProxy))
                  .then(() => RecommendationWizard.onFormChange(stateProxy))
                  .then(() => RecommendationWizard.applyProcessFlow(stateProxy, "context"));
                },
                onLoad: (stateProxy) => {
                  RecommendationWizard.getContext(stateProxy);
                },
              },
            ],
          ],
          getSecurityContext: (writeAction, getValue) => {
            const args = [
              "legalStructure",
              "branch",
              "building",
              "location",
            ].map(getValue);
            return arxs.securityContext.buildForContext(
              writeAction,
              ...(args as any)
            );
          },
        },
        {
          title: arxs.t("details_pane.attachments"),
          fields: [
            [
              { name: "images", type: "image" },
              {
                name: "documents",
                type: "document",
                props: {  },
                onChange: (stateProxy) => RecommendationWizard.onDocumentsChange(stateProxy),
              },
            ],
          ],
        },
        {
          title: arxs.t("details_pane.details"),
          requiredAction: "Consultancy.Write",
          fields: [
            [
              {
                name: "status",
                values: [
                  RecommendationStatus.InProcess,
                  RecommendationStatus.InTreatment,
                  RecommendationStatus.Active,
                ],
                props: { required: true, disabledOnEdit: true },
              },
              {
                name: "responsible",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.Employee],
                  condensed: true,
                  overridePrefilter: true,
                },
              },
            ],
            [
              {
                name: "relationships",
                type: "relationshiplist",
                props: {
                  types: [RelationshipType.CoResponsible, RelationshipType.Cc],
                },
              },
            ],
            [{ name: "tags", type: "tag" }],
            [{ name: "activationDate" }],
          ],
        },
        {
          title: arxs.t("wizard.common.form"),
          fields: [
            [
              {
                type: "formContainer",
                props: { 
                  completeAction: "Consultancy.Request",
                  manageAction: "Consultancy.Write"
                 },
                onChange: (stateProxy) => RecommendationWizard.onFormChange(stateProxy),
              },
            ],
          ],
        },
        {
          title: arxs.t("kanban.common.signatories"),
          requiredAction: "Consultancy.Write",
          fields: [
            [{ name: "needsSignature" }],
            [
              {
                name: "signatories",
                type: "signatorylist",
                props: {
                  types: [SignatoryType.PreventionAdvisor],
                  required: true,
                  singleSelection: true,
                  index: 1,
                },
              },
              {
                name: "signatories",
                type: "signatorylist",
                props: {
                  types: [SignatoryType.HierarchicalLine],
                  singleSelection: true,
                  index: 2,
                },
              },
            ],
            [
              {
                name: "signatories",
                type: "signatorylist",
                props: {
                  types: [SignatoryType.Other],
                  index: 3,
                },
              },
            ],
          ],
          toggleFieldVisibility: () => {
            return [
              {
                needsSignature: {
                  default: "false",
                  false: [
                    "signingPreventionAdvisor",
                    "signingHierarchicalLine",
                    "signatories",
                  ],
                  true: [],
                },
              },
            ];
          },
        },
      ],
      fieldsWithIdsForDuplication: [],
      lookups: {
        recommendationMap: {},
        legalStructureMap: {},
        branchMap: {},
        buildingMap: {},
        locationMap: {},
        forms: []
      },
      addNewState: RecommendationStatus.InProcess,
    },
    board: {
      lookups: {
        recommendations: [],
        legalStructureMap: {},
        branchMap: {},
        buildingMap: {},
        locationMap: {},
        codeElementsById: {},
        tagMap: {},
        employeeMap: {},
        userRoleMap: {},
      },
      gridColums: [
        { name: "status" },
        { name: "activities" },
        { name: "uniqueNumber" },
        { name: "numberOfDocuments" },
        { name: "tags" },
        { name: "legalStructure" },
        { name: "branch" },
        { name: "building" },
        { name: "location" },
        { name: "kind" },
        { name: "type" },
        { name: "title" },
        { name: "description" },
        { name: "internalReference" },
        { name: "createdAt" },
        { name: "activationDate" },
        { name: "owner" },
        { name: "responsible" },
        { name: "coResponsibles" },
      ],
      cardProps: {
        showImage: false,
      },
      boardClass: RecommendationBoard,
      bucketConfig: [
        {
          module: module,
          status: RecommendationStatus.InProcess,
          onDrop: (state) =>
            RecommendationActions.dropActions().onDropInProcess(state),
          requiredActions: ["set_inprocess"],
        },
        {
          module: module,
          status: RecommendationStatus.Requested,
          onDrop: (state) =>
            RecommendationActions.dropActions().onDropInRequested(state),
          requiredActions: ["request"],
        },
        {
          module: module,
          status: RecommendationStatus.InTreatment,
          onDrop: (state) =>
            RecommendationActions.dropActions().onDropInTreatment(state),
          requiredActions: ["to_treatment", "accept"],
        },
        {
          module: module,
          status: RecommendationStatus.Refused,
          onDrop: (state) =>
            RecommendationActions.dropActions().onDropInRefused(state),
          requiredActions: ["refuse"],
        },
      ],
      views: [BoardViewType.KanBan, BoardViewType.Grid, BoardViewType.Tree],
      reports: [
        {
          name: "Recommendation",
          filter: cards => !cards.any(x => x.status !== RecommendationStatus.InTreatment),
        }
      ],
      documents: [
        {
          type: ObjectDocumentType.MainDocument,
          filter: (cards) => cards.some(card => card.attachmentInfo?.attachments.some((x : Attachment) => x.type === ObjectDocumentType.MainDocument && (x.value || []).some(y => !y.isDeleted)))
        }
      ]
    },
    cardClass: RecommendationCard,
  };
};
export default getRecommendationMetadata;
