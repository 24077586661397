import React, { Fragment, useState, useEffect } from 'react';
import GlobalContext from 'infra/GlobalContext';
import arxs from 'infra/arxs';
import { useIsMounted } from 'infra/tools/ReactExtensions';
import MultiSelect from 'components/controls/MultiSelect';

import './StructureFilter.scss';
import { OriginModuleEnum } from 'infra/api/contracts';

export default function StructureFilter(props) {
    const lookups = {
        legalStructures: [],
        branches: [],
        buildings: [],
        locations: [],
    };

    const isMounted = useIsMounted();

    const [modules, setModules] = useState([]);
    const [legalStructures, setLegalStructures] = useState([]);
    const [branches, setBranches] = useState([]);
    const [buildings, setBuildings] = useState([]);
    const [locations, setLocations] = useState([]);

    const [legalStructureSelected, setLegalStructureSelected] = useState([]);
    const [branchSelected, setBranchSelected] = useState([]);
    const [buildingSelected, setBuildingSelected] = useState([]);
    const [locationSelected, setLocationSelected] = useState([]);

    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [branchVisible, setBranchVisible] = useState(true);
    const [buildingVisible, setBuildingVisible] = useState(true);
    const [locationVisible, setLocationVisible] = useState(true);
    const [legalStructureVisible, setLegalStructureVisible] = useState(true);

    useEffect(() => {
        const subscriptions = {
            lookups: arxs.Api.lookups.subscribe(lookups, (values) => {
                for (var key of Object.keys(values)) {
                    switch (key) {
                        case "legalStructures": setLegalStructures(values[key]); break;
                        case "branches": setBranches(values[key]); break;
                        case "buildings": setBuildings(values[key]); break;
                        case "locations": setLocations(values[key]); break;
                        default: break;
                    }
                }
            })
        };

        return () => {
            subscriptions.lookups.dispose();
        };
    }, [lookups]);

    useEffect(() => {
        refresh();
    }, [modules, props.securityContext]);

    const refresh = () => {
        if (!isMounted.current) { return; }

        const { modules } = props;
        const modulesArray = Object.keys(modules || {});
        if (modulesArray.length > 0) {
            setModules(modules);
        }

        if (modulesArray.length === 1) {
            switch (modulesArray[0]) {
                case OriginModuleEnum.SchoolGroup: setLocationVisible(false); setBuildingVisible(false); setBranchVisible(false); setLegalStructureVisible(false); break;
                case OriginModuleEnum.School: setLocationVisible(false); setBuildingVisible(false); setBranchVisible(false); break;
                case OriginModuleEnum.Building: setLocationVisible(false); setBuildingVisible(false); break;
                case OriginModuleEnum.Room: setLocationVisible(false); break;
                default: break;
            }
        }
    }

    const handleChangeLegalStructureSelected = (selected) => {
        setLegalStructureSelected(selected);
        props.onLegalStructureChange(selected);
    }

    const handleChangeBranchSelected = (selected) => {
        setBranchSelected(selected);
        props.onBranchChange(selected);
    }

    const handleChangeBuildingSelected = (selected) => {
        setBuildingSelected(selected);
        props.onBuildingChange(selected);
    }

    const handleChangeLocationSelected = (selected) => {
        setLocationSelected(selected);
        props.onLocationChange(selected);
    }

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    }

    const filteredLegalStructures = legalStructures;

    const filteredBranches = (branches || [])
        .filter(br => br.legalStructure?.id && filteredLegalStructures.some(fls => fls.id === br.legalStructure.id))
        .filter(x => legalStructureSelected.length > 0 ? legalStructureSelected.some(lss => lss.id === x.legalStructure?.id) : true);

    const filteredBuildings = (buildings || [])
        .filter(b => b.branch?.id && filteredBranches.some(fbr => fbr.id === b.branch.id))
        .filter(x => branchSelected.length > 0 ? branchSelected.some(brs => brs.id === x.branch?.id) : true);

    const filteredLocations = (locations || [])
        .filter(l => l.building?.id && filteredBuildings.some(fb => fb.id === l.building.id))
        .filter(x => buildingSelected.length > 0 ? buildingSelected.some(bs => bs.id === x.building?.id) : true);


    const legalStructureMultiSelect = (context) => {
        return <MultiSelect
            className="multiselect"
            selected={filteredLegalStructures.filter(x => legalStructureSelected.map(y => y.id).includes(x.id))}
            onChange={handleChangeLegalStructureSelected}
            placeholder={arxs.t("field.legalStructure")}
            items={filteredLegalStructures.map(ls => ({ id: ls.id, name: ls.name }))}
            securityContext={props.securityContext}
        />
    }

    const branchMultiSelect = (context) => {
        return <MultiSelect
            className="multiselect"
            selected={filteredBranches.filter(x => branchSelected.map(y => y.id).includes(x.id))}
            onChange={handleChangeBranchSelected}
            placeholder={arxs.t("field.branch")}
            items={filteredBranches.map(ls => ({ id: ls.id, name: ls.name }))}
            securityContext={props.securityContext}
        />
    }

    const buildingMultiSelect = (context) => {
        return <MultiSelect
            className="multiselect"
            selected={filteredBuildings.filter(x => buildingSelected.map(y => y.id).includes(x.id))}
            onChange={handleChangeBuildingSelected}
            placeholder={arxs.t("field.building")}
            items={filteredBuildings.map(ls => ({ id: ls.id, name: ls.name }))}
            securityContext={props.securityContext}
        />
    }

    const locationMultiSelect = (context) => {
        return <MultiSelect
            className="multiselect"
            selected={filteredLocations.filter(x => locationSelected.map(y => y.id).includes(x.id))}
            onChange={handleChangeLocationSelected}
            placeholder={arxs.t("field.location")}
            items={filteredLocations.map(ls => ({ id: ls.id, name: ls.name }))}
            securityContext={props.securityContext}
        />
    }

    const hasFilter = (legalStructureSelected || []).length > 0
        || (branchSelected).length > 0
        || (buildingSelected).length > 0
        || (locationSelected).length > 0;

    return (
        <GlobalContext.Consumer>
            {(context) =>
                <Fragment>
                    <div className="structure-filter">
                        <div className="structure-filter-header" onClick={toggleDropdown}>
                            {context.platform.isMobile ? <i className='far fa-location'></i> : arxs.t("card_lookup.location")} {hasFilter && <i className="far fa-filter"></i>}
                        </div>
                        {dropdownVisible && <div className="structure-filter-dropdown">
                            {legalStructures && legalStructureVisible && legalStructureMultiSelect(context)}
                            {branches && branchVisible && branchMultiSelect(context)}
                            {buildings && buildingVisible && buildingMultiSelect(context)}
                            {locations && locationVisible && locationMultiSelect(context)}
                        </div>}
                    </div>
                </Fragment>
            }
        </GlobalContext.Consumer>
    );
}