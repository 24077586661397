import React, { useState, useEffect, useContext } from "react";

import arxs from "infra/arxs";
import GlobalContext from 'infra/GlobalContext';
import { FormStatus, OriginModuleEnum } from "infra/api/contracts";

import Button from "components/controls/Button";
import Toaster from "components/util/Toaster";

import LibraryItemList from "./LibraryItemList";
import LibraryItemPreview from "./LibraryItemPreview";
import LibraryImportProgress from "./LibraryImportProgress";

import "./LibraryPopup.scss";


export const createLibraryPopup = (
  module,
  onCancel,
  title,
) => {
  const state = {
    title: title || arxs.t("controls.library.import.title"),
    content: (
      <LibraryPopup
        module={module}
        onCancel={onCancel}
      />
    ),
    onCancel: onCancel,
    fitContentWidth: true,
    fitContentHeight: true,
    canMaximize: false,
  };

  return state;
};

function LibraryPopup(props) {
  const { onCancel } = props;

  const context = useContext(GlobalContext);
  const [items, setItems] = useState(null);
  const [checked, setChecked] = useState([]);
  const [selected, setSelected] = useState(null);
  const [importProgress, setImportProgress] = useState(null);

  useEffect(() => {
    arxs
      .ApiClient.masterdata.library.get()
      .then(payload => {
        setItems(payload);
      });
  }, []);

  const onClose = () => {
    if (props.onCancel) {
      props.onCancel();
    } else {
      context.popup.close();
    }
  }

  const onImport = async () => {
    const resource = arxs.Api.getResource("form");
    const formLibraryItems = checked.filter(x => x.module === OriginModuleEnum.Form);
    const formTemplates = [];
    const total = formLibraryItems.length * 2;
    let progress = 0;
    for (const item of formLibraryItems) {
      progress++;
      formTemplates.push([item, await fetch(item.url).then(x => x.json())]);
      setImportProgress({ progress, total });
    }

    const errors = [];

    for (const [item, template] of formTemplates) {
      await resource.put({
        ...template,
        title: item.title,
        status: FormStatus.InProcess,
        responsible: { id: arxs.Identity.profile.id, module: "Employee" },
        isGlobalScope: true,
        isGlobalAuthorization: true,
        hasNotificationDateBeenReached: false,
      }).then((response) => {
        if (response.ok) {
          progress++;
        } else {
          return response
            .json()
            .then(x => {
              if (x.error) {
                errors.push(x.error);
              }
            });
        }
      });
      setImportProgress({ progress, total });
    }

    if (errors.length > 0) {
      Toaster.error(errors.distinct().join("\n"));
      setImportProgress(null);
    }

    if (progress === total) {
      onClose();
    }
  };

  return (
    <div className={`library-popup ${props.className || ""}`}>
      <div className="library-popup-body">
        <div className="content">
          <LibraryItemList
            module={props.module}
            items={items}
            checked={checked}
            onChangeChecked={setChecked}
            onClick={setSelected}
          />
          <LibraryItemPreview
            title={selected && selected.text}
            selected={selected}
          />
          {importProgress && 
            <LibraryImportProgress
              max={importProgress.total}
              value={importProgress.progress}
            />
          }
        </div>
        <div className="buttons">
          <Button
            className="icon"
            onClick={onImport}
            disabled={!!importProgress}
          >
            {arxs.t("controls.library.import.import")}
          </Button>
          <Button
            className="icon alternative"
            onClick={onClose}
          >
            {arxs.t("common.cancel")}
          </Button>
        </div>
      </div>
    </div>
  );
}
