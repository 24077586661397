import React, { Component } from "react";

import arxs from "infra/arxs";
import FormEditor from "components/controls/form/FormEditor";
import InlineFormAction from "components/controls/form/InlineFormAction";
import Switch from "components/controls/Switch";
import { ObjectDocumentType } from "infra/api/contracts";

import "./FormContainer.scss";

export default class FormContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isCompletionMode: false,
            card: undefined
        };
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.card !== prevProps.card) {
            this.setState({ card: this.props.card });
        }
    }

    switchMode = (value) => {
        this.setState({ isCompletionMode: value });
    }

    render() {
        const { isCompletionMode, card } = this.state;
        const canManage = arxs.isActionAllowed(this.props.manageAction);
        const hasDefinition = card && card.formDefinition !== undefined;

        const onChange = (value) => {
            this.setState({ card: { ...this.state.card, formValues: value.formValues, formDefinition: value.formDefinition } }
                , () => this.props.onChange({ formValues: value.formValues, formDefinition: value.formDefinition }));

        }

        const { module, readOnly, stateProxy } = this.props;
        const { formValues, formDefinition } = card || {};

        const attachments = ((card || {}).attachmentInfo || {}).attachments || [];
        const hasMainDocument = attachments.some(x => x.type === ObjectDocumentType.MainDocument && (x.value || []).some(y => !y.isDeleted));

        return (
            <div className="form-container">
                {hasMainDocument && <div className="form-container-has-document"><i className="far fa-exclamation-triangle"></i>
                    {arxs.t("wizard.validation.when_a_main_document_is_present_no_form_can_be_provided")}
                </div>}
                {!hasMainDocument && canManage &&
                    <div className="form-container-switch">
                        <Switch
                            checked={isCompletionMode}
                            disabled={!hasDefinition}
                            title={arxs.t("form_container.switch_title")}
                            reverse={true}
                            onChange={(checked) => this.switchMode(checked)}
                        />
                    </div>}
                {!hasMainDocument && <div className="form-container-content">
                    {(isCompletionMode || !canManage) && <InlineFormAction
                        card={card}
                        module={module}
                        onChange={(value) => onChange({ formValues: value, formDefinition: formDefinition })}
                    />}
                    {(!isCompletionMode && canManage) && <FormEditor
                        {...this.props}
                        className="field"
                        value={formDefinition}
                        onChange={(value) => onChange({ formValues: formValues, formDefinition: value })}
                        readOnly={readOnly}
                        stateProxy={stateProxy}
                    />}
                </div>}
            </div>
        );
    }
}