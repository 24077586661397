import React, { Fragment } from "react";
import arxs from 'infra/arxs';
import FormItemEditorRiskController from "./FormItemEditorRiskController";
import GlobalContext from "infra/GlobalContext";
import DropDown from "components/controls/DropDown";
import RiskAnalysisValue from "components/controls/riskAnalysis/RiskAnalysisValue";
import { createRiskValueSelectionPopup } from "components/controls/riskAnalysis/RiskValueSelectionPopup";

export default class RiskAnalysis extends FormItemEditorRiskController {
    renderRiskAnalysis = (context) => {
        const { field, readOnly } = this.props;
        const value = field.getter();
        const data = value.data || {};
        const settings = value.settings || {};
        const selectedMethod = this.methods.filter(x => x.id === settings.method)[0];

        const onItemChange = (state, newSettings) => {
            field.setter({ ...value, data: state, settings: newSettings || settings });
        };

        const onMethodChange = (state) => {
            onItemChange(data, { ...settings, method: state ? state.id : null });
        }

        const onRiskAnalysisChange = (state) => {
            onItemChange(state);
            context.popup.close();
        }

        const openRiskMethodEditor = () => {
            if (selectedMethod && selectedMethod.id) {
                const riskValueSelectionPopup = createRiskValueSelectionPopup(selectedMethod.id, data, onRiskAnalysisChange, context.popup.close);

                context.popup.show(riskValueSelectionPopup);
            }
        }

        return <div className="riskAnalysis-container">
            <div className="riskAnalysis" key={(data && data.id) || 0}>
                <DropDown
                    id={`method-${data.id}`}
                    key={`method-${data.id}`}
                    className="riskAnalysis-method"
                    items={this.methods}
                    selected={selectedMethod}
                    onChange={onMethodChange}
                    readOnly={readOnly}
                    disableClearButton={true}
                    placeholder={arxs.t("forms.items.riskanalysis.method")}
                />
                <RiskAnalysisValue value={data} readOnly={true} type={(settings || {}).method} includeRiskValue={this.props.includeRiskValue} />
                {!readOnly && <div className="riskAnalysis-action" onClick={openRiskMethodEditor}><i className="fas fa-balance-scale-right"></i></div>}
            </div>
        </div>;

    }

    render() {
        return (<GlobalContext.Consumer>
            {
                (context) => <Fragment>
                    {this.renderBegin()}
                    <div className="form-item-control-container">
                        {this.renderTitle(context)}
                        {this.renderRiskAnalysis(context)}
                    </div>
                    {this.renderEnd(true)}
                </Fragment>
            }
        </GlobalContext.Consumer>)
    }
}