import React, { Component } from "react";

import arxs from "infra/arxs";
import GlobalContext from "infra/GlobalContext";
import CardDataSource from "infra/CardDataSource";
import { LinkType, FormValueAttachmentType } from "infra/api/contracts";

import { Spinner } from "components/animations/Spinner";
import Search from "components/controls/Search";
import CardList from "components/controls/cardlist/CardList";
import formDefinitionSelector from "components/controls/form/FormDefinitionSelector";

import FormForSubject from "./FormForSubject";
import FormBadgePresenter from "./FormBadgePresenter";

import "./Form.scss";

export default class Form extends Component {
  lookups = {
    legalStructureMap: {},
    branchMap: {},
    buildingMap: {},
    locationMap: {},
    labourmeanMap: {},
    equipmentMap: {},
    intangibleAssetMap: {},
    protectionEquipmentMap: {},
    hazardousSubstanceMap: {},
    combinedInstallationMap: {},
    formMap: {},
  }

  constructor(props) {
    super(props);

    const criteria = {
      searchTerm: () => this.state.searchTerm,
      modules: () => this.state.subjectLinks.map(x => x.module).toDictionary(x => x),
      ids: () => this.state.subjectLinks.map(x => x.id).toDictionary(x => x),
    };

    this.state = {
      data: {},
      metadata: {},
      metadataMap: null,
      searchTerm: "",
      dataSource: new CardDataSource(criteria),
      selectedSubject: null,
      subjectLinks: [],
      subjects: [],
    };
  }

  componentDidMount() {
    if (!this.subscriptions) {
      this.subscriptions = {
        // We only subscribe to these lookups so we can call resolveSubject below.
        // No need to inject state in this component
        lookups: arxs.Api.lookups.subscribe(this.lookups, lookups => {
          this.setState(lookups, this.loadFormMetadata);
        })
      };

      this.state.dataSource.setRefresh(this.refresh);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.cards.length === 0) {
      if (prevProps.subjectLinks !== this.props.subjectLinks) {
        this.setState({ subjectLinks: [], subjects: [], data: {}, selectedSubject: null });
      }
      return;
    }

    const subjectLinks = (this.props.cards || [])
      .flatMap(x => x.outboundLinks || [])
      .filter(x => x.type === LinkType.Subject);

    const subjects = subjectLinks
      .map(x => arxs.Api.lookups.resolveSubjectOrDefault(x))
      .filter(x => x);

    if (subjectLinks.length > this.state.subjects.length
      && subjectLinks.length === subjects.length
      && this.state.metadataMap
    ) {
      const data = this.deserializeValues(this.props.initialFormValuesByCard
          , this.props.cards
          , this.state.metadataMap);
      this.setState({ subjectLinks, subjects, data });
    }
  }

  componentWillUnmount() {
    if (this.subscriptions) {
      this.subscriptions.lookups.dispose();
    }
    if (this.state.dataSource) {
      this.state.dataSource.dispose();
    }
  }

  loadFormMetadata = () => {
    if (!this.state.formMap || this.state.metadataMap) {
      return;
    }

    const metadataMap = {};

    for (const card of this.props.cards) {
      const formDefinition = (card || {}).formDefinition || {};
      
      let form, definition, metadata;
      if (formDefinition.link) { 
        [form, definition] = formDefinitionSelector(this.state.formMap, this.props.module, card.status, formDefinition);
      } else {
        definition = formDefinition;
      }
      if (definition) {
        metadata = this.deserializeDefinition(definition);
      }

      metadataMap[card.id] = {
        metadata,
        form,
        definition
      };
    }

    this.setState({ metadataMap });
  }

  deserializeDefinition = (from) => {
    if (from && Object.keys(from).length > 0) {
      return {
        ...from,
        controls: from.controls
          .map(x => ({
            ...x,
            parameters: x.props ? JSON.parse(x.props) : null
          }))
      };
    }
    return {};
  }

  serializeValues = (from, cards) => {
    const data = JSON.parse(JSON.stringify(from || {}));

    for (const card of cards) {
      const formValues = data[card.id];

      if (formValues && formValues.itemsBySubject) {
        for (const [_, idAndItems] of Object.entries(formValues.itemsBySubject)) {
  
          for (const [id, items] of Object.entries(idAndItems || {})) {
            for (let item of items) {
              if (item.value && typeof item.value !== "string") {
                item.value = item.value.toString();
              }
              for (let att of (item.attachments || [])) {
                if (att.type === FormValueAttachmentType.Media) {
                  let attNewValues = [].concat(att.value.map(x => ({
                    refId: x.id,
                    name: x.name,
                    hash: x.hash,
                    url: x.url,
                    contentType: x.contentType,
                    type: x.type
                  })));
      
                  att.value = JSON.stringify(attNewValues);
                }
              }
            }
          }
        }
      }
    }

    return data;
  }

  deserializeValues = (formValuesByCard, cards, metadataMap) => {
    const data = {};
    for (const card of cards) {
      const meta = metadataMap[card.id];
      const form = meta.form;
      const formValues = (formValuesByCard && formValuesByCard[card.id]) || card.formValues;

      let attachmentInfo = { ...card.attachmentInfo };
      if (form) {
        attachmentInfo = {
          storedFiles: ((attachmentInfo || {}).storedFiles || [])
            .concat((form.attachmentInfo || {}).storedFiles || []),
        };
      }

      let formValuesForRendering = { ...formValues };

      // To support the intermediate period where old caches provide old formValues structure
      if (formValues && !formValues.itemsBySubject && formValues.items) {
        const subjectLink = card.outboundLinks.filter(x => x.type === LinkType.Subject)[0];
        if (subjectLink) {
          formValuesForRendering = {
            itemsBySubject: {
              [subjectLink.module]: {
                [subjectLink.id]: formValues.items,
              }
            }
          };
        }
      }

      for (let [module, moduleMap] of Object.entries(formValuesForRendering.itemsBySubject || {})) {
        for (let [id, items] of Object.entries(moduleMap)) {
          for (let item of (items || [])) {
            for (let att of (item.attachments || [])) {
              if (att.type === FormValueAttachmentType.Media && typeof att.value === "string") {
                var mediaValues = JSON.parse(att.value);
                var newMediaValues = [];
                for (let mediaValue of mediaValues) {
                  if (mediaValue && mediaValue.refId) {
                    const newMediaValue = ((card.attachmentInfo ||{}).storedFiles || [])
                      .filter(x => x.id === mediaValue.refId)[0];
                    if (newMediaValue){
                      newMediaValues.push({ ...newMediaValue, type: mediaValue.type });
                    }
                  } else {
                    newMediaValues.push(mediaValue);
                  }
                }
                att.value = newMediaValues;
              }
            }
          }
        }
      }

      data[card.id] = formValuesForRendering;
    }

    return data;
  }

  refresh = () => {
    const subjects = this.state.subjectLinks
      .map(x => arxs.Api.lookups.resolveSubjectOrDefault(x))
      .filter(x => x);

    this.setState({ subjects });
  }

  getValues = (card, subject) => {
    if (!subject) {
      return null;
    }

    const { data } = this.state;
    const formValuesForCard = data[card.id] || {};
    const moduleMap = formValuesForCard.itemsBySubject || {}
    const idMap = moduleMap[subject.module] || {};
    const items = idMap[subject.id] || [];
    return items;
  }

  handleChangeSearchTerm = (event) => {
    this.setSearchTerm(event.target.value);
  }

  setSearchTerm = (value) => {
    this.setState({searchTerm: value});
  }

  handleChangeFormItem = (card, subject, newItems) => {
    const data = JSON.parse(JSON.stringify(this.state.data || {}));
    const formValuesForCard = data[card.id] || {};
    const itemsBySubject = formValuesForCard.itemsBySubject || {};
    const idMap = itemsBySubject[subject.module] || {};
    
    data[card.id] = formValuesForCard;
    formValuesForCard.itemsBySubject = itemsBySubject;
    itemsBySubject[subject.module] = idMap;
    idMap[subject.id] = newItems;

    this.setState({ data });
    this.props.onChange(this.serializeValues(data, this.props.cards));
  }

  renderSubjectFooter = (subject) => {
    const card = (this.props.cards || [])
      .filter(x => (x.outboundLinks || [])
        .some(l => l.type === LinkType.Subject
          && l.module === subject.module
          && l.id === subject.id))
      [0];

    const formValuesForCard = this.state.data[card.id] || {};
    const itemsBySubject = formValuesForCard.itemsBySubject || {};
    const idMap = itemsBySubject[subject.module] || {};
    const items = idMap[subject.id] || [];

    return <FormBadgePresenter
      card={card}
      module={card.module}
      overrideItems={items}
      render={({filledIn, total}) => total > 0 && <div className="list-card-form-count">
          {filledIn === total && <i className="fas fa-check"></i>}
          <i className="fas fa-tasks"></i>
          {filledIn}/{total}
      </div>}
    />;
  }

  render() {
    const { requiredItemsWithoutValue, cards } = this.props;
    const { searchTerm, subjectLinks, subjects, metadataMap } = this.state;
    let { selectedSubject } = this.state;

    const matchingSubjects = this.state.dataSource.get(
      _ => true,
      x => x.uniqueNumber
    );

    const loaded = (subjectLinks.length === 0 || subjectLinks.length === subjects.length)
      && !!metadataMap
      && (searchTerm || matchingSubjects.length > 0 || subjectLinks.length === 0);

    if (!loaded) {
      return (
        <GlobalContext.Consumer>
          {(context) => (
            <div className="form">
              <div className="form-loading">
                <h3>{arxs.t("controls.form.loading_subjects")}</h3>
                <Spinner />
              </div>
            </div>
          )}
        </GlobalContext.Consumer>
      );
    }

    const hasMultipleSubjects = subjectLinks.length > 1;
    if (matchingSubjects.length === 0) {
      selectedSubject = null;
    } else if (!hasMultipleSubjects || !selectedSubject) {
      selectedSubject = matchingSubjects[0];
    }

    let card, form, definition, metadata, module;
    if (selectedSubject) {
      card = (cards || [])
        .filter(x => (x.outboundLinks || [])
          .some(l => l.type === LinkType.Subject
            && l.module === selectedSubject.module
            && l.id === selectedSubject.id))
        [0];

      module = this.props.module;
    } else if (subjectLinks.length === 0) {
      card = (cards || [])[0];
      module = card.targetModule;
    }

    if (card) {
      const meta = this.state.metadataMap[card.id];
      form = meta.form;
      definition = meta.definition;
      metadata = meta.metadata;
    }

    return (
      <GlobalContext.Consumer>
        {(context) => (
          <div className="form">
            <div className="form-body">
              {hasMultipleSubjects && <Search
                value={this.state.searchTerm}
                onChange={this.handleChangeSearchTerm}
                onSearchResult={this.setSearchTerm}
                />}
              {hasMultipleSubjects && <CardList
                readOnly={true}
                value={matchingSubjects}
                selected={selectedSubject}
                onClick={(e, subject) => {
                  if (subject && !this.props.readOnly) {
                    context.detailsPane.open(subject, context.platform.isPhone);
                  }
                  this.setState({ selectedSubject: matchingSubjects.filter(x => x.id === subject.id)[0] });
                }}
                navigation={true}
                renderCardFooter={this.renderSubjectFooter}
              />}
              {card && <FormForSubject
                requiredItemsWithoutValue={requiredItemsWithoutValue}
                module={module}
                objectId={card.id}
                card={card}
                form={form}
                definition={definition}
                metadata={metadata}
                searchTerm={searchTerm}
                values={this.getValues(card, selectedSubject)}
                onChange={(x) => this.handleChangeFormItem(card, selectedSubject, x)}
                readOnly={this.props.readOnly}
                inline={this.props.inline}
              />}
            </div>
          </div>
        )}
      </GlobalContext.Consumer>
    );
  }
}